<app-loading-spinner *ngIf="isLoading"></app-loading-spinner>

<div class="row" style="margin:10px">
  <div class="col-2 sectionBox p-20" style="height: 100%">
    <div>
      <h4>
        <input (click)="toggleTypes()" [ngModel]="this.filterTypeIds.selected.length == this.assetTypes.length" class="form-check-input" type="checkbox"
               id="statusss_all"> Asset Types</h4>

      <div *ngFor="let x of this.assetTypes" class="form-check">
        <input [ngModel]="this.filterTypeIds.isSelected(x.id)"
               (change)="this.filterTypeIds.toggle(x.id);" [ngModelOptions]="{standalone: true}"
               name="{{x.id}}_{{x.name}}" class="form-check-input" type="checkbox"
               id="{{x.id}}_{{x.name}}">
        <label class="form-check-label" for="{{x.id}}_{{x.name}}">{{x.name}}</label>
      </div>


      <hr class="m-t-20 m-b-20">

      <h4>
        <input (click)="toggleStatus()" [ngModel]="this.filterStatusIds.selected.length == this.statusOptions.length" class="form-check-input" type="checkbox"
               id="status_all"> Status</h4>

      <div *ngFor="let x of this.statusOptions" class="form-check">
        <input [ngModel]="this.filterStatusIds.isSelected(x.id)"
               (change)="this.filterStatusIds.toggle(x.id);" [ngModelOptions]="{standalone: true}"
               name="{{x.id}}_{{x.name}}" class="form-check-input" type="checkbox"
               id="{{x.id}}_{{x.name}}">
        <label class="form-check-label" for="{{x.id}}_{{x.name}}">{{x.name}}</label>
      </div>

    </div>

  </div>
  <div *ngIf="!isLoading" class="col">
    <div class="row">
      <div class="col p-0">
        <input type="text" [(ngModel)]="searchTerm" id="searchTerm" placeholder="Search..." autocomplete="off" ng-reflect-model="" class="form-control ng-pristine ng-valid ng-touched" style="margin-bottom: 1px;border: #f3f3f3;font-size: 14px;font-weight: normal;color: #7a7a7a;padding: 10px;/* border: solid 1px #ebeff2; */border-radius: 0px;margin-bottom: 2px;margin-top: 0px;    border-radius: 10px;
    margin-bottom: 5px;">
      </div>
      <div class="col-1 divLink" (click)="export()" style="border-radius: 10px;
    background-color: #88ad9a;
    margin: 5px;
    color: #ffffff;
    padding-top: 9px;
    text-align: center;
    font-size: 15px;
    margin-top: 0px;"> Export
      </div>
      <div class="col-2 divLink" (click)="create()" style="border-radius: 10px;
    background-color: #467691;
    margin: 5px;
    margin-left: 0px;
    color: #ffffff;
    padding-top: 9px;
    text-align: center;
    font-size: 15px;
    margin-top: 0px;"> New Asset
      </div>
      <div id="dashboardContent">
        <div class="sectionBox p-0">
          <div class="table-responsive">
            <p-table [value]="this.getFilteredAssets()" [paginator]="true" [rows]="50" sortField="Name" [sortOrder]="-1"
                     class="table align-middle tableStyle">


              <ng-template pTemplate="header">
                <tr style="padding:10px">
                  <th pSortableColumn="AssetNo" style="width: 100px">Asset #
                    <p-sortIcon [field]="'AssetNo'"></p-sortIcon>
                  </th>
                  <th pSortableColumn="Name" class="col-sm">Name
                    <p-sortIcon field="Name"></p-sortIcon>
                  </th>
                  <th pSortableColumn="OwnerId" class="col-sm-1 text-center">Owner
                    <p-sortIcon field="OwnerId"></p-sortIcon>
                  </th>
                  <th pSortableColumn="AssetTypeId" class="col-sm">Type
                    <p-sortIcon field="AssetTypeId"></p-sortIcon>
                  </th>
                  <th pSortableColumn="Manufacturer" class="col-sm">Manufacturer
                    <p-sortIcon field="Manufacturer"></p-sortIcon>
                  </th>
                  <th pSortableColumn="Model" class="col-sm">Model
                    <p-sortIcon field="Model"></p-sortIcon>
                  </th>
                  <th pSortableColumn="Cost" class="col-sm-1 text-center">Cost
                    <p-sortIcon field="Cost"></p-sortIcon>
                  </th>
                  <th pSortableColumn="Status" class="col-sm-1 text-center">Status
                    <p-sortIcon field="Status"></p-sortIcon>
                  </th>

                  <th pSortableColumn="SerialNumber" class="col-sm-1 text-center">Serial
                    <p-sortIcon field="SerialNumber"></p-sortIcon>
                  </th>
                </tr>
              </ng-template>
              <ng-template pTemplate="body" let-device>
                <tr style="padding:10px" class="divLink" (click)="edit(device.Id)">
                  <td>{{device.AssetNo}}</td>
                  <td>{{device.Name}}</td>
                  <td>{{device.OwnerName}}</td>
                  <td>{{getType(device.AssetTypeId)}}</td>
                  <td>{{device.Manufacturer}}</td>
                  <td>{{device.Model}}</td>
                  <td>£{{device.Cost}}</td>
                  <td>{{getStatus(device.Status)}}</td>
                  <td>{{device.SerialNumber}}</td>
                </tr>
              </ng-template>
            </p-table>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

