<app-modal [modalSize]="'modal-medium'">
  <div class="modal-header">
    <h5 class="modal-title" id="exampleModalLabel">Add Link</h5>
  </div>
  <div class="modal-body">


  </div>
  <div class="modal-footer">
    <div (click)="save()" class="btn btn-primary">Create</div>
    <div (click)="null" class="btn btn-cancel" data-bs-dismiss="modal">Cancel</div>
  </div>
</app-modal>





<!--// Blade-->

<app-blade-right>
  <div class="p-10 p-b-0">
    <i (click)="this.close()" class="fas fa-times fa-2x float-end divLink"></i>
    <h3>New </h3>
  </div>
  <hr>


  <div class="container p-0">

  </div>
</app-blade-right>
