import { Component, EventEmitter, Input, Output, ViewContainerRef } from "@angular/core";
import { Dialog } from "../dialog/dialog";
import { DialogService } from "src/app/services/dialog-service/dialog.service";
import { FilterService } from "src/app/services/api/filter/filter.service";
import { OptionsApiService } from "src/app/services/api/options/options-api.service";

@Component({
    selector: 'app-schedule-filter-reports',
    templateUrl: './schedule-filter-reports.component.html',
    styleUrls: ['./schedule-filter-reports.component.css']

  })

  export class ScheduleFilterReportsComponent implements Dialog {
    @Input() data;
    @Output() remove = new EventEmitter<any>();
    isLoadingOptions = true;
    filterOptions;
    Id;

    frequencyOptions = [
        {id: 1, name: 'Daily'},
        {id: 2, name: 'Weekly'},
        {id: 3, name: 'Monthly'}
      ];

      loadedData;

      constructor(private optionsApi: OptionsApiService, private filterApi: FilterService, private dialogService: DialogService,
        private viewReference: ViewContainerRef) {
console.log("Constructing!!!");
}

    ngOnInit(): void {
        this.loadedData = this.data;
        this.loadedData.StartDateTime = this.ConvertToValidDate(this.loadedData.StartDateTime)
        this.getOptions();
        this.Id=this.loadedData.Id;
    }

    getOptions(){
        this.isLoadingOptions = true;
        this.optionsApi.DynamicOptions(this.loadedData.RequestTypeId).then(data => {
            this.filterOptions = data;
        })}

    save() {
      console.log(this.loadedData.StartDateTime);
      let formattedStartDateTime = null;
    
      // Get individual components of the date
      if (this.loadedData.StartDateTime) {
        let date = new Date(this.loadedData.StartDateTime);
        let year = date.getFullYear();
        let month = ('0' + (date.getMonth() + 1)).slice(-2); // Months are zero indexed
        let day = ('0' + date.getDate()).slice(-2);
        let hours = ('0' + date.getHours()).slice(-2);
        let minutes = ('0' + date.getMinutes()).slice(-2);
        let seconds = ('0' + date.getSeconds()).slice(-2);
      
        // Format StartDateTime as 'YYYY-MM-DD HH:mm:ss'
        formattedStartDateTime = `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;
        console.log(formattedStartDateTime);
      }
    
      const scheduleData = {
        "ReportingEnabled": this.loadedData.ReportingEnabled ? 1 : 0,
        "Frequency": this.loadedData.Frequency,
        "StartDateTime": formattedStartDateTime,
        "GroupValues": this.loadedData.ReportGroupValues.map(obj => obj.id),
        "AgentValues": this.loadedData.ReportAgentValues.map(obj => obj.id)
      };
    
      this.filterApi.updateSchedulingReports(this.loadedData.Id, scheduleData)
        .then(success => {
          this.filterApi.executeFilter(this.Id);
          this.remove.emit(success.Id);
        })
        .catch(fail => {
          console.log("Failed: ", fail);
        });
    }
        
    close() {
        this.remove.emit(null);
      }
      ConvertToValidDate(date){
        if (!date) {
            return null;
        }
    
        const parsedDate = new Date(date);
        return isNaN(parsedDate.getTime()) ? null : parsedDate;
    }
    }
