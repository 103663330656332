import { Injectable } from '@angular/core';
import {ApiService} from "../api.service";

@Injectable({
  providedIn: 'root'
})
export class AuthApiService {

  private loginApi = "/api/login";
  private loginMethodApi = "/api/loginmethod";
  private resetPasswordApi = "/api/resetpassword";

  constructor(private api: ApiService) { }

  public getLoginMethod(emailAddress){
    return this.api.post(this.loginMethodApi, {EmailAddress: emailAddress});
  }

  public login(emailAddress, password){

    let loginPayload = {
      EmailAddress: emailAddress,
      Password:  password
    }

    return this.api.post(this.loginApi, loginPayload);

  }

  public resetPassword(emailAddress){
    let loginPayload = {
      EmailAddress: emailAddress,
    }

    return this.api.post(this.resetPasswordApi, loginPayload);
  }
}
