// src/app/services/api/self-service/self-service-api.service.ts
import { Injectable } from '@angular/core';
import { ApiService } from "../api.service";

@Injectable({
  providedIn: 'root'
})
export class SelfServiceApiService {

  selfServiceUri = '/api/selfservice';
  selfServiceV2Uri = '/api/v2/selfservice';

  constructor(private apiService: ApiService) { }

  getAvailableRequestTypes() {
    return this.apiService.get(`${this.selfServiceUri}/availablerequests`);
  }

  getMyRequests() {
    return this.apiService.get(`${this.selfServiceUri}/requests`);
  }

  getKnowledgeBasePreview() {
    return this.apiService.get(`${this.selfServiceUri}/solutions`);
  }
  getKnowledgeBasePreviewAdvanced(page: number = 1, pageSize: number = 50, sort: string = 'newest', search: string = '', category: string = ''): Promise<any> {
    const encodedSearch = encodeURIComponent(search);
    const encodedCategory = encodeURIComponent(category);

    const queryParams = `?page=${page}&pageSize=${pageSize}&sort=${sort}&search=${encodedSearch}&category=${encodedCategory}`;
    console.log('Requesting knowledge base with:', queryParams);
    return this.apiService.get(`${this.selfServiceUri}/solutions${queryParams}`);
}

  getKnowledgeBasePreviewPaginated(page: number = 1, pageSize: number = 10): Promise<any> {
    // Construct query parameters for pagination
    const queryParams = `?page=${page}&pageSize=${pageSize}`;
    return this.apiService.get(`${this.selfServiceUri}/solutions${queryParams}`);
  }
  getKnowledgeBaseArticle(articleId: string): Promise<any> {
    return this.apiService.get(`${this.selfServiceUri}/kbarticle/${articleId}`);
  }

  getRequest(requestId: string) {
    return this.apiService.get(`${this.selfServiceV2Uri}/request/${requestId}`);
  }

  respondToRequest(requestId: string, response: string,attachmentArray) {
    let body = {
      Text: response,
      Attachment:attachmentArray
    }
    return this.apiService.post(`${this.selfServiceUri}/request/${requestId}/update`, body);
  }

  createRequest(typeId: string, subject: string, body: string) {
    let apiRequestBody = {
      RequestSubject: subject,
      RequestBody: body,
      RequestTypeId: typeId
    };

    return this.apiService.post(`${this.selfServiceUri}/request`, apiRequestBody);
  }

  getTagsForArticle(articleId: number): Promise<any[]> {
    return this.apiService.get(`${this.selfServiceUri}/kbarticle/tags/${articleId}`);
  }

  getAllArticleTags(): Promise<any[]> {
    return this.apiService.get(`${this.selfServiceUri}/kbarticles/tags`);
  }

  getArticleBody(articleUrl: string): Promise<string> {
    console.log('Fetching article body from:', `${this.selfServiceUri}/kbarticles/body/${articleUrl}`);
    return this.apiService.get(`${this.selfServiceUri}/kbarticles/body/${articleUrl}`);
  }

  getCategoryDetails(categoryId: string): Promise<any> {
    return this.apiService.get(`${this.selfServiceUri}/categories/${categoryId}`);
  }
}
