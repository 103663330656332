<div class="SDeskSidePanel-GreyBackground"></div>

<app-blade-right *ngIf="!isLoading" size="blade-large">
  <div class="p-10 p-b-0">
    <i (click)="this.close()" class="fas fa-times fa-2x float-end divLink"></i>
    <h3>Create Jira Configuration</h3>
  </div>
  <hr>
  <div class="container p-0">

    <div class="row">
      <div class="col-sm-3 formHeader alignMiddle p-10">
        Jira Url <span class="text-danger">*</span>
      </div>
      <div class="col-sm p-10">
        <input [(ngModel)]="loadedData.JiraUrl" type="text" class="form-control" id="JiraUrl" placeholder="aytra.atlassian.net" autocomplete="off">

      </div>
    </div>

    <div class="row">
      <div class="col-sm-3 formHeader alignMiddle p-10">
        Jira User Name <span class="text-danger">*</span>
      </div>
      <div class="col-sm p-10">
        <input [(ngModel)]="loadedData.JiraUsername" type="text" class="form-control" id="JiraUsername" placeholder="test@ivedha.com" autocomplete="off">

      </div>
    </div>

    <div class="row">
      <div class="col-sm-3 formHeader alignMiddle p-10">
        Jira Api Key <span class="text-danger">*</span>
      </div>
      <div class="col-sm p-10">
        <input [(ngModel)]="loadedData.JiraApiKey"
           [type]="loadedData.JiraApiKey ? 'password' : 'text'"
           class="form-control"
           id="JiraApiKey"
           placeholder="••••••"
           autocomplete="off">

      </div>
    </div>



    <hr/>

    <div class="m-t-20" *ngIf="!isJiraConfigSaved">
      <div (click)="save()" class="btn btn-primary">Save & Add Group</div>
      <div (click)="close()" class="btn btn-cancel">Cancel</div>
    </div>
    <div *ngFor="let group of jiraConfigGroups; let i = index">
       <div *ngIf="group.id">
        <div *ngIf="group.is_Active; else activeButton">
          <button (click)="activeDisableGroup(group.id)" class="btn btn-danger p-5 float-end fa-15x divLink" aria-hidden="true" title="Disable the Group" style="padding: 5px 10px; font-size: 12px;">Disable the Group</button>
        </div>
        <ng-template #activeButton>
          <button (click)="activeDisableGroup(group.id)" class="btn btn-success p-5 float-end fa-15x divLink" aria-hidden="true" title="Active the Group" style="padding: 5px 10px; font-size: 12px;">Active the Group</button>
        </ng-template>
      </div>
  <h4>Group {{ i + 1 }}</h4>
  <div class="row">
    <div class="col-sm-3 formHeader alignMiddle p-10">Jira Project Key</div>
    <div class="col p-10">
      <ng-select [items]="jiraConfigList.project"
                 bindLabel="project_key"
                 bindValue="id"
                 [(ngModel)]="group.JiraProjectId"
                 [disabled]="!group.is_Active"
                 [clearable]="false"
                 (ngModelChange)="group.isUpdated = true; getIssueType(group.JiraProjectId)">
      </ng-select>
    </div>
  </div>

  <div class="row">
    <div class="col-sm-3 formHeader alignMiddle p-10">Jira Issue Type Key</div>
    <div class="col p-10">
      <ng-select [items]="issueType"
                 bindLabel="issuetype_name"
                 bindValue="id"
                 [(ngModel)]="group.JiraIssueTypeId"
                 [clearable]="false"
                 [disabled]="!group.is_Active"
                 (ngModelChange)="group.isUpdated = true">
      </ng-select>
    </div>
  </div>

  <div class="row">
    <div class="col-sm-3 formHeader alignMiddle p-10">Request Type</div>
    <div class="col p-10">
      <ng-select [items]="requestTypes"
                 bindLabel="name"
                 bindValue="name"
                 [(ngModel)]="group.GroupName"
                 [clearable]="false"
                 [disabled]="!group.is_Active"
                 (ngModelChange)="group.isUpdated = true">
      </ng-select>
    </div>
  </div>

  <!-- Conditionally show the Update button if any data is changed -->
  <div class="row" *ngIf="group.isUpdated && group.id">
    <div class="col p-10">
      <button class="btn btn-primary" (click)="updateGroup(group.id)">Update</button>
    </div>
  </div>

  <hr>
</div>

<div class="m-t-20" *ngIf="isJiraConfigSaved">
  <div *ngIf="!isGroupAdded" (click)="addGroup()" class="btn btn-primary">Add Group</div>
  <div *ngIf="isGroupAdded" (click)="saveGroup()" class="btn btn-primary">Save Group</div>
  <div (click)="close()" class="btn btn-cancel">Cancel</div>
</div>


  </div>
</app-blade-right>
<!-- Optional loading spinner -->
<app-blade-right *ngIf="isLoading">
<app-loading-spinner ></app-loading-spinner>
</app-blade-right>
