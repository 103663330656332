

import {Component, OnInit, ViewContainerRef} from '@angular/core';
import {ApiAdminEmailTemplatesService} from "../../../../../services/api/admin/admin-email-templates-api/admin-email-templates-api.service";
import {DialogService} from "../../../../../services/dialog-service/dialog.service";
import {AdminEmailTemplatesComponentDialog} from "./admin-email-templates-create-edit-dialog/admin-email-templates-create-edit-dialog.component";
import {ModalConfirmationComponent} from "../../../../../shared/modal-confirmation/modal-confirmation.component";
import {OptionsApiService} from '../../../../../services/api/options/options-api.service';

@Component({
  selector: 'app-admin-email-templates',
  templateUrl: './admin-email-templates.html'
})
export class AdminEmailTemplatesComponent implements OnInit {

  data;
  isLoading = true;
  requestTypeId = 0;
  options;

  requestTypes = [{"name":"Default", "id": 0}]

  constructor(private optionsApi: OptionsApiService, private apiAdminEmailTemplatesService: ApiAdminEmailTemplatesService, private dialogService: DialogService, private viewReference: ViewContainerRef) {
    this.getData();
  }

  ngOnInit(): void {
  }

  getData(){
    this.isLoading = true;
    this.apiAdminEmailTemplatesService.listAll(this.requestTypeId).then(response => {
      this.data = response;

      this.optionsApi.AllOptions().then(response => {

        this.requestTypes = [{"name":"Default", "id": 0}];
        this.requestTypes = this.requestTypes.concat(response.type);

        this.isLoading = false;
      })

    })
  }

  override(typeId, name){
    // Replace null with Component

    this.apiAdminEmailTemplatesService.override(typeId, name).then(response => this.getData());

  }

  create(){
    // Replace null with Component
    this.dialogService.createDialog(AdminEmailTemplatesComponentDialog, null, this.viewReference)
      .then(dialogSaved => {
        this.getData();
      }, dialogCancelled => {

      })
  }

  edit(id){

    if(id == null){
      return;
    }

    // Replace null with Component
    this.dialogService.createDialog(AdminEmailTemplatesComponentDialog, id, this.viewReference)
      .then(dialogSaved => {
        this.getData();
      }, dialogCancelled => {

      })
  }

  delete(id){
    this.dialogService.createDialog(ModalConfirmationComponent, 'Are you sure?', this.viewReference)
      .then(confirmed => {
        this.apiAdminEmailTemplatesService.delete(id).then(response => this.getData())
      }, cancelled => null)
  }



}
