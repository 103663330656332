import { Injectable } from '@angular/core';
import {BehaviorSubject, timer} from "rxjs";
import {ApiOnlineIndicatorService} from "../api/agent/online-indicator/api-online-indicator.service";

@Injectable({
  providedIn: 'root'
})
export class OnlineUsersService {

  public onlineUsersArray = new BehaviorSubject<any[]>([]);

  constructor(private onlineApi: ApiOnlineIndicatorService) {
    this.getOnlineUsers();
    let onlineIndicator = timer(60000,60000);
    onlineIndicator.subscribe(val => this.getOnlineUsers());
  }

  // userLastOnline(userId){
  //   // 1 = Online (Less than 3 minutes)
  //   // 2 = Idle (More than 3 minutes, less than 15 minutes)
  //   // 3 = Offline (More than 15 minutes)
  //   // 4 = Unknown
  //
  //   let userRecord = this.onlineUsers.find(user => user.Id === userId);
  //
  //   return userRecord.LastActivityDateTime;
  //
  // }

  getOnlineUsers(){
    this.onlineApi.getOnline().then(response => this.onlineUsersArray.next(response))
  }
}
