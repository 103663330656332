
import {Injectable} from '@angular/core';
import {ApiService} from "../../api.service";

@Injectable({
  providedIn: 'root'
})
export class ApiAdminUsersService {

  apiUri = '/api/user'

  constructor(private apiService: ApiService) {
  }

  listAll() {
    return this.apiService.get(this.apiUri);
  }

  get(id) {
    return this.apiService.get(`/api/v2/user/${id}`);
  }

  delete(id) {
    return this.apiService.delete(`${this.apiUri}/${id}`);
  }

  create(data) {
    return this.apiService.post('/api/v2/user', data);
  }

  update(id, data) {
    return this.apiService.put(`/api/v2/user/${id}`, data);
  }

  getUserAllCustomField(){
    return this.apiService.get("/api/user/get/customfield");
  }

  getFullUserCustomField(){
    return this.apiService.get("/api/user/get/customfield/all")
  }

  createCustomField(data){
    return this.apiService.post("/api/user/customfield", data);
  }

  getCustomField(id){
    return this.apiService.get(`/api/user/get/customfield/${id}`);
  }

  createCustomFieldOption(customFieldId, value, level,parentOptionId){
    let data = {
      OptionValue: value,
      Level: level,
      ParentCustomFieldId: parentOptionId
    }
    return this.apiService.post(`/api/admin/user/customfield/${customFieldId}/option`, data);
  }

  deleteCustomField(customFieldId){
    //'DELETE /api/admin/user/get/customfield/@CustomFieldId',
    return this.apiService.delete(`/api/admin/user/get/customfield/${customFieldId}`);
  }

  deleteCustomFieldOption(customFieldId, optionId){
    return this.apiService.delete(`/api/admin/user/customfield/${customFieldId}/option/${optionId}`);

  }

  updateUserCustomField(customFieldId, data){
    return this.apiService.put(`/api/admin/user/get/customfield/${customFieldId}`, data);
  }

  moveUpCustomFieldOption(customFieldId, optionId){
    //'PATCH /api/admin/user/customfield/@CustomFieldId/option/@OptionId/changeorder/up',
    return this.apiService.patch(`/api/admin/user/customfield/${customFieldId}/option/${optionId}/changeorder/up`)
  }

  moveDownCustomFieldOption(customFieldId, optionId){
    //'PATCH /api/admin/user/customfield/@CustomFieldId/option/@OptionId/changeorder/up',
    return this.apiService.patch(`/api/admin/user/customfield/${customFieldId}/option/${optionId}/changeorder/down`)
  }


}



