import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {Dialog} from '../../../../shared/dialog/dialog';
import {TasksApiService} from '../../../../services/api/tasks/tasks-api-service.service';

@Component({
  selector: 'app-tasks-dialog',
  templateUrl: './tasks-dialog.component.html',
  styleUrls: ['./tasks-dialog.component.css']
})
export class TasksDialogComponent implements Dialog {
  @Input() data;
  @Output() remove = new EventEmitter<any>();

  isLoading = true;
  loadedId = null;

  loadedData;

  constructor(
    private TasksApi: TasksApiService
  ) {}


  ngOnInit(): void {
    if(this.data !== null){
      this.loadedId = this.data;
    } else {

      // Load Default Data
      this.loadedData = {
        TaskName: '',
        Instructions: ''
      }

      this.isLoading = false;

    }
  }


  close(){
    this.remove.emit(null);
  }

  save(){
    this.TasksApi.create(this.loadedData).then(response => {
      this.remove.emit("Saved");
    })

  }

}
