<div *ngIf="isLoading">
  <app-loading-spinner></app-loading-spinner>
</div>
<ng-container *ngIf="!isLoading">
  <div *ngIf="isGlobalTab" class="row col-10">

    <div class="row">
      <div class="col-md-2 formHeader alignRight p-10">
        Service Desk Timezone
      </div>
      <div class="col p-10">
        <select [(ngModel)]="data.DefaultTimezone" class="form-control" data-style="btn-white">
          <option *ngFor="let option of data.Options" [label]="option" [value]="option" selected="selected"></option>
        </select>
      </div>
    </div>

    <div class="row">
      <div class="col-md-2 formHeader alignRight p-10">
        Default Send From Name
      </div>
      <div class="col p-10">
        <input [(ngModel)]="data.DefaultEmailSendFromName" type="text" class="form-control" id="FilterName" placeholder="" autocomplete="off">
      </div>
    </div>

    <div class="row">
      <div class="col-md-2 formHeader alignRight p-10">
        Default E-mail Address
      </div>
      <div class="col p-10">
        <input [(ngModel)]="data.DefaultEmailAddress" type="text" class="form-control" id="FilterName" placeholder="" autocomplete="off">
      </div>
    </div>

    <div class="row">
      <div class="col-md-2 formHeader alignRight p-10">
        Preferred Date Format
      </div>
      <div class="col p-10">
        <ng-select [items]="preferredDateFormats"
                   bindLabel="name"
                   bindValue="id"
                   [(ngModel)]="data.PreferredDateFormat"
                   [clearable]="false">
        </ng-select>
      </div>
    </div>

    <div class="row">
      <div class="col-md-2 formHeader alignRight p-10">
        Service Desk Logo
      </div>
      <div class="col p-10">

        <img style="border: #e1e1e1 solid 1px; max-height:45px; max-width:200px " [src]="data.LogoUrl">

        <input ng-model="SelectedAttachment"
               id="ServiceDeskLogo"
               type="file" class="filestyle" (change)="onFileSelected($event)">
      </div>
    </div>

    <div class="row">
      <div class="col-md-2 formHeader alignRight p-10">
        Business Hours
      </div>
      <div class="col p-10">
        <div class="col-6">

          <div class="row">
            <div class="col-sm-2"></div>
            <div class="col-sm-2">Open</div>
            <div class="col-sm-2">24h</div>
            <div class="col-sm-3">Start</div>
            <div class="col-sm-3">Finish</div>
          </div>

          <div class="row">
            <div class="col-sm-2 p-5">Monday</div>
            <div class="col-sm-2 p-5">
              <div class="checkbox checkbox-primary">
                <input class="form-check-input" type="checkbox" [(ngModel)]="data.Monday.Enabled" (change)="updateDayData('Monday')">
                <label>
                </label>
              </div>
            </div>
            <div class="col-sm-2 p-5">
              <div class="checkbox checkbox-primary">
                <input class="form-check-input" type="checkbox" *ngIf="data.Monday.Enabled" [(ngModel)]="data.Monday.TwentyFour" (change)="updateDayData('Monday')">
                <label>
                </label>
              </div>
            </div>
            <div class="col-sm-3">
              <input size="4" class="form-control input-sm" type="time" [(ngModel)]="data.Monday.Start" *ngIf="!data.Monday.TwentyFour && data.Monday.Enabled" [disabled]="data.Monday.TwentyFour"></div>
            <div class="col-sm-3">
              <input size="4" class="form-control input-sm" type="time" [(ngModel)]="data.Monday.Finish" *ngIf="!data.Monday.TwentyFour && data.Monday.Enabled" [disabled]="data.Monday.TwentyFour"></div>
          </div>

          <div class="row">
            <div class="col-sm-2 p-5">Tuesday</div>
            <div class="col-sm-2 p-5">
              <div class="checkbox checkbox-primary">
                <input class="form-check-input" type="checkbox" [(ngModel)]="data.Tuesday.Enabled" (change)="updateDayData('Tuesday')">
                <label>
                </label>
              </div>
            </div>
            <div class="col-sm-2 p-5">
              <div class="checkbox checkbox-primary">
                <input class="form-check-input" type="checkbox" *ngIf="data.Tuesday.Enabled" [(ngModel)]="data.Tuesday.TwentyFour" (change)="updateDayData('Tuesday')">
                <label>
                </label>
              </div>
            </div>
            <div class="col-sm-3">
              <input size="4" class="form-control input-sm" type="time" [(ngModel)]="data.Tuesday.Start" *ngIf="!data.Tuesday.TwentyFour && data.Tuesday.Enabled" [disabled]="data.Tuesday.TwentyFour"></div>
            <div class="col-sm-3">
              <input size="4" class="form-control input-sm" type="time" [(ngModel)]="data.Tuesday.Finish" *ngIf="!data.Tuesday.TwentyFour && data.Tuesday.Enabled" [disabled]="data.Tuesday.TwentyFour"></div>
          </div>

          <div class="row">
            <div class="col-sm-2 p-5">Wednesday</div>
            <div class="col-sm-2 p-5">
              <div class="checkbox checkbox-primary">
                <input class="form-check-input" type="checkbox" [(ngModel)]="data.Wednesday.Enabled" (change)="updateDayData('Wednesday')">
                <label>
                </label>
              </div>
            </div>
            <div class="col-sm-2 p-5">
              <div class="checkbox checkbox-primary">
                <input class="form-check-input" type="checkbox" *ngIf="data.Wednesday.Enabled" [(ngModel)]="data.Wednesday.TwentyFour" (change)="updateDayData('Wednesday')">
                <label>
                </label>
              </div>
            </div>
            <div class="col-sm-3">
              <input size="4" class="form-control input-sm" type="time" [(ngModel)]="data.Wednesday.Start" *ngIf="!data.Wednesday.TwentyFour && data.Wednesday.Enabled" [disabled]="data.Wednesday.TwentyFour"></div>
            <div class="col-sm-3">
              <input size="4" class="form-control input-sm" type="time" [(ngModel)]="data.Wednesday.Finish" *ngIf="!data.Wednesday.TwentyFour && data.Wednesday.Enabled" [disabled]="data.Wednesday.TwentyFour"></div>
          </div>

          <div class="row">
            <div class="col-sm-2 p-5">Thursday</div>
            <div class="col-sm-2 p-5">
              <div class="checkbox checkbox-primary">
                <input class="form-check-input" type="checkbox" [(ngModel)]="data.Thursday.Enabled" (change)="updateDayData('Thursday')">
                <label>
                </label>
              </div>
            </div>
            <div class="col-sm-2 p-5">
              <div class="checkbox checkbox-primary">
                <input class="form-check-input" type="checkbox" *ngIf="data.Thursday.Enabled" [(ngModel)]="data.Thursday.TwentyFour" (change)="updateDayData('Thursday')">
                <label>
                </label>
              </div>
            </div>
            <div class="col-sm-3">
              <input size="4" class="form-control input-sm" type="time" [(ngModel)]="data.Thursday.Start" *ngIf="!data.Thursday.TwentyFour && data.Thursday.Enabled" [disabled]="data.Thursday.TwentyFour"></div>
            <div class="col-sm-3">
              <input size="4" class="form-control input-sm" type="time" [(ngModel)]="data.Thursday.Finish" *ngIf="!data.Thursday.TwentyFour && data.Thursday.Enabled" [disabled]="data.Thursday.TwentyFour"></div>
          </div>

          <div class="row">
            <div class="col-sm-2 p-5">Friday</div>
            <div class="col-sm-2 p-5">
              <div class="checkbox checkbox-primary">
                <input class="form-check-input" type="checkbox" [(ngModel)]="data.Friday.Enabled" (change)="updateDayData('Friday')">
                <label>
                </label>
              </div>
            </div>
            <div class="col-sm-2 p-5">
              <div class="checkbox checkbox-primary">
                <input class="form-check-input" type="checkbox" *ngIf="data.Friday.Enabled" [(ngModel)]="data.Friday.TwentyFour" (change)="updateDayData('Friday')">
                <label>
                </label>
              </div>
            </div>
            <div class="col-sm-3">
              <input size="4" class="form-control input-sm" type="time" [(ngModel)]="data.Friday.Start" *ngIf="!data.Friday.TwentyFour && data.Friday.Enabled" [disabled]="data.Friday.TwentyFour"></div>
            <div class="col-sm-3">
              <input size="4" class="form-control input-sm" type="time" [(ngModel)]="data.Friday.Finish" *ngIf="!data.Friday.TwentyFour && data.Friday.Enabled" [disabled]="data.Friday.TwentyFour"></div>
          </div>

          <div class="row">
            <div class="col-sm-2 p-5">Saturday</div>
            <div class="col-sm-2 p-5">
              <div class="checkbox checkbox-primary">
                <input class="form-check-input" type="checkbox" [(ngModel)]="data.Saturday.Enabled" (change)="updateDayData('Saturday')">
                <label>
                </label>
              </div>
            </div>
            <div class="col-sm-2 p-5">
              <div class="checkbox checkbox-primary">
                <input class="form-check-input" type="checkbox" *ngIf="data.Saturday.Enabled" [(ngModel)]="data.Saturday.TwentyFour" (change)="updateDayData('Saturday')">
                <label>
                </label>
              </div>
            </div>
            <div class="col-sm-3">
              <input size="4" class="form-control input-sm" type="time" [(ngModel)]="data.Saturday.Start" *ngIf="!data.Saturday.TwentyFour && data.Saturday.Enabled" [disabled]="data.Saturday.TwentyFour"></div>
            <div class="col-sm-3">
              <input size="4" class="form-control input-sm" type="time" [(ngModel)]="data.Saturday.Finish" *ngIf="!data.Saturday.TwentyFour && data.Saturday.Enabled" [disabled]="data.Saturday.TwentyFour"></div>
          </div>

          <div class="row">
            <div class="col-sm-2 p-5">Sunday</div>
            <div class="col-sm-2 p-5">
              <div class="checkbox checkbox-primary">
                <input class="form-check-input" type="checkbox" [(ngModel)]="data.Sunday.Enabled" (change)="updateDayData('Sunday')">
                <label>
                </label>
              </div>
            </div>
            <div class="col-sm-2 p-5">
              <div class="checkbox checkbox-primary">
                <input class="form-check-input" type="checkbox" *ngIf="data.Sunday.Enabled" [(ngModel)]="data.Sunday.TwentyFour" (change)="updateDayData('Sunday')">
                <label>
                </label>
              </div>
            </div>
            <div class="col-sm-3">
              <input size="4" class="form-control input-sm" type="time" [(ngModel)]="data.Sunday.Start" *ngIf="!data.Sunday.TwentyFour && data.Sunday.Enabled" [disabled]="data.Sunday.TwentyFour"></div>
            <div class="col-sm-3">
              <input size="4" class="form-control input-sm" type="time" [(ngModel)]="data.Sunday.Finish" *ngIf="!data.Sunday.TwentyFour && data.Sunday.Enabled" [disabled]="data.Sunday.TwentyFour"></div>
          </div>

          <div class="">
            <div (click)="saveData()" class="btn btn-primary">Save</div>
          </div>

        </div>
      </div>
    </div>
  </div>
  <div class="row col-10" *ngIf="isTicketsTab">
    <!-- <div class="row mb-4">
        <div class="col-md-3 formHeader alignRight p-10">
          Automaticall resolve ticket after
        </div>
        <div class="col-md-4">
          <input type="number" [(ngModel)]="ticketSettings.ResolveInHours" id="resolveInHours" placeholder="No. of Hours" class="form-control">
          <p class="mt-1 note">Note: The system will automatically resolve the ticket after the selected number of hours.</p>
        </div>
        <div class="col-md-2">
            <input class="form-check-input hoursdays-check" type="checkbox" [(ngModel)]="ticketSettings.IsResolveInHours" >            
        </div>
      </div> -->
      <div class="row mb-4">
        <div class="col-md-3 formHeader alignRight p-10">
          Disable reopen ticket
        </div>
        <div class="col-md-4">
          <input [(ngModel)]="ticketSettings.ReopenLimitDays" type="number" id="resolveInDays" class="form-control" placeholder="No. of Business Days" autocomplete="off" >
          <p class="mt-1 note">Note:The system will disable to re-open a resolved ticket after the selected business days.</p>
        </div>
     <div class="col-md-2">
        <input class="form-check-input hoursdays-check" type="checkbox" [(ngModel)]="ticketSettings.IsReopenLimitDays">
        </div>
      </div>
      <div class="row">
        <div class="col-md-3 formHeader alignRight p-10">
            <div (click)="saveSettingsData()" class="btn btn-primary">Save</div>
        </div>
        <div class="col-md-4">
          </div>
      </div>
    </div>
</ng-container>


