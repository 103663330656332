import {Component, Input, OnInit} from '@angular/core';
import * as Highcharts from 'highcharts';
import {MomentModule} from "ngx-moment";
import * as moment from 'moment';


@Component({
  selector: 'app-highchart-time-series',
  templateUrl: './highchart-time-series.component.html',
  styleUrls: ['./highchart-time-series.component.css']
})
export class HighchartTimeSeriesComponent implements OnInit {

  @Input() data = [];
  @Input() height = '100px';
  @Input() dataUnit = '%';
  @Input() yAxixMax = null;
  @Input() threshold = null;
  @Input() reverseThreshold = false; // if enabled, high is good, low is bad
  @Input() graphType = 'area' || 'line';

  public testData = [];

  public showGraph = false;
  public options: any;

  Highcharts: typeof Highcharts = Highcharts; // required
  chartConstructor: string = 'chart'; // optional string, defaults to 'chart'
  chartCallback: Highcharts.ChartCallbackFunction = function (chart) {
    null
  } // optional function, defaults to null
  updateFlag: boolean = true; // optional boolean
  oneToOneFlag: boolean = true; // optional boolean, defaults to false
  runOutsideAngular: boolean = false; // optional boolean, defaults to false
  private global: string;

  constructor() {
    var global = this;
  }

  ngOnInit() {

    this.options = {
      credits: {
        enabled: false
      },
      time: {
        useUTC: false
      },
      chart: {
        height: 100,
        type: 'column',
        // zoomType: 'x'
      },
      title: {
        text: null
      },
      xAxis: {
        formatter: function () {
          return moment(this.x).calendar();
        },
        type: 'datetime',
        ordinal: true
      },
      yAxis: {
        labels: {
          format: '{value}' + this.dataUnit
        },
        title: {
          text: null
        },
        min: 0,
        endOnTick: true
      },
      tooltip: {},
      // tooltip: {
      //   backgroundColor: '#f7f7f7',
      //   borderColor: '#e0e0e0',
      //   borderRadius: 3,
      //   borderWidth: 1,
      //   headerFormat: '{point.key}<br/>'
      //
      // },
      legend: {
        enabled: false
      },
      plotOptions: {
        column: {
          pointPadding: 0,
          //pointInterval: 300000,
          maxPointWidth: 5,
          borderWidth: 0
        },
        area: {
          // fillColor: '#557ea5',
          marker: {
            // fillColor: '#973838',
            radius: 1
          },
          lineWidth: 0,
          lineColor: '#973838',
          states: {
            hover: {
              radius: 2,
              lineWidth: 1
            }
          },
          threshold: null
        }
      },
      series: [{
        // color: 'indianred',
        type: this.graphType,
        name: 'CPU Usage',
        data: this.data,
        zones: [{
          color: this.reverseThreshold ? 'indianred' : '#669a80',
          value: this.threshold
        }, {
          color: this.reverseThreshold ? '#669a80' : 'indianred'
        }]
      }],
      responsive: {
        rules: {
          condition: {
            maxHeight: '150px'
          }
        }
      }
    }

    if (this.yAxixMax) {
      if (this.dataUnit == '%') {
        this.options.yAxis.max = this.yAxixMax;
      } else {
        this.options.yAxis.softMax = this.yAxixMax;
      }
      //this.options.yAxis.ceiling = this.yAxixMax;
    }
    if (this.dataUnit == '%') {
      this.options.tooltip.formatter = function () {
        return '<b>' + moment(this.x).calendar() + '</b><br/>' + this.y + '%';
      }
    }

    if (this.dataUnit == 'ms') {
      this.options.tooltip.formatter = function () {
        return '<b>' + moment(this.x).calendar() + '</b><br/>' + this.y + 'ms';
      }
    }


    this.showGraph = true;

    // Highcharts.chart('chartContainer', this.options);
  }

}
