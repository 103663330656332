<div id="thread" class="row p-t-20 m-l-5">
<!--  Only show tabs if thread is greater than 1 -->
  <div id="threadTabs" *ngIf="(requestThread.Threads).length > 1" class="row">
    <div class="col">
      <ul class="nav m-b-5">
        <li class="nav-item divLink" *ngFor="let thread of requestThread.Threads" (click)="changeThread(thread.ThreadId)" style="margin-bottom: 20px;">
          <a class="nav-link-thread" [class.active]="selectedThreadId == thread.ThreadId">{{ getThreadLabel(thread) }}
          <span *ngIf="thread.UnreadCount"><strong> ({{thread.UnreadCount}}) </strong></span>
          </a>
        </li>
      </ul>
    </div>
  </div>
  <div id="threadContent" class="row m-t-1">
    <app-request-thread-item
    *ngFor="let threadItem of getThreadItems(this.selectedThreadId)" 
    [threadItem]="threadItem"
    [threadItemAttachments]="getThreadItemAttachments(threadItem.Id)"
    [isRequestClosed]="requestDetailsClosed"
    (refreshData)="onRefreshThreadItems()">
  </app-request-thread-item>
  </div>
</div>
