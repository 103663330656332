import { Component, OnInit, ViewContainerRef } from '@angular/core';
import { ChangeDetectorRef } from '@angular/core';
import { AdminUsersComponentDialog } from "./admin-users-create-edit-dialog/admin-users-create-edit-dialog.component";
import { ApiAdminUsersService } from "../../../../../services/api/admin/admin-users-api/admin-users-api.service";
import { DialogService } from "../../../../../services/dialog-service/dialog.service";
import { ModalConfirmationComponent } from "../../../../../shared/modal-confirmation/modal-confirmation.component";
import { AgentUserService } from 'src/app/services/api/agent-user/agent-user.service';
import { saveAs } from 'file-saver';


@Component({
  selector: 'app-admin-users',
  templateUrl: './admin-users.html',
  styleUrls: ['./admin-users.css']
})
export class AdminUsersComponent implements OnInit {
  data;
  paginateddata;
  recordCount
  totaldata;
  isLoading = true;
  searchTerm = '';
  orderTableBy = '';
  orderTableByAsc = true;
  totalUsers = 0;
  totalAdmins = 0;
  totalAgent = 0;
  totalsspe = 0;
  first:number=0;

  rows: number = 50;
  showingRowscount:number = 50;
  fromRowscount:number = 1;
  currentPage: number = 1;
  constructor(
    private apiAdminUsersService: ApiAdminUsersService,
    private agentUserService:AgentUserService,
    private dialogService: DialogService,
    private viewReference: ViewContainerRef,
    private cdr: ChangeDetectorRef
  ) {
    this.getData();
  }

  ngOnInit(): void {
    this.getDataTotal()
   }

  changeOrder(column: string): void {
    if (this.orderTableBy === column) {
      this.orderTableByAsc = !this.orderTableByAsc; // Toggle sort direction
    } else {
      this.orderTableBy = column;
      this.orderTableByAsc = true; // Default to ascending
    }
    // console.log(`Sorting by ${column}, ascending: ${this.orderTableByAsc}`);
    this.filteredData(); // Sort and filter data
  }
  refreshView() {
    this.cdr.detectChanges();
  }

  filteredData() {
    if(this.searchTerm)
      {
        const searchTerm = this.searchTerm.toLowerCase();
        this.data = this.totaldata.Result || [];
        this.recordCount = this.totaldata.RecordCount;

        this.data = this.data.filter(row => typeof row.Name === 'string' && row.Name.toLowerCase().includes(searchTerm));
      }
      else
      {
        if(this.paginateddata)
          {
            this.data = this.paginateddata.Result || [];
            this.recordCount = this.paginateddata.RecordCount;
          }
      }

    // sorting out
    if (this.orderTableBy) {
      this.data.sort((a, b) => {
        let valA = a[this.orderTableBy];
        let valB = b[this.orderTableBy];

        // Convert null values to empty strings for comparison
        valA = valA === null ? '' : valA;
        valB = valB === null ? '' : valB;

        if (typeof valA === 'string' && typeof valB === 'string') {
          valA = valA.toLowerCase();
          valB = valB.toLowerCase();
        }
        if (valA < valB) return this.orderTableByAsc ? -1 : 1;
        if (valA > valB) return this.orderTableByAsc ? 1 : -1;
        return 0;
      });
    }
    return this.data;
}

getDataTotal() {
  this.isLoading = true;
  this.apiAdminUsersService.listAll().then(
    response => {
      this.totaldata = response;
      this.filteredData(); // for filters and sorting
      this.updateCounts();
      this.isLoading = false;
    },
    error => {
      console.error('Failed to fetch data', error);
      this.isLoading = false;
    }
  );
}
  getData() {
    this.isLoading = true;
    let perPage = 50
    this.agentUserService.GetPagination(this.currentPage,perPage).then(
      response => {
        this.paginateddata = response;
        this.isLoading = false;
      },
      error => {
        console.error('Failed to fetch data', error);
        this.isLoading = false;
      }
    );
  }

  exportExcel() {

    import("xlsx").then(xlsx => {
      const worksheet = xlsx.utils.json_to_sheet(this.totaldata.Result);
      const workbook = { Sheets: { 'data': worksheet }, SheetNames: ['data'] };
      const excelBuffer = xlsx.write(workbook, { bookType: 'xlsx', type: 'array' });
      this.saveAsExcelFile(excelBuffer, "users");
    })

}


saveAsExcelFile(buffer: any, fileName: string): void {
  let EXCEL_TYPE = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
  let EXCEL_EXTENSION = '.xlsx';
  const data: Blob = new Blob([buffer], {
    type: EXCEL_TYPE
  });
  console.log("downloading file")
  saveAs(data, fileName + '_export_' + new Date().getTime() + EXCEL_EXTENSION);
}

  isLastPage(): boolean {
    if(this.paginateddata)
      {
    return this.currentPage === Math.ceil(this.paginateddata.RecordCount / this.showingRowscount);
      }
  }

  updateCounts() {
    if (!this.totaldata) return;
    this.totalUsers = this.totaldata.Result.length;
    this.totalAdmins = this.totaldata.Result.filter(user => user.Admin).length;
    this.totalAgent = this.totaldata.Result.filter(user => user.Agent).length;
    this.totalsspe = this.totaldata.Result.filter(user => user.AllowPortalLogin).length;
  }

  create() {
    this.dialogService.createDialog(AdminUsersComponentDialog, null, this.viewReference)
      .then(dialogSaved => this.getData(), dialogCancelled => {});
  }

  edit(id) {
    this.dialogService.createDialog(AdminUsersComponentDialog, id , this.viewReference)
      .then(dialogSaved => this.getData(), dialogCancelled => {});
  }

  delete(id) {
    this.dialogService.createDialog(ModalConfirmationComponent, 'Are you sure?', this.viewReference)
      .then(confirmed => {
        if (confirmed) {
          this.apiAdminUsersService.delete(id).then(() => this.getData());
        }
      }, cancelled => {});
  }
  onPageChange(event)
  {
    let page = event.page + 1;
    let perpage = event.rows;
    this.showingRowscount = page * perpage;
    this.fromRowscount = ((page-1) * perpage)+1;
    this.agentUserService.GetPagination(page,perpage).then(
      response => {
        this.paginateddata = response;
        this.isLoading = false;
      },
      error => {
        console.error('Failed to fetch data', error);
        this.isLoading = false;
      }
    );
  }
}
