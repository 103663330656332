<span *ngIf="!isLoading && onlineUserData !== undefined">
<i *ngIf="onlineUserData.MinutesSinceLastOnline === null"
   [matTooltip]="'Last Online: Never'" [matTooltipPosition]="'above'" [matTooltipClass]="'sdesk-tooltip'"
   class="fas fa-circle" style="color: #969696; padding-left: 1px"></i>

<!-- Online -->
<i *ngIf="onlineUserData.MinutesSinceLastOnline !== null && onlineUserData.MinutesSinceLastOnline <= 2"
   [matTooltip]="'Last Online: '+(onlineUserData.LastActivityDateTime | amTimeAgo)" [matTooltipPosition]="'above'" [matTooltipClass]="'sdesk-tooltip'"
   class="fas fa-circle" style="color: #81b29a; padding-left: 1px"></i>

<!-- Idle -->
<i *ngIf="onlineUserData.MinutesSinceLastOnline !== null && (onlineUserData.MinutesSinceLastOnline >= 3 && onlineUserData.MinutesSinceLastOnline <= 30)"
   [matTooltip]="'Last Online: '+(onlineUserData.LastActivityDateTime | amTimeAgo)" [matTooltipPosition]="'above'" [matTooltipClass]="'sdesk-tooltip'"
   class="fas fa-circle" style="color: #f4a261; padding-left: 1px"></i>

<!-- Offline -->
<i *ngIf="onlineUserData.MinutesSinceLastOnline !== null && onlineUserData.MinutesSinceLastOnline > 30"
   [matTooltip]="'Last Online: '+(onlineUserData.LastActivityDateTime | amTimeAgo)" [matTooltipPosition]="'above'" [matTooltipClass]="'sdesk-tooltip'"
   class="fas fa-circle" style="color: #cd5c5c; padding-left: 1px"></i>

</span>
