

import {Component, OnInit, ViewContainerRef} from '@angular/core';
import { ApiAdminLinkConfigurationService } from 'src/app/services/api/admin/admin-link-configuration-api/admin-link-configuration-api.service';
import {DialogService} from "../../../../../services/dialog-service/dialog.service";
import {AdminLinkConfigurationComponentDialog} from "./admin-link-configuration-create-edit-dialog/admin-link-configuration-create-edit-dialog.component";
import {ModalConfirmationComponent} from "../../../../../shared/modal-confirmation/modal-confirmation.component";

@Component({
  selector: 'app-admin-link-configuration',
  templateUrl: './admin-link-configuration.html'
})
export class AdminLinkConfigurationComponent implements OnInit {

  data;
  isLoading = true;

  constructor(private apiAdminLinkConfigurationService: ApiAdminLinkConfigurationService, private dialogService: DialogService, private viewReference: ViewContainerRef) {
    this.getData();
  }

  ngOnInit(): void {
  }

  getData(){
    this.isLoading = true;
    this.apiAdminLinkConfigurationService.listAll().then(response => {
      this.data = response;
      this.isLoading = false;
    })
  }

  create(){
    // Replace null with Component
    this.dialogService.createDialog(AdminLinkConfigurationComponentDialog, null, this.viewReference)
      .then(dialogSaved => {
        this.getData();
      }, dialogCancelled => {

      })
  }

  edit(id){
    // Replace null with Component
    this.dialogService.createDialog(AdminLinkConfigurationComponentDialog, id, this.viewReference)
      .then(dialogSaved => {
        this.getData();
      }, dialogCancelled => {

      })
  }

  delete(id){
    this.dialogService.createDialog(ModalConfirmationComponent, 'Are you sure?', this.viewReference)
      .then(confirmed => {
        this.apiAdminLinkConfigurationService.delete(id).then(response => this.getData())
      }, cancelled => null)
  }



}
