<div class="SDeskSidePanel-GreyBackground"></div>

<app-blade-right  *ngIf="!isLoading && !isLoadingRequestTypes" size="blade-large">
  <div class="p-10 p-b-0">
    <i (click)="this.close()" class="fas fa-times fa-2x float-end divLink"></i>
    <h3>{{this.loadedId ? 'Edit' : 'Create'}} Canned Response</h3>
  </div>
  <hr>
  <div class="container p-0">

    <div class="row">
      <div class="col-sm-3 formHeader alignMiddle p-10">
        Name <span class="text-danger">*</span>
      </div>
      <div class="col-sm p-10">
        <input [(ngModel)]="loadedData.Name" type="text" class="form-control" id="Name" placeholder="Name" autocomplete="off">

      </div>
    </div>

    <div class="row">
      <div class="col-sm-3 formHeader alignMiddle p-10">
        Default Request Type <span class="text-danger">*</span>
      </div>
      <div class="col-sm p-10">

        <!-- Cannot change requestType on edit -->
        <ng-select [items]="requestTypes"
                   bindLabel="name"
                   bindValue="id"
                   [(ngModel)]="loadedData.TypeId"
                   [clearable]="false"
                   [disabled]="this.loadedId">
        </ng-select>
      </div>
    </div>

    <div class="row">
      <div class="col">
        <quill-editor [(ngModel)]="loadedData.Response" [styles]="{height: '150px'}" placeholder=""></quill-editor>

      </div>
    </div>

    <app-template-syntax></app-template-syntax>

    <div class="m-t-20">
      <div (click)="save()" class="btn btn-primary">Save</div>
      <div (click)="close()" class="btn btn-cancel">Cancel</div>
    </div>

  </div>
</app-blade-right>
