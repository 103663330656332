import { Injectable } from '@angular/core';
import {ApiService} from "../api.service";

@Injectable({
  providedIn: 'root'
})
export class EnvironmentApiService {

  private environmentApi = "/api/environment";

  constructor(private api: ApiService) { }

  public getEnvironmentInfo(){
    return this.api.get(this.environmentApi);
  }

}
