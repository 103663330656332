import { Component, OnInit } from '@angular/core';
import { SelfServiceApiService } from '../../services/api/self-service/self-service-api.service';
import { ActivatedRoute, Router } from '@angular/router';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';

@Component({
  selector: 'app-self-service-solutions-article',
  templateUrl: './self-service-solutions-article.component.html',
  styleUrls: ['./self-service-solutions-article.component.css']
})
export class SelfServiceSolutionsArticleComponent implements OnInit {
  articleUrl: string;
  articleData: any;
  categoryData: any;
  filteredArticles: any[] = [];
  isLoading = true;
  public bodyString: string;
  public convertedBody: any;
  articleTags: any[] = [];
  currentUrl: string;
  isCopied: boolean = false;

  constructor(
    private selfServiceApi: SelfServiceApiService,
    private route: ActivatedRoute,
    private sanitizer: DomSanitizer,
    private router: Router
  ) {}

  ngOnInit(): void {
    this.route.paramMap.subscribe(params => {
      this.articleUrl = params.get('ArticleUrl');
      this.currentUrl = window.location.href;
      this.getData();
    });
  }

  async getData(silent = false): Promise<void> {
    try {
      const response = await this.selfServiceApi.getKnowledgeBaseArticle(this.articleUrl);
      this.articleData = response;
      this.bodyString = this.articleData.Body;
      this.convertedBody = this.decode(this.bodyString);
      this.articleData.Body = this.convertedBody;

      if (this.articleData.CategoryId) {
        try {
          this.categoryData = await this.selfServiceApi.getCategoryDetails(this.articleData.CategoryId);
        } catch (error) {
          console.error('Error fetching category data:', error);
        }
      }

      this.filteredArticles = this.articleData.RelatedArticles;
      await this.fetchTags();
      this.isLoading = false;
    } catch (error) {
      console.error('Error fetching article data:', error);
    }
  }
  filterByCategoryAndNavigate(categoryId: number): void {
    console.log('Navigating with category ID:', categoryId); // Log the category ID
    this.router.navigate([''], { queryParams: { categoryId: categoryId } });
  }

  sanitize(html: string): SafeHtml {
    return this.sanitizer.bypassSecurityTrustHtml(html);
  }

  decode(str: string): string {
    let txt = document.createElement('textarea');
    txt.innerHTML = str;
    return txt.value;
  }

  async fetchTags(): Promise<void> {
    try {
      const allTags = await this.selfServiceApi.getAllArticleTags();
      if (this.articleData.Id) {
        this.articleTags = allTags.filter(tag => tag.ArticleId === this.articleData.Id);
      } else {
        console.error('Article ID is undefined.');
      }
    } catch (error) {
      console.error('Error fetching tags:', error);
    }
  }

  copyLink(): void {
    if (this.currentUrl) {
      const textarea = document.createElement('textarea');
      textarea.value = this.currentUrl;
      document.body.appendChild(textarea);
      textarea.select();
      document.execCommand('copy');
      document.body.removeChild(textarea);
      this.isCopied = true;
      setTimeout(() => {
        this.isCopied = false;
      }, 2000);
    }
  }
}
