import { Injectable } from '@angular/core';
import {BehaviorSubject} from "rxjs";
import {ApiService} from "../api/api.service";

@Injectable({
  providedIn: 'root'
})
export class UserPermissionsService {

  public userPermissionsSubscription = new BehaviorSubject<any>(null);
  private userPermissionUrl = '/api/userpermissions'

  constructor(private api: ApiService) {}

  public refreshUserPermissions() {
    this.api.get(this.userPermissionUrl).then(response => {
      this.userPermissionsSubscription.next(response);
    }).catch(error => {
        console.error(error);
        console.error('Failed to get user permissions');
      }
    );
  }

}
