import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-modaltest',
  templateUrl: './modaltest.component.html',
  styleUrls: ['./modaltest.component.css']
})
export class ModaltestComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
