<div style="position: absolute; right: -4px;" *ngIf="this.selectedTickets.selected.length" class="dropdown">
  <a class="btn btn-secondary btn-sm dropdown-toggle" href="#" role="button" id="dropdownMenuLink" data-bs-toggle="dropdown" aria-expanded="false">
    {{this.selectedTickets.selected.length}} Selected
  </a>

  <ul class="dropdown-menu" aria-labelledby="dropdownMenuLink" *ngIf="!isCmdbEnabled">
    <li><a class="dropdown-item divLink" [class.disabled]="this.selectedTickets.selected.length < 2" (click)="this.mergeSelected()">Merge</a></li>
    <li><a class="dropdown-item divLink" (click)="this.deleteSelected()">Delete</a></li>
  </ul>
  <ul class="dropdown-menu" aria-labelledby="dropdownMenuLink" *ngIf="isCmdbEnabled">
    <li><a class="dropdown-item divLink" [class.disabled]="this.selectedTickets.selected.length < 2" (click)="this.mergeSelected()">Merge</a></li>
    <li><a class="dropdown-item divLink" [class.disabled]="accessPermissions.AssetManagementAdmin == 0" (click)="this.deleteSelected()">Delete</a></li>
  </ul>
</div>
