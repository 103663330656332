<div class="SDeskSidePanel-GreyBackground"></div>

<app-blade-right  *ngIf="!isLoading" size="blade-large">
  <div class="p-10 p-b-0">
    <i (click)="this.close()" class="fas fa-times fa-2x float-end divLink"></i>
    <h3>{{this.loadedId ? 'Edit' : 'Add'}} SLA Holiday</h3>
  </div>
  <hr>
  <div class="container p-0">

    <div class="row">
      <div class="col-sm-2 formHeader alignMiddle p-10">
        Date <span class="text-danger">*</span>
      </div>
      <div class="col-sm p-10">
        <!-- <input [(ngModel)]="loadedData.Date" type="date" class="form-control" id="date" placeholder="Enter date in format YYYY-MM-DD" autocomplete="off"> -->
        <p-calendar  
        [(ngModel)]="loadedData.Date"
        [showIcon]="true" 
        ariaLabel="Date"
        [iconDisplay]="'input'"
        inputId="Planning_ScheduledStart"
        [readonlyInput]="true"
        [showButtonBar]="true"
        [locale]="{firstDayOfWeek: 1, today: 'Today', clear: 'Clear'}"
      ></p-calendar>
      </div>
    </div>

    <div class="m-t-20">
      <div (click)="save()" class="btn btn-primary">Save</div>
      <div (click)="close()" class="btn btn-cancel">Cancel</div>
    </div>

  </div>
</app-blade-right>
