import { Component, EventEmitter, Input, Output } from "@angular/core";
import { ToastrService } from "ngx-toastr";
import { ApiAdminIcxService } from "src/app/services/api/admin/admin-icx-api/admin-icx-api.service";
import { Dialog } from "src/app/shared/dialog/dialog";

@Component({
  selector: 'app-admin-icx-settings-create-edit-dialog',
  templateUrl: './admin-icx-settings-create-edit-dialog.component.html'
})

export class AdminIcxSettingsComponentDialog implements Dialog {
  @Input() data;
  @Output() remove = new EventEmitter<any>();
  loadedData;

  isLoading = true;

  constructor(
    private apiAdminIcxService: ApiAdminIcxService,  private toastr: ToastrService
  ) {}

  ngOnInit(): void {
    if (typeof this.data === 'object' && this.data !== null) {
      this.loadedData = this.data;
    } else {
      this.loadedData = {
        ClientName: ''
      };
    }
    this.isLoading = false;
  }

  save() {
    // Check if ClientName contains spaces
    if (!this.loadedData.ClientName || /\s/.test(this.loadedData.ClientName)) {
      // Show toastr error if ClientName contains spaces or is empty
      this.toastr.error('Client Name cannot contain spaces and is mandatory', 'Error');
      return; // Exit the function without calling the API
    }

    this.apiAdminIcxService.create(this.loadedData).then(response => {
      this.remove.emit("Saved");
    });
  }

  preventSpace(event: KeyboardEvent): void {
    if (event.key === ' ') {
      event.preventDefault();
    }
  }



  close(){
    this.remove.emit(null);
  }
}
