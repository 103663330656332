import {Component, Input, OnInit} from '@angular/core';

@Component({
  selector: 'app-shared-tasks-component',
  templateUrl: './shared-tasks-component.component.html',
  styleUrls: ['./shared-tasks-component.component.css']
})
export class SharedTasksComponentComponent implements OnInit {
  @Input() tasks = [];

  constructor() { }

  ngOnInit(): void {
  }

}
