import { Component, OnInit, ChangeDetectorRef, ApplicationRef } from '@angular/core';
import { SelfServiceApiService } from '../../services/api/self-service/self-service-api.service';
import { EnvironmentService } from '../../services/environment/environment.service';
import { ActivatedRoute, Router } from '@angular/router';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';

interface ArticleTag {
  ArticleId: number;
  Tag: string;
}

interface Category {
  Id: number;
  Name: string;
  SearchCount: number;
}

interface Article {
  Id: number;
  Title: string;
  Body?: SafeHtml;
  Url: string;
  CategoryId: number;
  Tags?: string[];
  SearchCount: number;
  CategoryName?: string;
  showAllTags?: boolean; //whether all tags are shown
  TruncatedBody?: string;
}

@Component({
  selector: 'app-self-service-home',
  templateUrl: './self-service-home.component.html',
  styleUrls: ['./self-service-home.component.css']
})
export class SelfServiceHomeComponent implements OnInit {

  environmentSubscription;
  isLoadingEnvironmentVariables = true;
  filteredArticles: Article[] = [];
  filterField: string = '';
  isNoresults = false;
  solutionData: any;
  isLoading = true;
  tags: ArticleTag[] = [];
  articleId: number;

  isDropdownOpenAll: boolean = false;
  isDropdownOpenTags: boolean = false;
  isDropdownOpenCriteria: boolean = false;

  categories: Category[] = [];
  filteredCategories: Category[] = [];
  filteredTags: ArticleTag[] = [];
  sortedCategories: Category[] = [];
  displayCategoriesOnly: boolean = false;
  sortedByNewest: boolean = false;
  selectedArticle: any;
  isSortDropdownOpen: boolean = false;
  selectedCategory: number | null = null;
  selectedCategoryName: string = 'All';
  selectedTag: string = 'Tags'; // To store the selected tag
  categorySearch: string = '';
  tagSearch: string = '';
  showArticles: boolean = false;
  currentPage = 1;
  articlesPerPage : number=50;
  paginatedArticles: Article[] = []; 
  totalPages: number;
  totalArticles: number;
 searchTerm: string = "";
 selectedSearchCriteria: string = 'Search by';


  constructor(
    private selfServiceApi: SelfServiceApiService,
    private environmentService: EnvironmentService,
    private router: Router,
    private route: ActivatedRoute,
    private sanitizer: DomSanitizer,
    private cdr: ChangeDetectorRef,
    private appRef: ApplicationRef 
  ) {
    // Subscribe to environment service
    this.environmentService.environmentVariablesSubscription.subscribe(data => {
      if (!data) {
        return;
      } else {
        this.environmentSubscription = data;

        if (this.environmentSubscription.RequireLogin || !this.environmentSubscription.ShowKnowledgeBase) {
          this.router.navigate(['/solution']);
        }

        this.isLoadingEnvironmentVariables = false;
      }
    });
    this.cdr.markForCheck();
  }

  ngOnInit(): void {
    this.route.queryParams.subscribe(params => {
      const categoryId = params['categoryId'];
      //console.log('Received category ID in ngOnInit:', categoryId);
      if (categoryId) {
        this.getData(Number(categoryId));
      } else {
        this.getData().then(()=>{
          this.calculateTotalPages();
          this.paginateArticles();
          this.loadTags().then(() => {
            this.cdr.markForCheck();
        });
        
        }) 
      }
    });
  }

  onArticlesPerPageChange(newSize: number): void {
    this.articlesPerPage = newSize;
    this.currentPage = 1;
    this.calculateTotalPages();
    this.paginateArticles();
  }
  
  paginateArticles() {
    const startIndex = (this.currentPage - 1) * this.articlesPerPage;
    const endIndex = startIndex + this.articlesPerPage;
    this.paginatedArticles = this.filteredArticles.slice(startIndex, endIndex);
    this.cdr.markForCheck(); 
  }
  goToFirstPage(): void {
    this.currentPage = 1;
    this.paginateArticles();
  }
  goToNextPage() {
    if (this.currentPage < this.totalPages) {
      this.currentPage++;
    }
  }
  resetPagination(): void {
    this.currentPage = 1;
  }
  
  applyFilterOrSort(): void {
    this.resetPagination();
    this.paginateArticles();
    this.cdr.markForCheck();
  }


  goToPreviousPage(): void {
    if (this.currentPage > 1) {
      this.currentPage--;
    }
  }
goToLastPage(): void {
  this.currentPage = this.totalPages;
  this.paginateArticles();
}

goToPage(page: number): void {
  this.currentPage = page;
  this.paginateArticles();
}


pagesToShow(current: number, total: number): number[] {
  let pages: number[] = [];
  const range = 2;  // Show 2 pages before and after the current page
  const start = Math.max(1, current - range);
  const end = Math.min(total, current + range);

  for (let i = start; i <= end; i++) {
    pages.push(i);
  }

  return pages;
}

calculateTotalPages(): void {
  this.totalPages = Math.ceil(this.filteredArticles.length / this.articlesPerPage);
}



  filterByCategoryAndNavigate(categoryId: number): void {
    //console.log('Navigating to category ID:', categoryId); // Log the navigation
    this.router.navigate([''], { queryParams: { categoryId: categoryId } });
  }

  async getData(categoryId?: number) {
    try {
      this.solutionData = await this.selfServiceApi.getKnowledgeBasePreview();
      //console.log('Fetched solution data:', this.solutionData);

      if (this.solutionData && this.solutionData.Articles && this.solutionData.Articles.length > 0) {
        this.categories = this.solutionData.Categories;
      //  console.log('Categories:', this.categories);
        this.filteredCategories = this.categories; // Initialize filtered categories
        this.categories.forEach(category => {
          category.SearchCount = category.SearchCount || 0; // Initialize SearchCount if not already set
        });

        this.solutionData.Articles.forEach(article => {
          article.SearchCount = article.SearchCount || 0;
          // Add category name to each article
          const category = this.categories.find(cat => cat.Id === article.CategoryId);
          if (category) {
            article.CategoryName = category.Name;
          }
          article.showAllTags = false; // Initialize showAllTags property
        });
        
        this.articleId = this.solutionData.Articles[0].Id;
        this.calculateTotalPages();  // Calculate total pages once data is fetched
        this.paginateArticles();  
        await this.loadTags();
        //await this.loadArticleBodies();
        // Initialize with all articles
        this.filteredArticles = this.solutionData.Articles;

        if (categoryId) {
          this.showArticles = true; // Show articles only when category is selected from breadcrumb
          this.filterByCategory(categoryId); // Apply category filter if categoryId is present
        } else {
          this.showArticles = false; // Do not show articles by default
        }

        this.cdr.markForCheck();
      }

      this.isLoading = false;
    } catch (error) {
      console.error('Error fetching knowledge base preview', error);
      this.isLoading = false;
    }
  }

  // async loadArticleBodies() {
  //   const promises = this.solutionData.Articles.map(async (article: Article) => {
  //     try {
  //       const body = await this.selfServiceApi.getArticleBody(article.Url);
  //       article.Body = this.sanitizer.bypassSecurityTrustHtml(body);
  //       this.truncateArticleBody(article); 
  //       console.log('Article body fetched:', body)// Apply truncation after fetching and sanitizing the body
  //     } catch (error) {
  //       console.error('Error fetching article body', error);
  //     }
  //   });
  //   await Promise.all(promises);
  //   this.cdr.markForCheck();
  // }

  // truncateArticleBody(article: Article): void {
  //   const maxChars = 100; // Character limit
  //   if (article.Body) {
  //     // Extract text as string, handling SafeHtml if necessary
  //     let text = (article.Body as any).changingThisBreaksApplicationSecurity || article.Body.toString();
  //     // Truncate text if it exceeds the maximum character count
  //     if (text.length > maxChars) {
  //       article.TruncatedBody = text.substring(0, maxChars) + '... <a (click)="goToArticle(article.Url)">More..</a>';
  //     } else {
  //       article.TruncatedBody = text;
  //     }
  //   }
  // }

  selectCategoryFromArticle(categoryId: number): void {
  //  console.log('Selected category from article:', categoryId);
    this.selectedCategory = categoryId;
    this.showArticles = true; // Show articles when a category is selected from breadcrumb
    this.filterByCategory(categoryId); 
    this.cdr.markForCheck();
  }

  async loadTags(): Promise<void> {
    try {
        const tags = await this.selfServiceApi.getAllArticleTags();
        const publishedArticleIds = this.solutionData.Articles.map(article => article.Id);
        this.tags = tags.filter(tag => publishedArticleIds.includes(tag.ArticleId));

        console.log('Fetched Tags:', this.tags);

        this.solutionData.Articles.forEach((article: Article) => {
            article.Tags = this.tags.filter(tag => tag.ArticleId === article.Id).map(tag => tag.Tag);
        });

        this.filterTags();
        this.cdr.markForCheck();
    } catch (error) {
        console.error('Error fetching tags', error);
    }
}



  toggleDropdown(type: string): void {
    if (type === 'all') {
      this.isDropdownOpenAll = !this.isDropdownOpenAll;
      this.isDropdownOpenTags = false;
      this.isDropdownOpenCriteria = false;
    } else if(type === 'criteria'){
      this.isDropdownOpenCriteria = !this.isDropdownOpenCriteria;
      this.isDropdownOpenTags = false;
      this.isDropdownOpenAll = false;
    }

    else if (type === 'tags') {
      this.isDropdownOpenTags = !this.isDropdownOpenTags;
      this.isDropdownOpenAll = false;
      this.isDropdownOpenCriteria = false;
    }
  }

  toggleSortDropdown(): void {
    this.isSortDropdownOpen = !this.isSortDropdownOpen;
  }

  toggleCategoriesDisplay(): void {
    this.displayCategoriesOnly = !this.displayCategoriesOnly;
    if (this.displayCategoriesOnly) {
      this.filteredArticles = [];
    } else {
      this.search();
    }
    this.cdr.markForCheck();
  }

  selectCategory(category: Category): void {
    this.displayCategoriesOnly = false;
    this.filterByCategory(category.Id);
    this.cdr.markForCheck();
    this.isDropdownOpenAll = false; // Close the dropdown
  }

  getArticles(categoryId: number): Article[] {
    return this.solutionData.Articles.filter((article: Article) => article.CategoryId === categoryId);
  }

  filterByCategory(categoryId: number | null): void {
    if (!this.solutionData || !this.solutionData.Articles) {
      console.error('solutionData or Articles is undefined');
      return;
    }

    //console.log('Filtering articles by category ID:', categoryId); // Log filtering action
    //console.log('All articles:', this.solutionData.Articles); // Log all articles
    if (categoryId === null) {
      this.filteredArticles = this.solutionData.Articles;
      this.selectedCategoryName = 'All';
    } else {
      // Verify if categoryId is present in the articles
      this.solutionData.Articles.forEach(article => {
       // console.log(`Article ID: ${article.Id}, Category ID: ${article.CategoryId}`);
      });

      // Filter articles based on the category ID
      this.filteredArticles = this.solutionData.Articles.filter(article => {
       // console.log(`Checking article with ID: ${article.Id}, Category ID: ${article.CategoryId} against filter ID: ${categoryId}`);
        return Number(article.CategoryId) === Number(categoryId);
      });
     // console.log('Filtered articles:', this.filteredArticles); // Log filtered articles

      const selectedCategory = this.categories.find(category => category.Id === categoryId);
      this.selectedCategoryName = selectedCategory ? selectedCategory.Name : 'All';
    }
    this.selectedCategory = categoryId;
    this.selectedTag = 'Tags'; // Reset the selected tag to "Tags" when a category is selected
    this.isDropdownOpenAll = false; // Close the dropdown after selection
    this.filterTags(); // Filter tags based on current filtered articles
    this.cdr.markForCheck();
    this.applyFilterOrSort();
  }


  sortArticles(criteria: string): void {
    switch (criteria) {
      case 'newest':
        this.sortedCategories.sort((a, b) => this.getNewestArticleId(b) - this.getNewestArticleId(a));
        break;
      case 'oldest':
        this.sortedCategories.sort((a, b) => this.getNewestArticleId(a) - this.getNewestArticleId(b));
        break;
      case 'popular':
        this.sortedCategories.sort((a, b) => (b.SearchCount || 0) - (a.SearchCount || 0));
        break;
      case 'az':
        this.sortedCategories.sort((a, b) => a.Name.localeCompare(b.Name));
        break;
      case 'za':
        this.sortedCategories.sort((a, b) => b.Name.localeCompare(a.Name));
        break;
    }

    this.solutionData.Articles.sort((a, b) => {
      switch (criteria) {
        case 'newest':
          return b.Id - a.Id;
        case 'oldest':
          return a.Id - b.Id;
        case 'popular':
          return (b.SearchCount || 0) - (a.SearchCount || 0);
        case 'az':
          return a.Title.localeCompare(b.Title);
        case 'za':
          return b.Title.localeCompare(a.Title);
        default:
          return 0;
      }
    });

    this.isSortDropdownOpen = false; // Close dropdown 
    this.filterTags(); // Filter tags based on current filtered articles
    this.cdr.markForCheck();
    this.applyFilterOrSort();
  }

  filterByTag(tag: string): void {
    this.filteredArticles = this.solutionData.Articles.filter(article => article.Tags && article.Tags.includes(tag));
    this.isDropdownOpenTags = false; // Close the tags dropdown after selection
    this.selectedTag = tag; // Update the selected tag
    this.filterTags(); // Filter tags based on current filtered articles
    this.applyFilterOrSort();
    this.cdr.markForCheck();
  }

  selectSearchCriteria(criteria: string): void {
    this.selectedSearchCriteria = criteria;
    this.isDropdownOpenCriteria = false; // Close the dropdown after selection
    this.cdr.markForCheck();
  }

  search() {
    let searchValue = this.searchTerm.toLowerCase();
    if (searchValue !== "") {
      this.filteredArticles = this.solutionData.Articles.filter((article: Article) => {
        switch (this.selectedSearchCriteria) {
          case 'category':
            return article.CategoryName && article.CategoryName.toLowerCase().includes(searchValue);
          case 'tag':
            return article.Tags && article.Tags.some(tag => typeof tag === 'string' && tag.toLowerCase().includes(searchValue));
          case 'bodyContent':
            return article.Body && article.Body.toString().toLowerCase().includes(searchValue);
          case 'articleName':
            return article.Title.toLowerCase().includes(searchValue);
          default:
            return false;
        }
      });
    } else {
      this.filteredArticles = this.solutionData.Articles;
    }
    this.applyFilterOrSort();
  }

 

  filterTags(): void {
    const searchValue = this.tagSearch.toString().toLowerCase();
    console.log('Search Value:', searchValue);

    this.filteredTags = this.tags.filter(tag => {
        const tagString = tag.Tag.toString().toLowerCase();
        console.log('Comparing:', tagString, 'with', searchValue);
        return tagString.includes(searchValue);
    });

    console.log('Filtered Tags:', this.filteredTags);
    this.cdr.markForCheck(); // Trigger change detection to update the view
}


  filterCategories(): void {
    const searchValue = this.categorySearch.toLowerCase();
    this.filteredCategories = this.categories.filter(category => category.Name.toLowerCase().includes(searchValue));
    this.cdr.markForCheck();
  }

  showAllTags(): void {
    this.filteredTags = this.tags;
    this.selectedTag = 'Tags'; // Reset the selected tag
    this.tagSearch = ''; 
    this.isDropdownOpenTags = false;
    this.cdr.markForCheck(); 
  }

  toggleShowAllTags(article: Article): void {
    article.showAllTags = !article.showAllTags;
    this.cdr.markForCheck();
  }

  getNewestArticleId(category: Category): number {
    const articles = this.getArticles(category.Id);
    if (articles.length === 0) return -1;
    return Math.max(...articles.map(article => article.Id));
  }
  
  refreshData() {
    this.getData();
  }
}
