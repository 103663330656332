import {Component, Input, OnInit} from '@angular/core';
import * as Highcharts from 'highcharts';

@Component({
  selector: 'app-highchart-column',
  templateUrl: './highchart-column.component.html',
  styleUrls: ['./highchart-column.component.css']
})
export class HighchartColumnComponent implements OnInit {

  @Input() inputData: any[];
  @Input() inputColor: any;
  @Input() height = '300px';

  public showGraph = false;

  Highcharts: typeof Highcharts = Highcharts; // required
  chartConstructor: string = 'chart'; // optional string, defaults to 'chart'
  chartOptions;
  chartCallback: Highcharts.ChartCallbackFunction = function (chart) { null } // optional function, defaults to null
  updateFlag: boolean = true; // optional boolean
  oneToOneFlag: boolean = true; // optional boolean, defaults to false
  runOutsideAngular: boolean = false; // optional boolean, defaults to false

  ngOnInit(): void {

    let categories = this.inputData.map(d => d.name);
    let data = this.inputData.map(d => d.y);

    this.chartOptions = {
      credits: {
        enabled: false
      },
      chart: {
        backgroundColor: 'rgba(0,0,0,0)',
        type: 'column'
      },
      title: {
        text: null
      },
      subtitle: {
        text: null
      },
      xAxis: {
        categories: categories,
        crosshair: true
      },
      yAxis: {
        min: 0,
        allowDecimals: false,
        title: {
          text: null
        }
      },
      tooltip: {
        headerFormat: '<span style="font-size:10px">{point.key}</span><table>',
        pointFormat: '<tr><td style="color:{series.color};padding:0">{series.name}: </td>' +
          '<td style="padding:0"><b>{point.y}</b></td></tr>',
        footerFormat: '</table>',
        shared: true,
        useHTML: true
      },
      plotOptions: {
        column: {
          pointPadding: 0.0,
          borderWidth: 0,
          maxPointWidth: 150
        }
      },
      series: [{
        showInLegend: false,
        type: "column",
        name: 'Tickets',
        color: this.inputColor,
        data: data
      }]
    };
    this.showGraph = true;
  }

}
