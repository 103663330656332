import {Component, Input, OnInit, ViewContainerRef} from '@angular/core';
import {ApiAdminEmailParseService} from "../../../../../services/api/admin/admin-email-parse-api/admin-email-parse-api.service";
import {DialogService} from "../../../../../services/dialog-service/dialog.service";
import {ModalConfirmationComponent} from "../../../../../shared/modal-confirmation/modal-confirmation.component";
import {AdminEmailParseComponentDialog} from "./admin-email-parse-create-edit-dialog/admin-email-parse-create-edit-dialog.component";
import { InstanceParamsService } from 'src/app/services/instance-params/instance-params.service';

@Component({
  selector: 'app-admin-email-parse',
  templateUrl: './admin-email-parse.html'
})
export class AdminEmailParseComponent implements OnInit {

  @Input() filterRequestTypeId: number;

  data;
  isLoading = true;
  instanceParams;
  constructor(private apiAdminEmailParseService: ApiAdminEmailParseService, private dialogService: DialogService, private viewReference: ViewContainerRef, private instanceParamsService: InstanceParamsService) {

    this.instanceParamsService.refreshInstanceParams();
    this.instanceParamsService.instanceParamsSubscription.subscribe(data => {
      this.instanceParams = data
    });
  }

  ngOnInit(): void {
    this.getData();
  }

  filterData() {
    if (this.filterRequestTypeId) {
      return this.data.filter(row => row.DefaultTypeId == this.filterRequestTypeId)
    } else {
      this.data.sort((a, b) => (a.EmailPriority ?? 0) - (b.EmailPriority ?? 0));
      return this.data;
    }
  }

  getData() {
    this.isLoading = true;
    this.apiAdminEmailParseService.listAll().then(response => {
      this.data = response;
      this.isLoading = false;
    })
  }

  create() {
    // Replace null with Component
    this.dialogService.createDialog(AdminEmailParseComponentDialog, {
      createNewWithRequestTypeId: this.filterRequestTypeId,
      id: null,
      dataLength:this.data.length + 1
    }, this.viewReference)
      .then(dialogSaved => {
        this.getData();
      }, dialogCancelled => {

      })
  }

  edit(id) {
    // Replace null with Component
    this.dialogService.createDialog(AdminEmailParseComponentDialog, {
      createNewWithRequestTypeId: null,
      id: id,
      dataLength:this.data.length
    }, this.viewReference)
      .then(dialogSaved => {
        this.getData();
      }, dialogCancelled => {

      })
  }

  delete(id) {
    this.dialogService.createDialog(ModalConfirmationComponent, 'Are you sure?', this.viewReference)
      .then(confirmed => {
        this.apiAdminEmailParseService.delete(id).then(response => this.getData())
      }, cancelled => null)
  }


}
