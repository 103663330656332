<div class="SDeskSidePanel-GreyBackground"></div>

<app-blade-right   size="blade-large">
  <div class="p-10 p-b-0">
    <i (click)="close()" class="fas fa-times fa-2x float-end divLink"></i>
    <h3>Connect to ICX</h3>
  </div>
  <hr>
  <div class="container p-0">

    <div class="row">
      <div class="col-sm-3 formHeader alignMiddle p-10">
        Client Name <span class="text-danger">*</span>
      </div>
      <div class="col-sm p-10">
        <input [(ngModel)]="loadedData.ClientName"
       (keypress)="preventSpace($event)"
       type="text"
       class="form-control"
       id="ClientName"
       placeholder="Client Name (Without space)"
       autocomplete="off">
      </div>
    </div>

    <hr/>

    <div class="m-t-20">
      <div (click)="save()" class="btn btn-primary">Save</div>
      <div (click)="close()" class="btn btn-cancel">Cancel</div>
    </div>

  </div>
</app-blade-right>
