import {Injectable} from '@angular/core';
import {ApiService} from "../api.service";

@Injectable({
  providedIn: 'root'
})
export class InstanceParamsApiService {

  instanceParamsApi = "/api/instance-params";

  constructor(private api: ApiService) {
  }

  getParams() {
    return this.api.get(this.instanceParamsApi);
  }

}
