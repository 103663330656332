import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-loading-dots',
  templateUrl: './loading-dots.component.html',
  styleUrls: ['./loading-dots.component.css']
})
export class LoadingDotsComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
