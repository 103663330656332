import { Injectable } from '@angular/core';
import {ApiService} from "../api.service";

@Injectable({
  providedIn: 'root'
})
export class ServicePopoutService {

  public servicePopoutApi = '/api/service';

  constructor(private apiService: ApiService) { }

  getServiceDetails(serviceId){
    return this.apiService.get(`${this.servicePopoutApi}/${serviceId}`);
  }

}
