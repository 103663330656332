import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';

@Component({
  selector: 'app-ticket-popout-blade',
  templateUrl: './ticket-popout-blade.component.html',
  styleUrls: ['./ticket-popout-blade.component.css']
})
export class TicketPopoutBladeComponent implements OnInit {

  constructor() { }

  @Input() data; // create // filterId // requestTypeId (Required on create)
  @Output() remove = new EventEmitter<any>();

  ngOnInit(): void {
  }

  close(){
    this.remove.emit(null);
  }

}
