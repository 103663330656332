import {Component, Input, OnInit} from '@angular/core';
import {OnlineUsersService} from "../../services/online-users/online-users.service";

@Component({
  selector: 'app-user-online-indicator',
  templateUrl: './user-online-indicator.component.html',
  styleUrls: ['./user-online-indicator.component.css']
})
export class UserOnlineIndicatorComponent implements OnInit {

  @Input() userId;
  public onlineUserData;
  public isLoading = true;

  constructor(private onlineUserService: OnlineUsersService) {
  }

  ngOnInit(): void {
    this.onlineUserService.onlineUsersArray.subscribe(data => {
      this.onlineUserData = data.find(users => users.UserId == this.userId);
      this.isLoading = false;
    })
  }

}
