import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {RequestMajorIncidentService} from "../../../../../services/api/request/request-major-incident/request-major-incident.service";
import {Dialog} from "../../../../../shared/dialog/dialog";

@Component({
  selector: 'app-major-incident-send-mass-communication-blade',
  templateUrl: './major-incident-send-mass-communication-blade.component.html',
  styleUrls: ['./major-incident-send-mass-communication-blade.component.css']
})
export class MajorIncidentSendMassCommunicationBladeComponent implements Dialog {

  @Input() data; // {requestDetails: this.requestDetails, taskObject: taskObject}
  @Output() remove = new EventEmitter<any>();

  public loading;

  sendUpdateToSlack = false;
  updateText;
  markTaskComplete = 0;
  nextUpdateValue = '';
  sendSmsUpdate = false;
  smsUpdateText;
  nextUpdateType = 'hours' || 'minutes' || 'days'; // [Hours] / [Minutes] / [Days]

  showNextUpdateOptions = 0; // Triggered by checkbox and shows "NextUpdateValue" and "NextUpdateType" form fields

  nextUpdateOptions = [
    {value: 'minutes', name: 'Minute(s)'},
    {value: 'hours', name: 'Hour(s)'},
    {value: 'days', name: 'Day(s)'}];


  constructor(private majorIncidentApi: RequestMajorIncidentService) { }

  ngOnInit(): void {
    this.updateText = this.data.taskObject.EmailTemplateText;
    this.smsUpdateText = this.data.taskObject.SmsTemplateText;
    this.sendUpdateToSlack = this.data.requestDetails.MISlackIntegrationEnabled;
  }

  close(){
    this.remove.emit(null);
  }

  save(){
    this.majorIncidentApi.sendMassUpdate(
      this.data.requestDetails.Id,
      this.data.taskObject.Id,
      this.updateText,
      this.markTaskComplete,
      this.nextUpdateValue,
      this.nextUpdateType,
      this.sendUpdateToSlack,
      this.sendSmsUpdate,
      this.smsUpdateText).then(response => {
        this.remove.emit("Success");
    })
  }
}
