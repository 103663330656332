import { Component, OnInit, ViewContainerRef } from "@angular/core";
import { DialogService } from "src/app/services/dialog-service/dialog.service";
import { AdminIcxSettingsComponentDialog } from "./admin-icx-settings-create-edit-dialog/admin-icx-settings-create-edit-dialog.component";
import { ApiAdminIcxService } from "src/app/services/api/admin/admin-icx-api/admin-icx-api.service";
import { AdminJiraSettingsComponentDialog } from "./admin-jira-settings-create-edit-dialog/admin-jira-settings-create-edit-dialog.component";
import { ToastrService } from "ngx-toastr";

@Component({
  selector: 'app-admin-icx-settings',
  templateUrl: './admin-icx-settings.component.html'
})
export class AdminIcxSettingsComponent implements OnInit {

  constructor( private dialogService: DialogService, private viewReference: ViewContainerRef, private apiAdminIcxService: ApiAdminIcxService,     private toastr: ToastrService,
  ) {
  }
  isLoading = true;

  icxClient=null;
  jiraConnected = false;
  ngOnInit(): void {
    this.getIcxClientName();
  }

  getIcxClientName(){
    this.isLoading = true;
     this.apiAdminIcxService.get().then((response)=>{
      if(response){
        this.icxClient = response;
        this.getJiraConfig();
        this.isLoading = false;
      }else{
        console.log("Client not connected to ICX")
        this.isLoading = false;

      }

     });
  }


  create(){
    this.dialogService.createDialog(AdminIcxSettingsComponentDialog, this.icxClient, this.viewReference)
      .then(dialogSaved => {
        this.getIcxClientName()
        console.log("dialog saved")
      }, dialogCancelled => {

      })
  }

  getJiraConfig(){
    this.isLoading = true;
    this.apiAdminIcxService.getJiraConfig().then((response)=>{
      console.log(response.data[0])
      var jiraConfigList = response["data"][0];
      var JiraUrl=response["data"][0]["config"]["jira_url"]
      var JiraUsername=response["data"][0]["config"]["user_name"]
      if (JiraUrl && JiraUsername && jiraConfigList) {
        this.jiraConnected = true;
      }
      this.isLoading = false;
    })
  }

  createJiraConfig(){
    // this.isLoading = true;
    if(this.icxClient != null){
      this.dialogService.createDialog(AdminJiraSettingsComponentDialog, null, this.viewReference)
      .then(dialogSaved=>{
        console.log("dialog saved")
        this.getIcxClientName()
        // this.isLoading = false;
      }, dialogCancelled=>{
        // this.isLoading = false;

    })}else{
      this.toastr.info("Create Client first with connect to ICX");
    }

  }

}
