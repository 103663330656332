

import {Component, OnInit, ViewContainerRef} from '@angular/core';
import {ApiAdminSlaTemplatesService} from "../../../../../services/api/admin/admin-sla-templates-api/admin-sla-templates-api.service";
import {DialogService} from "../../../../../services/dialog-service/dialog.service";
import {AdminSlaTemplatesComponentDialog} from "./admin-sla-templates-create-edit-dialog/admin-sla-templates-create-edit-dialog.component";
import {ModalConfirmationComponent} from "../../../../../shared/modal-confirmation/modal-confirmation.component";

@Component({
  selector: 'app-admin-sla-templates',
  templateUrl: './admin-sla-templates.html'
})
export class AdminSlaTemplatesComponent implements OnInit {

  data;
  isLoading = true;
  searchTerm = '';
  constructor(private apiAdminSlaTemplatesService: ApiAdminSlaTemplatesService, private dialogService: DialogService, private viewReference: ViewContainerRef) {
    this.getData();
  }

  ngOnInit(): void {
  }
  filteredData(){
    const searchTerm = this.searchTerm.toLocaleLowerCase();

    if(!this.searchTerm){
      return this.data;
    } else {
      return this.data.filter(row => {
        return row.Name.toLocaleLowerCase().includes(searchTerm)
      });
    }
  }
  getData(){
    this.isLoading = true;
    this.apiAdminSlaTemplatesService.listAll().then(response => {
      this.data = response;
      this.isLoading = false;
    })
  }

  create(){
    // Replace null with Component
    this.dialogService.createDialog(AdminSlaTemplatesComponentDialog, null, this.viewReference)
      .then(dialogSaved => {
        this.getData();
      }, dialogCancelled => {

      })
  }

  edit(id){
    // Replace null with Component
    this.dialogService.createDialog(AdminSlaTemplatesComponentDialog, id, this.viewReference)
      .then(dialogSaved => {
        this.getData();
      }, dialogCancelled => {

      })
  }

  delete(id){
    this.dialogService.createDialog(ModalConfirmationComponent, 'Are you sure?', this.viewReference)
      .then(confirmed => {
        this.apiAdminSlaTemplatesService.delete(id).then(response => this.getData())
      }, cancelled => null)
  }



}
