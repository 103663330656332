import {AfterViewInit, Component, ElementRef, EventEmitter, Input, Output, QueryList, ViewChild, ViewChildren} from '@angular/core';
import { CommonModule } from '@angular/common';
import {ActivatedRoute} from '@angular/router';
import {ChatApiService} from '../../../../services/api/agent/chat/chat-api';
import {QuillEditorComponent} from 'ngx-quill';
import {FormsModule} from '@angular/forms';
import {interval, Subscription} from 'rxjs';


@Component({
  selector: 'app-chat-window',
  templateUrl: './chat-window.component.html',
  styleUrl: './chat-window.component.css'
})
export class ChatWindowComponent {

  // input conversationId
  @Input() conversationId: string;
  @Output() refresh = new EventEmitter<any>();


  private updatePoller: Subscription;

  public data: any;
  public reply: any;

  private maxScroll = 0;
  private scrollTask;

  public onlineUsers = [];

  public isLoading = true;
  public isSendingRequest = false;

  public resetUnreadOnNextPoll = false;

  constructor(
    private route: ActivatedRoute,
    private chatApi: ChatApiService
  ) {
  }

  ngOnInit(): void {
    this.route.paramMap.subscribe(params => {

      this.ngOnDestroy();

      if(params.get("conversationId")){
        this.conversationId = params.get("conversationId");
      }

      this.isLoading = true;

      if(this.conversationId){
        this.refreshChat();
        this.startPollingForUpdates();
        this.pollRealTime();
      }
    })
  }

  playAudio() {
    let audio = new Audio();
    audio.src = "/assets/notification.wav";
    audio.load();
    audio.play();
  }

  scrollToBottom() {
    this.maxScroll = 0;
    let chatContainer = document.getElementById('chatContainer');
    console.log(chatContainer);

    // Set an interval to check every 500 ms
    this.scrollTask = setInterval(() => {
      this.maxScroll = this.maxScroll++;

      if(this.maxScroll > 50){
        console.log("Max scroll reached");
        clearInterval(this.scrollTask);
        return;
      }

      chatContainer = document.getElementById('chatContainer');
      console.log(chatContainer);

      if (chatContainer) {
        chatContainer.scrollTop = chatContainer.scrollHeight;
        clearInterval(this.scrollTask);// Clear the interval once the element is found
      }
    }, 10);
  }

  // scrollToBottom2() {
  //   const observeChatContainer = () => {
  //     const chatContainer = document.getElementById('chatContainer');
  //     if (chatContainer) {
  //       chatContainer.scrollTop = chatContainer.scrollHeight;
  //       observer.disconnect(); // Disconnect the observer once the element is found
  //     }
  //   };
  //
  //   const observer = new MutationObserver(observeChatContainer);
  //   observer.observe(document.body, { childList: true, subtree: true });
  // }

  closeConversation(){
    if(this.isSendingRequest){
      return;
    }

    this.isSendingRequest = true;
    this.chatApi.closeConversation(this.conversationId).then(response => {
      this.refreshChat();
      this.isSendingRequest = false;
    })
  }

  assignToMe(){
    this.chatApi.assignToMe(this.conversationId).then(response => {
      this.refreshChat();
    })
  }

  unassign(){
    this.chatApi.unassign(this.conversationId).then(response => {
      this.refreshChat();
    })
  }

  reopen(){
    if(this.isSendingRequest){
      return;
    }

    this.isSendingRequest = true;
    this.chatApi.reopenConversation(this.conversationId).then(response => {
      this.refreshChat();
      this.isSendingRequest = false;
    })
  }

  startPollingForUpdates() {
    this.updatePoller = interval(5000).subscribe(() => {

      if(this.isLoading)
        return;

      if(this.data.ClosedDateTime)
        return;

      if(!this.data)
        return;

      this.pollRealTime()
    });
  }

  pollRealTime(){
    this.chatApi.pollConversationUpdates(this.conversationId).then(updates => {

      this.onlineUsers = updates.OnlineUsers;

      console.log(updates);

      // if updates.LastMessageDateTime > data.LastMessageDateTime then refreshchat
      if (updates.LastMessageDateTime > this.data.LastMessageDateTime) {
        this.refreshChat(true);
      }



    }).catch(error => {
      console.error('Error polling for updates:', error);
    });

    if(this.resetUnreadOnNextPoll){
      this.resetUnreadOnNextPoll = false;
      this.markRead();
      this.data.UnreadMessageCount = 0;
    }
  }

  mouseMove(){
    if(this.data.UnreadMessageCount > 0 && !this.resetUnreadOnNextPoll && this.data.AssignedToAgentId){
      this.resetUnreadOnNextPoll = true;
    }
  }

  markRead(){
    this.chatApi.markRead(this.conversationId).then(response => {
      this.refreshChat();
    })
  }


  ngOnDestroy() {
    if (this.updatePoller) {
      this.updatePoller.unsubscribe();
    }
  }

  refreshChat(playSound = false){
    this.chatApi.getConversation(this.conversationId).then(response => {
      this.data = response;
      this.isLoading = false;

      if(this.data.UnreadMessageCount > 0 && playSound){
        this.playAudio();
      }

    }).then(() => {
      this.scrollToBottom();
    })
    this.refresh.emit(true);
  }

  send(){
    if(this.isLoading || this.isSendingRequest){
      return;
    }
    this.isSendingRequest = true;
    this.chatApi.sendReply(this.conversationId, this.reply).then(response => {
      this.refreshChat();
      this.reply = "";
      this.isSendingRequest = false;
    })
  }

  protected readonly close = close;
}
