import {Component, EventEmitter, Input, OnInit, Output, ViewContainerRef} from '@angular/core';
import {ApiAdminSlaTemplatesService} from "../../../../../../services/api/admin/admin-sla-templates-api/admin-sla-templates-api.service";
import {Dialog} from "../../../../../../shared/dialog/dialog";
import {OptionsApiService} from "../../../../../../services/api/options/options-api.service";
 
@Component({
  selector: 'app-admin-sla-templates-create-edit-dialog',
  templateUrl: './admin-sla-templates-create-edit-dialog.component.html'
})
 
export class AdminSlaTemplatesComponentDialog implements Dialog {
 
  @Input() data;
  @Output() remove = new EventEmitter<any>();
 
  isLoading = true;
  isLoadingRequestTypes = true;
  isLoadingOptions = false;
  loadedId = null;
 
  loadedData;
  requestTypes;
 
  workingHoursOptions = [
    { id: 1,  name: 'Global Working Hours'},
    { id: 2,  name: 'Customer Working Hours'},
    { id: 3,  name: '24/7'}
  ];
  responseCriteriaOptions = [
    { id: 1,  name: 'Default Behaviour (Agent Sends Email Response)'},
    { id: 2,  name: 'Agent Assignment (When ticket is assigned to an agent)'},
    { id: 3,  name: 'Flexible (When ticket is assigned to an agent OR when agent sends email response)'}
  ];
  timezoneOptions = [
    { id: 1,  name: 'Global Timezone'},
    { id: 2,  name: 'Customer Timezone'}
  ];
 
  constructor(
    private apiAdminSlaTemplatesService: ApiAdminSlaTemplatesService,
    private apiAdminOptionsService: OptionsApiService
  ) {
    this.getRequestTypes();
  }
 
 
  ngOnInit(): void {
    if(this.data !== null){
      this.loadedId = this.data;
      this.getData();
    } else {
 
      // Load Default Data
      this.loadedData = {
        Name: '',
        TypeId: null,
        Priorities: []
      }
 
      this.isLoading = false;
 
    }
  }
 
  refreshOptions(){
    this.isLoadingOptions = true;
    this.apiAdminSlaTemplatesService.options(this.loadedData.TypeId).then(response => {
      this.loadedData.Priorities = response;
      this.convertToHoursAndMinutes();
      this.isLoadingOptions = false;
    })
  }
 
  getRequestTypes(){
    this.isLoadingRequestTypes = true;
    this.apiAdminOptionsService.AllRequestTypes().then(response => {
      this.requestTypes = response;
      this.isLoadingRequestTypes = false;
    })
  }
 
  getData(){
    this.isLoading = true;
    this.apiAdminSlaTemplatesService.get(this.loadedId).then(response => {
      this.loadedData = response;
      this.convertToHoursAndMinutes();
      this.isLoading = false;
    })
  }
 
  close(){
    this.remove.emit(null);
  }
 
  convertToHoursAndMinutes() {
    this.loadedData.Priorities.forEach(x => {
      x.ResponseHours = Math.floor(x.ResponseMinutes / 60);
      x.ResponseMinutes = x.ResponseMinutes % 60;
      x.ResolutionHours = Math.floor(x.ResolutionMinutes / 60);
      x.ResolutionMinutes = x.ResolutionMinutes % 60;
    });
  }
 
  validateInput(event: any, max: number) {
    let value = parseInt(event.target.value);
    if(value < 0) {
        event.target.value = 0;
    }    
  }
 
  save(){
    let dataCopy = JSON.parse(JSON.stringify(this.loadedData));
    dataCopy.Priorities.forEach(x => {
      x.ResponseHours = Math.max(x.ResponseHours, 0);
      x.ResolutionHours = Math.max(x.ResolutionHours, 0);
      x.ResponseMinutes = Math.max(x.ResponseMinutes, 0);
      x.ResolutionMinutes = Math.max(x.ResolutionMinutes, 0);
      x.ResponseMinutes = x.ResponseHours * 60 + x.ResponseMinutes;
      x.ResolutionMinutes = x.ResolutionHours * 60 + x.ResolutionMinutes;
      delete x.ResponseHours;
      delete x.ResolutionHours;
    });
    if(this.loadedId){
      this.apiAdminSlaTemplatesService.update(this.loadedId, dataCopy).then(response => {
        this.remove.emit("Saved");
      })
    } else {
      this.apiAdminSlaTemplatesService.create(dataCopy).then(response => {
        this.remove.emit("Saved");        
      })
    }
  } 
}