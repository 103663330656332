import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {Dialog} from "../../../../shared/dialog/dialog";

@Component({
  selector: 'app-request-followers-modal',
  templateUrl: './request-followers-modal.component.html',
  styleUrls: ['./request-followers-modal.component.css']
})
export class RequestFollowersModalComponent implements Dialog {
  @Input() data;
  @Output() remove = new EventEmitter<any>();

  constructor() { }

  ngOnInit(): void {
  }

  closeModal(){
    this.remove.emit(null);
  }

}
