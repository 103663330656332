

import {Component, EventEmitter, Input, OnInit, Output, ViewContainerRef} from '@angular/core';
import {Dialog} from "../../../../../../shared/dialog/dialog";
import {ApiAdminResolutionCodeService} from "../../../../../../services/api/admin/admin-resolution-codes-api/admin-resolution-codes-api.service";

@Component({
  selector: 'app-admin-resolution-codes-create-edit-dialog',
  templateUrl: './admin-resolution-codes-create-edit-dialog.component.html'
})

export class AdminResolutionCodesComponentDialog implements Dialog {

  @Input() data; // {id: 122, requestTypeId: 12}
  @Output() remove = new EventEmitter<any>();

  isLoading = true;

  loadedId = null;
  requestTypeId = null;

  loadedData;

  constructor(
    private apiAdminResolutionCodeService: ApiAdminResolutionCodeService
  ) {}


  ngOnInit(): void {
    this.requestTypeId = this.data.requestTypeId;

    if(this.data.id !== null){
      this.loadedId = this.data.id;
      this.getData();
    } else {

      // Load Default Data
      this.loadedData = {
        Name: ''
      }

      this.isLoading = false;

    }
  }

  getData(){
    this.isLoading = true;
    this.apiAdminResolutionCodeService.get(this.requestTypeId, this.loadedId).then(response => {
      this.loadedData = response;
      this.isLoading = false;
    })
  }

  close(){
    this.remove.emit(null);
  }

  save(){
    if(this.loadedId){
      this.apiAdminResolutionCodeService.update(this.requestTypeId, this.loadedId, this.loadedData).then(response => {
        this.remove.emit("Saved");
      })
    } else {
      this.apiAdminResolutionCodeService.create(this.requestTypeId, this.loadedData).then(response => {
        this.remove.emit("Saved");
      })
    }

  }
}
