<app-loading-spinner *ngIf="isLoading" ></app-loading-spinner>

<div *ngIf="!isLoading" class="table-responsive p-l-20 p-r-20">

  <table class="table align-middle tableStyle">
    <thead>
    <tr>
      <th class="">Custom Fields
        <div (click)="create()" class="btn btn-primary float-end">Create</div>
      </th>
    </tr>
    </thead>
    <tbody>
    <tr *ngFor="let row of this.data; let firstIndex = first; let lastIndex = last; let i = index">
      <td>
        <span class="divLink" (click)="edit(row.Id)">{{row.Name}}
          <span *ngIf="!row.RequestTypeId" class="ng-scope" style="font-size: 10px; font-style: italic;"> (Shared across all Request Types) </span>
        </span>
        <i class="far fa-trash-alt float-end m-r-20 divLink" (click)="delete(row.Id)"></i>
        <i class="fas fa-cog float-end m-r-20 divLink" (click)="edit(row.Id)"></i>
      </td>
      <td style="width: 10px;">
        <a (click)="moveUp(row.Id)"><i *ngIf="row.RequestTypeId && i > 0 && this.data[i-1].RequestTypeId" class="fa fa-arrow-up m-r-5"></i></a>
      </td>
      <td style="width: 10px;">
        <a (click)="moveDown(row.Id)"><i *ngIf="row.RequestTypeId && i < this.data.length - 1 && this.data[i+1].RequestTypeId" class="fa fa-arrow-down"></i></a>
      </td>
    </tr>
    </tbody>
  </table>
</div>
