

import {Component, EventEmitter, Input, OnInit, Output, ViewContainerRef} from '@angular/core';
import {ApiAdminStatusService} from "../../../../../../services/api/admin/admin-status-api/admin-status-api.service";
import {Dialog} from "../../../../../../shared/dialog/dialog";
import { ApiAdminRequestTypeService } from 'src/app/services/api/admin/admin-request-type-api/admin-request-type-api.service';

@Component({
  selector: 'app-admin-status-create-edit-dialog',
  templateUrl: './admin-status-create-edit-dialog.component.html'
})

export class AdminStatusComponentDialog implements Dialog {

  @Input() data; // {id: 122, requestTypeId: 12}
  @Output() remove = new EventEmitter<any>();

  isLoading = true;

  loadedId = null;
  requestTypeId = null;

  loadedData;
  requestDetails;

  constructor(
    private apiAdminStatusService: ApiAdminStatusService,
    private apiAdminRequestTypeService: ApiAdminRequestTypeService,
  ) {}


  ngOnInit(): void {
    this.requestTypeId = this.data.requestTypeId;
    this.getRequestType()
    if(this.data.id !== null){
      this.loadedId = this.data.id;
      this.getData();
    } else {

      // Load Default Data
      this.loadedData = {
        Name: ''
      }

      this.isLoading = false;

    }
  }
  isCmdbEnabled() {
    if (this.requestDetails && this.requestDetails.hasOwnProperty('CmdbEnabled') && this.requestDetails.CmdbEnabled === 1) {
        return true;
    }
    return false;
  }

  getRequestType(){
    this.isLoading = true;
    this.apiAdminRequestTypeService.get(this.requestTypeId).then(response => {
      this.requestDetails = response;
      this.isLoading = false;
    })
  }

  getData(){
    this.isLoading = true;
    this.apiAdminStatusService.get(this.requestTypeId, this.loadedId).then(response => {
      this.loadedData = response;
      this.isLoading = false;
    })
  }

  close(){
    this.remove.emit(null);
  }

  save(){
    if(this.loadedId){
      this.apiAdminStatusService.update(this.requestTypeId, this.loadedId, this.loadedData).then(response => {
        this.remove.emit("Saved");
      })
    } else {
      this.apiAdminStatusService.create(this.requestTypeId, this.loadedData).then(response => {
        this.remove.emit("Saved");
      })
    }

  }
}
