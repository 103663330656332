import { Component, EventEmitter, Input, Output } from "@angular/core";
import { ReportingApiService } from "src/app/services/api/reporting/reporting-api.service";
import { Dialog } from "src/app/shared/dialog/dialog";

@Component({
  selector: 'app-reporting-filter-create-edit-dialog',
  templateUrl: './reporting-filter-create-edit-dialog.component.html'
})

export class ReportingFilterComponentDialog implements Dialog {
  @Input() data;
  @Output() remove = new EventEmitter<any>();

  constructor(private reportingApi: ReportingApiService,){}

  ngOnInit(): void {

  }

  save(){
    this.reportingApi.saveReportingFilter(this.data).then((res)=>{
      this.remove.emit(res);
    })
  }

  close(){
    this.remove.emit(null);
  }
}
