import { Component, OnInit } from '@angular/core';
import {AuthenticationService} from "../../services/authentication/authentication.service";
import {SelfServiceApiService} from "../../services/api/self-service/self-service-api.service";
import {ActivatedRoute} from "@angular/router";
import {RequestApiService} from "../../services/api/request/request-api.service";
@Component({
  selector: 'app-self-service-ticket-view',
  templateUrl: './self-service-ticket-view.component.html',
  styleUrls: ['./self-service-ticket-view.component.css']
})
export class SelfServiceTicketViewComponent implements OnInit {

  constructor(private auth: AuthenticationService, private selfServiceApi: SelfServiceApiService, private route: ActivatedRoute,private requestApi: RequestApiService) {
    // Check logged in because this is an authenticated page only
    this.auth.checkLoggedIn();
  }

  ticketId;
  ticketData = [];
  isLoading = true;
  sendingUpdateInProgress = false;
  replyText = '';
  attachmentArray = [];
  uploadingAttachmentInProgress = false;

  quill: any;
  isImageUploaded: boolean = true;

  modules = {
    toolbar: [
      ['bold', 'italic', 'underline'],
      [{ 'color': [] }],
      ['clean'],
      ['link', 'image']
    ]
  };

  ngOnInit(): void {
    this.route.paramMap.subscribe(params => {
      this.ticketId = params.get("RequestId");
      this.getData();
    })
  }

  submitUpdate(){
    this.sendingUpdateInProgress = true;
    this.selfServiceApi.respondToRequest(this.ticketId, this.replyText,this.attachmentArray).then(response => {
      this.getData(true);
      this.replyText = '';
      this.attachmentArray =[]
      this.sendingUpdateInProgress = false;
    })

  }

  getData(silent = false){

    if(!silent){
      this.isLoading = true;
    }

    this.selfServiceApi.getRequest(this.ticketId).then(response => {
      this.ticketData = response;
      this.isLoading = false;
    })
  }

  onFileSelected(event) {
    const file: File = event.target.files[0];
    if (file) {

      this.uploadingAttachmentInProgress = true;

      let fileName = file.name;
      let fileSize = file.size;

      const formData = new FormData();
      formData.append('0', file);

      this.requestApi.uploadTempAttachment(formData).then(success => {
        this.attachmentArray.push({
          AttachmentId: success.Id,
          AttachmentName: success.AttachmentName
        });

        this.uploadingAttachmentInProgress = false;

      }, error => {
        console.error(error);
      })
    }
  }

  removeTempAttachment(index) {
    this.attachmentArray.splice(index, 1);
  }

  onEditorChange(files: FileList) {
    if (files.length != 0) {
      const file = files[0];
      if (!this.isImageURL(file.name)) {
        this.isImageUploaded = false;
      } else {
        console.log('Uploaded image:', file.name);
        this.isImageUploaded = true;
      }
    }
  }

  isImageURL(url: string): boolean {
    const imageExtensions = /\.(jpeg|jpg|gif|png|bmp)$/i;
    return imageExtensions.test(url);
  }
}
