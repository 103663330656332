<app-blade-right [size]="'blade-small'">
  <div class="p-10">
    <i (click)="this.close()" class="fas fa-times fa-2x float-end p-10 divLink"></i>
    <h3>Dashboard Settings</h3>
    <hr>

    <app-loading-spinner *ngIf="loading"></app-loading-spinner>
    <div *ngIf="!this.loading">
      <h4>Tiles</h4>

      <div class="form-check">
        <input [(ngModel)]="this.dashboardSettings.ShowTile_Active" [ngModelOptions]="{standalone: true}"
               name="ShowTile_Active" class="form-check-input" type="checkbox"
               id="ShowTile_Active">
        <label class="form-check-label" for="ShowTile_Active">Active</label>
      </div>

      <div class="form-check">
        <input [(ngModel)]="this.dashboardSettings.ShowTile_Unassigned" [ngModelOptions]="{standalone: true}"
               name="ShowTile_Unassigned" class="form-check-input" type="checkbox"
               id="ShowTile_Unassigned">
        <label class="form-check-label" for="ShowTile_Unassigned">Unassigned</label>
      </div>

      <div class="form-check">
        <input [(ngModel)]="this.dashboardSettings.ShowTile_Overdue" [ngModelOptions]="{standalone: true}"
               name="ShowTile_Overdue" class="form-check-input" type="checkbox"
               id="ShowTile_Overdue">
        <label class="form-check-label" for="ShowTile_Overdue">Overdue</label>
      </div>

      <div class="form-check">
        <input [(ngModel)]="this.dashboardSettings.ShowTile_DueToday" [ngModelOptions]="{standalone: true}"
               name="ShowTile_DueToday" class="form-check-input" type="checkbox"
               id="ShowTile_DueToday">
        <label class="form-check-label" for="ShowTile_DueToday">Due Today</label>
      </div>

      <div class="form-check">
        <input [(ngModel)]="this.dashboardSettings.ShowTile_OpenedToday" [ngModelOptions]="{standalone: true}"
               name="ShowTile_OpenedToday" class="form-check-input" type="checkbox"
               id="ShowTile_OpenedToday">
        <label class="form-check-label" for="ShowTile_OpenedToday">Opened Today</label>
      </div>

      <div class="form-check">
        <input [(ngModel)]="this.dashboardSettings.ShowTile_ResolvedToday" [ngModelOptions]="{standalone: true}"
               name="ShowTile_ResolvedToday" class="form-check-input" type="checkbox"
               id="ShowTile_ResolvedToday">
        <label class="form-check-label" for="ShowTile_ResolvedToday">Resolved Today</label>
      </div>

      <hr>
      <h4>Graphs</h4>

      <div class="form-check">
        <input [(ngModel)]="this.dashboardSettings.ShowGraph_Group" [ngModelOptions]="{standalone: true}"
               name="ShowGraph_Group" class="form-check-input" type="checkbox"
               id="ShowGraph_Group">
        <label class="form-check-label" for="ShowGraph_Group">Tickets by Group</label>
      </div>

      <div class="form-check">
        <input [(ngModel)]="this.dashboardSettings.ShowGraph_Priority" [ngModelOptions]="{standalone: true}"
               name="ShowGraph_Priority" class="form-check-input" type="checkbox"
               id="ShowGraph_Priority">
        <label class="form-check-label" for="ShowGraph_Priority">Tickets by Priority</label>
      </div>

      <div class="form-check">
        <input [(ngModel)]="this.dashboardSettings.ShowGraph_Type" [ngModelOptions]="{standalone: true}"
               name="ShowGraph_Type" class="form-check-input" type="checkbox"
               id="ShowGraph_Type">
        <label class="form-check-label" for="ShowGraph_Type">Tickets by Request Type</label>
      </div>

      <hr>
      <h4>Notifications</h4>
      <div class="form-check">
        <input [(ngModel)]="this.dashboardSettings.NewTicket_Sound" [ngModelOptions]="{standalone: true}"
               name="NewTicket_Sound" class="form-check-input" type="checkbox"
               id="NewTicket_Sound">
        <label class="form-check-label" for="NewTicket_Sound">Play sound when new ticket comes in</label>
      </div>

      <div class="m-t-15">
        <button (click)="save()" type="submit" class="btn btn-primary">
          Save
        </button>
        <button (click)="close()" type="button" class="btn btn-cancel">
          Cancel
        </button>
      </div>

    </div>

  </div>
</app-blade-right>
