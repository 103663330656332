import { Injectable } from '@angular/core';
import {ApiService} from "../../api.service";

@Injectable({
  providedIn: 'root'
})
export class RequestMajorIncidentService {

  constructor(private api: ApiService) {}

  // toDo: move this to a customers generic API service
  listCustomers(){
    return this.api.get('/api/customer');
  }

  // toDo: move this to a services generic API service
  listServices(){
    return this.api.get('/api/service');
  }

  linkServices(requestId, arrayOfServiceIds){
    let body = {
      ServiceIds: arrayOfServiceIds
    }
    return this.api.post(`/api/itil/request/${requestId}/link/service`, body);
  }

  removeServiceLink(requestId, serviceId){
    return this.api.delete(`/api/itil/request/${requestId}/link/service/${serviceId}`);
  }

  linkCustomers(requestId, arrayOfCustomerIds){
    let body = {
      CustomerIds: arrayOfCustomerIds
    };
    return this.api.post(`/api/itil/request/${requestId}/link/customer`, body);
  }

  removeCustomerLink(requestId, customerId){
    return this.api.delete(`/api/itil/request/${requestId}/link/customer/${customerId}`);
  }

  sendMassUpdate(requestId, taskId, updateText, markAsComplete, nextUpdateValue, nextUpdateType, sendToSlack, sendSmsUpdate, smsUpdateText){
    let body = {
      UpdateText: updateText,
      MarkAsComplete: markAsComplete,
      NextUpdateValue: nextUpdateValue,
      NextUpdateType: nextUpdateType,
      SendToSlack: sendToSlack,
      SendSmsUpdate: sendSmsUpdate,
      SmsUpdateText: smsUpdateText
    };

    return this.api.post(`/api/itil/request/${requestId}/mitask/${taskId}`, body);
  }

  markTaskComplete(requestId, taskId){
    return this.sendMassUpdate(requestId, taskId, null, 1, null, null, 1, null, null);
  }

  previewMassUpdate(requestId, updateText, nextUpdateValue, nextUpdateType){
    let body = {
      UpdateText: updateText,
      NextUpdateValue: nextUpdateValue,
      NextUpdateType: nextUpdateType
    };

    return this.api.post(`/api/itil/request/${requestId}/miupdatepreview`, body);
  }

}
