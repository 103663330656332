import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {Dialog} from "../../../../../shared/dialog/dialog";
import {RequestMajorIncidentService} from "../../../../../services/api/request/request-major-incident/request-major-incident.service";
import {SelectionModel} from "@angular/cdk/collections";

@Component({
  selector: 'app-major-incident-link-customers-modal',
  templateUrl: './major-incident-link-customers-modal.component.html',
  styleUrls: ['./major-incident-link-customers-modal.component.css']
})
export class MajorIncidentLinkCustomersModalComponent implements Dialog {

  @Input() data;
  @Output() remove = new EventEmitter<any>();

  public availableCustomers = [];
  public loading = true;

  public search = '';

  public selected = new SelectionModel(
    true,   // multiple selection or not
    [] // initial selected values
  );

  constructor(private majorIncidentApi: RequestMajorIncidentService) { }

  ngOnInit(): void {
    this.majorIncidentApi.listCustomers().then(response => {
      this.availableCustomers = response
      this.loading = false;
    });
  }

  returnFilteredCustomers(){
    return this.availableCustomers.filter(customer => {
      return (String(customer.Name).toLowerCase()).includes(this.search.toLowerCase());
    } )
  }

  checkboxIsAllSelected(){
    return this.selected.selected.length == (this.availableCustomers).length;
  }

  checkboxToggleAll(){
    if(this.checkboxIsAllSelected()){
      this.returnFilteredCustomers().forEach(request => {
        this.selected.deselect(request.Id);
      })
    } else {
      this.returnFilteredCustomers().forEach(request => {
        this.selected.select(request.Id);
      })
    }
  }

  closeModal(){
    this.remove.emit(null);
  }

  save(){
    this.majorIncidentApi.linkCustomers(this.data.requestId, this.selected.selected).then(response => {
      this.remove.emit("Success");
    })
  }
}
