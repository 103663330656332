import {Component, OnInit, ViewContainerRef} from '@angular/core';
import {MassCommunicationComponentDialog} from "./mass-communication-create-edit-dialog/mass-communication-create-edit-dialog.component";
import {ApiMassCommunicationService} from "../../../services/api/mass-communication/mass-communication-api/mass-communication-api.service";
import {ModalConfirmationComponent} from "../../../shared/modal-confirmation/modal-confirmation.component";
import {DialogService} from "../../../services/dialog-service/dialog.service";
import {MassCommunicationViewDialogComponent} from "./mass-communication-view-dialog/mass-communication-view-dialog.component";
import {InstanceParamsService} from "../../../services/instance-params/instance-params.service";


@Component({
  selector: 'app-mass-communication',
  templateUrl: './mass-communication.html'
})
export class MassCommunicationComponent implements OnInit {

  data;
  isLoading = true;
  instanceParams: any;


  constructor(private apiMassCommunicationService: ApiMassCommunicationService, private dialogService: DialogService, private viewReference: ViewContainerRef,
    private instanceParamsService: InstanceParamsService,) {
    this.getData();
  }

  ngOnInit(): void {
    this.instanceParamsService.refreshInstanceParams();
    this.instanceParamsService.instanceParamsSubscription.subscribe(data => {
      this.instanceParams = data;
    });
  }

  getData() {
    this.isLoading = true;
    this.apiMassCommunicationService.listAll().then(response => {
      this.data = response;
      this.isLoading = false;
    })
  }

  create() {
    // Replace null with Component
    this.dialogService.createDialog(MassCommunicationComponentDialog, null, this.viewReference)
      .then(dialogSaved => {
        this.getData();
      }, dialogCancelled => {

      })
  }

  openTopic(id) {
    // Replace null with Component
    this.dialogService.createDialog(MassCommunicationViewDialogComponent, id, this.viewReference)
      .then(dialogSaved => {
        this.getData();
      }, dialogCancelled => {

      })
  }

  // delete(id) {
  //   this.dialogService.createDialog(ModalConfirmationComponent, 'Are you sure?', this.viewReference)
  //     .then(confirmed => {
  //       this.apiMassCommunicationService.delete(id).then(response => this.getData())
  //     }, cancelled => null)
  // }


}
