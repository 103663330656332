
import {Injectable} from '@angular/core';
import {ApiService} from "../../api.service";

@Injectable({
  providedIn: 'root'
})
export class ApiAdminAutoPriorityService {

  constructor(private apiService: ApiService) {
  }

  get(typeId) {
    return this.apiService.get(`/api/admin/type/${typeId}/automaticpriority`);
  }
  update(typeId, data) {
    return this.apiService.put(`/api/admin/type/${typeId}/automaticpriority`, data);
  }

}



