import {Component, EventEmitter, Input, OnInit, Output, ViewContainerRef} from '@angular/core';
import {RequestChangeService} from "../../../../services/api/request/request-change/request-change.service";
import { UserPermissionsService } from "../../../../services/user-permissions/user-permissions.service";
import {DialogService} from "../../../../services/dialog-service/dialog.service";
import {AppRequestChangeApprovalAddMembersDialogComponent} from "./app-request-change-approval-add-members-dialog/app-request-change-approval-add-members-dialog.component";
import {SelectionModel} from "@angular/cdk/collections";

@Component({
  selector: 'app-request-change-management',
  templateUrl: './request-change-management.component.html',
  styleUrls: ['./request-change-management.component.css']
})
export class RequestChangeManagementComponent implements OnInit {

  @Input() requestDetails;
  @Output() refreshData = new EventEmitter<any>();

  public selectedMembers = new SelectionModel(
    true,   // multiple selection or not
    [] // initial selected values
  );

  changeTabSelected = 1;
  DowntimeRequired;
  userPermissionsSubscription;
  isSavingPlan:boolean=false;
  isSavingSchedule:boolean=false;
  isSavingImplementation:boolean=false;
  isSavingReview:boolean=false;
  isSavingclose:boolean=false;

  constructor(private changeApi: RequestChangeService, 
              private dialogService: DialogService, 
              private viewReference: ViewContainerRef,
              private userPermissions: UserPermissionsService
            ) {
                userPermissions.refreshUserPermissions();
                userPermissions.userPermissionsSubscription.subscribe(data => this.userPermissionsSubscription = data);
              }  

  ngOnInit(): void {
    this.changeTabSelected = this.requestDetails.ChangeManagement.ChangeState;
    this.DowntimeRequired = !!this.requestDetails.ChangeManagement.Planning_DowntimeStart || !!this.requestDetails.ChangeManagement.Planning_DowntimeEnd ;
  }

  changeTab(tabId){
    let currentState = this.requestDetails.ChangeManagement.ChangeState;
    if(currentState < tabId){
      return;
    }

    this.changeTabSelected = tabId;
  }

  editPlan(){
    this.changeApi.editPlanning(this.requestDetails.Id).then(response => this.refreshParent());
  }

  approvalIsAllSelected(){
    return this.selectedMembers.selected.length == (this.requestDetails.ChangeManagementCabMembers).length;
  }

  approvalToggleAll(){
    if(this.approvalIsAllSelected()){
      this.requestDetails.ChangeManagementCabMembers.forEach(member => {
        this.selectedMembers.deselect(member.UserId);
      })
    } else {
      this.requestDetails.ChangeManagementCabMembers.forEach(member => {
        this.selectedMembers.select(member.UserId);
      })
    }
  }

  refreshParent() {
    this.refreshData.emit();
  }

  savePlanning(iscomplete:boolean=false): Promise<void> {
    this.isSavingPlan=true;
    return new Promise<void>((resolve, reject) => {
      this.changeApi.updateChangePlanning(
        this.requestDetails.Id,
        this.requestDetails.ChangeManagement.Planning_ReasonForChange,
        this.requestDetails.ChangeManagement.Planning_Impact,
        this.requestDetails.ChangeManagement.Planning_RolloutPlan,
        this.requestDetails.ChangeManagement.Planning_BackoutPlan,
        null,
        iscomplete
      ).then(response => {
        this.refreshParent();
        resolve();
      }).catch(error => {
        reject(error);
      }).finally(() => {
        this.isSavingPlan=false;
      });

    });
    
  }

  completePlanning(){
    this.changeApi.setPlanningComplete(this.requestDetails.Id).then(response => this.refreshParent());
  }

  saveAndCompletePlanning() {
    this.savePlanning(true).then(() => {
      this.completePlanning();
    });
  }

  saveImplementation(iscomplete:boolean=false): Promise<void> {
    this.isSavingImplementation=true;
    return new Promise<void>((resolve, reject) => {
      this.changeApi.updateImplementation(
        this.requestDetails.Id,
        this.requestDetails.ChangeManagement.Implementation_Notes,
        this.DateConvert(this.requestDetails.ChangeManagement.Actual_StartDate),
        this.DateConvert(this.requestDetails.ChangeManagement.Actual_EndDate),
        iscomplete
      ).then(response => {
        this.refreshParent();
        resolve();
      }).catch(error => {
        reject(error);
      }).finally(()=>{
        this.isSavingImplementation=false;
      });
    });
  }

  completeImplementation(implemented){
    this.changeApi.setImplementationComplete(this.requestDetails.Id, implemented).then(response => this.refreshParent());
  }

  saveAndCompleteImplementation(implemented) {
    this.saveImplementation(true).then(() => {
      this.completeImplementation(implemented);
    });
  }


  saveReview(): Promise<void> {
    this.isSavingReview=true;
    return new Promise<void>((resolve, reject) => {
      this.changeApi.updateReview(
        this.requestDetails.Id,
        this.requestDetails.ChangeManagement.Review_Notes
      ).then(response => {
        this.refreshParent();
        resolve();
      }).catch(error => {
        reject(error);
      }).finally(()=>{
        this.isSavingReview=false;
      });
    });
  }

  completeReview(){
    this.changeApi.setReviewComplete(this.requestDetails.Id).then(response => this.refreshParent());
  }

  saveAndCompleteReview() {
    this.saveReview().then(() => {
      this.completeReview();
    });
  }

  saveClosure(): Promise<void> {
    this.isSavingclose=true;
    return new Promise<void>((resolve, reject) => {
      this.changeApi.updateClosureNotes(
        this.requestDetails.Id,
        this.requestDetails.ChangeManagement.Closure_Notes
      ).then(response => {
        this.refreshParent();
        resolve();
      }).catch(error => {
        reject(error);
      }).finally(() => {
        this.isSavingclose=false;
      });
    });
  }

  completeClosure(){
    this.changeApi.setClosureComplete(this.requestDetails.Id).then(response => this.refreshParent());
  }

  saveAndCompleteClosure() {
    this.saveClosure().then(() => {
      this.completeClosure();
    });
  }

  addCabMembers(){
    // Replace null with Component
    this.dialogService.createDialog(AppRequestChangeApprovalAddMembersDialogComponent, this.requestDetails, this.viewReference)
      .then(dialogSaved => {
        this.refreshParent();
      }, dialogCancelled => {

      })
  }

  removeCabMember(userId, cabId){
    this.changeApi.requestRemoveCabMember(this.requestDetails.Id, userId, cabId).then(response => {
      this.refreshParent();
    })
  }

  requestApproval(){
    this.changeApi.requestCabApproval(this.requestDetails.Id, this.selectedMembers.selected).then(response => this.refreshParent())
  }

  handleDowntimeCheckboxChange() {
    if (!this.DowntimeRequired) {
      this.requestDetails.ChangeManagement.Planning_DowntimeStart = null;
      this.requestDetails.ChangeManagement.Planning_DowntimeEnd = null;
    }
  }

  editSchedule(){
    this.changeApi.editScheduleInfo(this.requestDetails.Id).then(response => this.refreshParent());
  }

  saveScheduleInfo(Iscomplete:boolean=false): Promise<void> {
    this.isSavingSchedule=true;
    return new Promise<void>((resolve, reject) => {
      this.changeApi.updateScheduleInfo(
        this.requestDetails.Id,
        this.DateConvert(this.requestDetails.ChangeManagement.Planning_ScheduledStart),
        this.DateConvert(this.requestDetails.ChangeManagement.Planning_ScheduledEnd),
        this.DateConvert(this.requestDetails.ChangeManagement.Planning_DowntimeStart),
        this.DateConvert(this.requestDetails.ChangeManagement.Planning_DowntimeEnd),
        this.DateConvert(this.requestDetails.ChangeManagement.Requested_Date),
        Iscomplete
      ).then(response => {
        if ((this.DateConvert(this.requestDetails.ChangeManagement.Planning_DowntimeStart) && this.DateConvert(this.requestDetails.ChangeManagement.Planning_DowntimeStart) != '') ||
            (this.DateConvert(this.requestDetails.ChangeManagement.Planning_DowntimeEnd) && this.DateConvert(this.requestDetails.ChangeManagement.Planning_DowntimeEnd) != '')) {
          this.DowntimeRequired = true;
        } else {
          this.DowntimeRequired = false;
        }
  
        this.refreshParent();
        resolve();
      }).catch(error => {
        reject(error);
      }).finally(()=>{
        this.isSavingSchedule=false;
      });
    });
  }  

  completeSchedule(){
    this.changeApi.setScheduleComplete(this.requestDetails.Id).then(response => {
    if ((this.requestDetails.ChangeManagement.Planning_DowntimeStart && this.requestDetails.ChangeManagement.Planning_DowntimeStart != '0000-00-00 00:00:00') ||
        (this.requestDetails.ChangeManagement.Planning_DowntimeEnd && this.requestDetails.ChangeManagement.Planning_DowntimeEnd != '0000-00-00 00:00:00')) {
      this.DowntimeRequired = true;
    } else {
      this.DowntimeRequired = false;
    }

      this.refreshParent();
    });
  }

  saveAndCompleteSchedule() {
    this.saveScheduleInfo(true).then(() => {
      this.completeSchedule();
    });
  }

  allApprovalStatusCheck(): boolean {
    if (this.requestDetails.ChangeManagementCabMembers && Array.isArray(this.requestDetails.ChangeManagementCabMembers)) {
      return this.requestDetails.ChangeManagementCabMembers.every(member => member.ApprovalStatus != 3);
    }
    return false;
  }

  DateConvert(insert_date: Date | string): string | null {
    if (insert_date === null) {
      return null;
    }
    let date = new Date(insert_date);
    let year = date.getFullYear();
    let month = ('0' + (date.getMonth() + 1)).slice(-2); // Months are zero indexed
    let day = ('0' + date.getDate()).slice(-2);
    let hours = ('0' + date.getHours()).slice(-2);
    let minutes = ('0' + date.getMinutes()).slice(-2);
    let seconds = ('0' + date.getSeconds()).slice(-2);
  
    // Format StartDateTime as 'YYYY-MM-DD HH:mm:ss'
    return `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;
  }

}
