import {Component, OnInit, ViewContainerRef} from '@angular/core';
import {ActivatedRoute} from "@angular/router";
import {OptionsApiService} from "../../../../services/api/options/options-api.service";
import {AdminGlobalService} from "../../../../services/api/admin/admin-global.service";
import {ApiAdminRequestTypeService} from "../../../../services/api/admin/admin-request-type-api/admin-request-type-api.service";
import {SdeskMultiSelectComponent} from "../../../../shared/sdesk-multi-select/sdesk-multi-select.component";
import { SharedService } from 'src/app/shared/service/shared-service';

@Component({
  selector: 'app-type-settings',
  templateUrl: './type-settings.component.html',
  styleUrls: ['./type-settings.component.css']
})
export class TypeSettingsComponent implements OnInit {

  typeId: number;
  selectedTab;

  isLoading = true;
  isLoadingOptions = true;
  isLoadingGlobalSettings = true;
  isDropdownEmpty = false;

  values;
  options;
  globalSettings;

  dropdownSettings = {
    singleSelection: false,
    text: "Please select...",
    selectAllText:'Select All',
    unSelectAllText:'UnSelect All',
    enableSearchFilter: true,
    labelKey: 'name',
    lazyLoading: true,
    badgeShowLimit: 4,
    classes: "sdesk-multi-select"
  };
  origName = '';

  groupPermissions = [
    {id:1, name:'All agents can see this request type'},
    {id:2, name:'Only agents from specific groups can see this request type'}
  ];

  constructor(private route: ActivatedRoute,
              private typeApiService: ApiAdminRequestTypeService,
              private optionsService: OptionsApiService,
              private globalSettingsService: AdminGlobalService,
              private sharedService: SharedService) {
  }

  ngOnInit() {
    this.route.paramMap.subscribe(params => {
      this.typeId = parseInt(params.get("TypeId"));
      console.error("ReloadedType");
      this.loadData();
      this.loadOptions();
      this.loadGlobalSettings();
    })

    this.route.paramMap.subscribe(params => {
      this.selectedTab = params.get("SelectedTab");
      this.loadData();
      this.loadOptions();
      this.loadGlobalSettings();
    })
  }

  toggle() {

    if (!this.values.ApplySla) {
      this.values.SlaTemplate = null;
    }
    if (!this.values.CustomerEnabled) {
      this.values.CustomerRequired = 0;
    }
    if (!this.values.ServiceEnabled) {
      this.values.ServiceRequired = 0;
    }
    if (!this.values.ImpactEnabled) {
      this.values.ImpactRequired = 0;
      this.values.AutoPriority = 0;
    }
    if (!this.values.UrgencyEnabled) {
      this.values.UrgencyRequired = 0;
      this.values.AutoPriority = 0;
    }
    if (!this.values.PriorityEnabled) {
      this.values.PriorityRequired = 0;
      this.values.AutoPriority = 0;
      this.values.ApplySla = 0;
      this.values.SlaTemplate = null;
    }
  }


  loadData() {
    this.isLoading = true;

    this.typeApiService.get(this.typeId).then(response => {
      this.values = response;
      this.origName = this.values.Name;
      if (this.isCmdbEnabled()) {
        this.saveAssets(this.values);
      }
      this.isLoading = false;
    })
  }

  loadOptions() {
    this.isLoadingOptions = true;
    this.optionsService.DynamicOptions(this.typeId).then(response => {
      this.options = response;
      this.isLoadingOptions = false;
    })
  }
  
  isCmdbEnabled() {
    if (this.values && this.values.hasOwnProperty('CmdbEnabled') && this.values.CmdbEnabled === 1) {
        return true;
    }
    return false;
  }

  loadGlobalSettings() {
    this.isLoadingGlobalSettings = true;

    this.globalSettingsService.getData().then(response => {
      this.globalSettings = response;
      this.isLoadingGlobalSettings = false;
    })
  }

  saveData() {

    if (this.values.Security_GroupPermissions === 2 && !this.values.Security_GroupPermissions_Groups.length) {
      this.isDropdownEmpty = true;
      return; 
    }

    this.isDropdownEmpty = false;
    this.isLoading = true;

    this.typeApiService.update(this.typeId, this.values).then(response => {
      if (this.origName !==  this.values.Name) {
        // this is update, invoke the reloading of the request types, just in case the update is about changing the name of the request type
        this.sharedService.reloadRequestTypes();
      }
      this.loadData();
    })
  }

  saveAssets(values) {
    if (this.isCmdbEnabled()) {
      values.Security_GroupPermissions = 1
    }
    this.typeApiService.update(this.typeId, values).then(response => {
    })
  }
}
