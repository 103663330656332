import {Component, EventEmitter, Input, OnInit, Output, ViewContainerRef} from '@angular/core';
import {Dialog} from "../../../../shared/dialog/dialog";
import {ApiMassCommunicationService} from "../../../../services/api/mass-communication/mass-communication-api/mass-communication-api.service";
import {ModalConfirmationComponent} from "../../../../shared/modal-confirmation/modal-confirmation.component";
import {DialogService} from "../../../../services/dialog-service/dialog.service";

@Component({
  selector: 'app-mass-communication-create-edit-dialog',
  templateUrl: 'mass-communication-create-edit-dialog.component.html'
})

export class MassCommunicationComponentDialog implements Dialog {

  @Input() data;
  @Output() remove = new EventEmitter<any>();

  isLoading = true;
  loadedId = null;

  loadedData;


  constructor(
    private apiMassCommunicationService: ApiMassCommunicationService) {
  }


  ngOnInit(): void {
    if (this.data !== null) {
      this.loadedId = this.data;
      this.getData();
    } else {

      // Load Default Data
      this.loadedData = {
        Name: ''
      }

      this.isLoading = false;

    }
  }

  getData() {
    this.isLoading = true;
    this.apiMassCommunicationService.get(this.loadedId).then(response => {
      this.loadedData = response;
      this.isLoading = false;
    })
  }

  close() {
    this.remove.emit(null);
  }

  save() {
    if (this.loadedId) {
      this.apiMassCommunicationService.update(this.loadedId, this.loadedData).then(response => {
        this.remove.emit("Saved");
      })
    } else {
      this.apiMassCommunicationService.create(this.loadedData).then(response => {
        this.remove.emit("Saved");
      })
    }

  }

}
