import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-template-syntax',
  templateUrl: './template-syntax.component.html',
  styleUrls: ['./template-syntax.component.css']
})
export class TemplateSyntaxComponent implements OnInit {

  constructor() { }

  expanded = false;

  toggle(){
    this.expanded = !this.expanded;
  }

  ngOnInit(): void {
  }

}
