import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {RequestApiService} from "../../../../services/api/request/request-api.service";

@Component({
  selector: 'app-request-thread',
  templateUrl: './request-thread.component.html',
  styleUrls: ['./request-thread.component.css']
})
export class RequestThreadComponent implements OnInit {

  @Input() requestThread;
  @Input() selectedThreadId;
  @Input() requestDetailsClosed : boolean; 
  @Output() threadIdChanged = new EventEmitter<any>();
  @Output() refreshData = new EventEmitter<void>();


  constructor(private requestApi: RequestApiService) { }

  ngOnInit(): void {
  }

  getThreadItems(threadId){
    return this.requestThread.ThreadItems.filter(thread => thread.ThreadId === threadId);
  }

  getThreadItemAttachments(threadItemId){
    return this.requestThread.ThreadAttachments.filter(attachment => attachment.ThreadItemId === threadItemId);
  }

  getThreadLabel(threadObject){
    if(threadObject.IsMainThread){
      return "Main Thread";
    }
    if(threadObject.IsVendorThread){
      return "Vendor";
    }
    return threadObject.ToAddress;
  }

  changeThread(threadId){

    let newThreadObject = this.requestThread.Threads.find(thread => thread.ThreadId == threadId);
    if(newThreadObject.UnreadCount > 0){
      this.requestApi.resetUnreadCount(newThreadObject.RequestId, newThreadObject.ThreadId).then(response => {
        // do nothing
      })
    }

    this.threadIdChanged.emit(threadId);
  }

  onRefreshThreadItems() {
    this.refreshData.emit();
  }
}
