import {Component, OnInit, ViewContainerRef} from '@angular/core';
import {UserPermissionsService} from "../services/user-permissions/user-permissions.service";
import {AuthenticationService} from "../services/authentication/authentication.service";
import {BrandingService} from "../services/branding/branding.service";
import {RequestTypeService} from "../services/request-type/request-type.service";
import {DialogService} from "../services/dialog-service/dialog.service";
import {FilterService} from "../services/api/filter/filter.service";
import {Router} from "@angular/router";
import {RequestChangeTypeModalComponent} from "../agent/pages/request/request-change-type-modal/request-change-type-modal.component";
import {SelfServiceNewTicketComponent} from "./self-service-new-ticket/self-service-new-ticket.component";
import {EnvironmentService} from "../services/environment/environment.service";

@Component({
  selector: 'app-self-service',
  templateUrl: './self-service.component.html',
  styleUrls: ['./self-service.component.css']
})
export class SelfServiceComponent implements OnInit {

  brandingSubscription;
  environmentSubscription;
  userPermissionSubscription;
  sideBarNavOpen = true;
  isLoading = true;


  constructor(private userPermissions: UserPermissionsService,
              public auth: AuthenticationService,
              private brandingService: BrandingService,
              private dialogService: DialogService,
              private viewReference: ViewContainerRef,
              private router: Router,
              private environmentService: EnvironmentService) {

    // Subscribe to permission service IF logged in
    if(this.auth.amILoggedIn()){
      this.userPermissions.refreshUserPermissions();
      this.userPermissions.userPermissionsSubscription.subscribe(data => this.userPermissionSubscription = data)
    }

    // Subscribe to branding service
    brandingService.brandingSubscription.subscribe(data => this.brandingSubscription = data);

    // Subscribe to environment service
    environmentService.environmentVariablesSubscription.subscribe(data => {
      if(!data){
        return;
      } else {
        this.environmentSubscription = data
        this.isLoading = false;
        this.checkAuth();
      }
    });

  }

  checkAuth(){
    // If Require login == 1 OR show knowledge base == 0 check logged in.
    if(this.environmentSubscription.RequireLogin || !this.environmentSubscription.ShowKnowledgeBase){
      this.auth.checkLoggedIn();
    }
  }

  newTicket(){
    this.dialogService.createDialog(SelfServiceNewTicketComponent, null, this.viewReference)
      .then(dialogSaved => {
      }, dialogCancelled => {
      })
  }

  logout(){
    this.auth.logout();

    if(this.environmentSubscription.RequireLogin || !this.environmentSubscription.ShowKnowledgeBase){
      this.auth.redirectToLoginPage();
    }
  }

  ngOnInit(): void {}

}
