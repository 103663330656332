import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {Dialog} from "../../../../shared/dialog/dialog";
import {RequestLinkService} from "../../../../services/api/request/request-link/request-link.service";

@Component({
  selector: 'app-request-show-link-modal',
  templateUrl: './request-show-link-modal.component.html',
  styleUrls: ['./request-show-link-modal.component.css']
})
export class RequestShowLinkModalComponent implements Dialog {
  @Input() data;
  @Output() remove = new EventEmitter<any>();
  constructor(private requestLinkApi: RequestLinkService) { }

  ngOnInit(): void {
  }
  closeModal(){
    this.remove.emit(null);
  }

  save(){
    this.remove.emit('refresh');
  }

  removeLink(requestLinkId){
    this.requestLinkApi.removeLink(requestLinkId).then(response => this.save());
  }
}
