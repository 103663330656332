<div class="SDeskSidePanel-GreyBackground"></div>

<app-blade-right  *ngIf="!isLoading" size="blade-large">
  <div class="p-10 p-b-0">
    <i (click)="this.close()" class="fas fa-times fa-2x float-end divLink"></i>
    <h3>{{this.loadedId ? 'Edit' : 'Create'}} Group</h3>
  </div>
  <hr>
  <div class="container p-0">

    <div class="col-12 m-b-20 ">
      <ul class="nav dialogTabs">
        <li class="nav-item divLink" (click)="selectedTab = 'details'">
          <a class="nav-link-thread" [class.active]="selectedTab == 'details'">
            Details
          </a>
        </li>

        <!--  Only show tab on edit  -->
        <li *ngIf="this.loadedId" class="nav-item divLink" (click)="selectedTab = 'members'">
          <a class="nav-link-thread" [class.active]="selectedTab == 'members'">
            Members
          </a>
        </li>

      </ul>
    </div>



<div *ngIf="selectedTab == 'details'">
    <div class="row">
      <div class="col-sm-3 formHeader alignMiddle p-10">
        Name <span class="text-danger">*</span>
      </div>
      <div class="col-sm p-10">
        <input [(ngModel)]="loadedData.Name" type="text" class="form-control" id="Name" placeholder="Name" autocomplete="off">

      </div>
    </div>
    <div *ngIf="loadedData.SlaLinks">
      <div class="p-10 p-b-0 m-t-20">
        <h4>Override SLA's</h4>
      </div>
      <hr>


      <div *ngFor="let slaLink of this.loadedData.SlaLinks" class="row">
          <div class="col-sm-3 formHeader alignMiddle p-10">
            {{slaLink.TypeName}}
          </div>
          <div class="col-sm p-10">

            <ng-select [items]="slaTemplates"
                       bindLabel="Name"
                       bindValue="Id"
                       [(ngModel)]="slaLink.SlaId"
                       [clearable]="true">
            </ng-select>

          </div>
      </div>
    </div>

  <div class="m-t-20">
    <div (click)="save()" class="btn btn-primary">Save</div>
    <div (click)="close()" class="btn btn-cancel">Cancel</div>
  </div>
</div>

    <div *ngIf="selectedTab == 'members'">

      <div class="table-responsive">
        <table class="table align-middle tableStyle">
          <thead>
          <tr>
            <th>Name</th>
            <th>Email</th>
            <th><div (click)="openUserSearchModal()" class=" divLink float-end btn btn-primary">Add Users +</div></th>
          </tr>
          </thead>
          <tbody>
          <tr *ngFor="let user of this.loadedData.Members; let i = index" class="divLink">
            <td>{{user.Name}}</td>
            <td>{{user.EmailAddress}}</td>
            <td><i class="far fa-times float-end m-r-20 divLink" (click)="removeMember(user.Id)" aria-hidden="true"></i></td>
          </tr>
          </tbody>
        </table>
      </div>

      <div class="m-t-20">
        <div (click)="close()" class="btn btn-primary">Close</div>
      </div>

    </div>



  </div>
</app-blade-right>
