<app-modal [modalSize]="'modal-medium'">
  <div class="modal-header">
    <h5 class="modal-title" id="exampleModalLabel">Link Customers</h5>
  </div>
  <app-loading-spinner *ngIf="loading"></app-loading-spinner>
  <div *ngIf="!loading" class="modal-body">
    <input [(ngModel)]="search" type="text" id="Search" placeholder="Search.." autocomplete="off" class="form-control">
    <div class="table-responsive">
      <table class="table align-middle tableStyle">
        <thead>
        <tr>
          <th class="col-1">              <div class="form-check">
            <input [ngModel]="this.checkboxIsAllSelected()" (change)="this.checkboxToggleAll()"
                   class="form-check-input" type="checkbox" id="flexCheckDefault">
            <label class="form-check-label" for="flexCheckDefault"></label>
          </div></th>
          <th class="col">Customer Name</th>
        </tr>
        </thead>
        <tbody>
        <tr *ngFor="let customer of this.returnFilteredCustomers()" class="divLink">
          <td><input [ngModel]="this.selected.isSelected(customer.Id)"
                     (change)="this.selected.toggle(customer.Id)" class="form-check-input" type="checkbox"
                     id="filterViewTable">
            <label class="form-check-label" for="flexCheckDefault"></label></td>
          <td>{{customer.Name}}</td>
        </tr>
        </tbody>
      </table>
    </div>


  </div>
  <div class="modal-footer">
    <div (click)="save()" class="btn btn-primary">Link ({{this.selected.selected.length}}) Customers</div>
    <div (click)="closeModal()" class="btn btn-cancel" data-bs-dismiss="modal">Cancel</div>
  </div>
</app-modal>
