
<div class="m-t-10 m-b-10">
  <h4 class="p-10 p-b-0"><strong>Search</strong></h4>

  <div class="row">
      <div class="col-md-2" style="display: flex; align-items: stretch;">
        <select [(ngModel)]="defaultSearchType" class="form-control large-search-box" data-style="btn-white" style="height: 100%;">
          <option *ngFor="let option of this.searchType" [label]="option.name" [value]="option.value" selected="selected"></option>
        </select>
      </div>
      <div class="col-md-10">
    <input [(ngModel)]="searchTerm" (keyup.enter)="search()" type="text" class="form-control large-search-box" autocomplete="off" placeholder="Search..." id="search">
  </div>

  <div class="row">
    <div class="sectionBox p-0 col">

      <app-loading-spinner *ngIf="isLoading"></app-loading-spinner>


      <div *ngIf="!isLoading" class="table-responsive">
        <table class="table align-middle tableStyle">
          <thead>
          <tr>
            <th class="table-header" (click)="toggleSortOrder('LocalRequestId')">Id 
              <span *ngIf="sortColumn === 'LocalRequestId' && sortOrder" [ngClass]="sortOrder === 'asc' ? 'fa-arrow-up' : 'fa-arrow-down'"></span>
            </th>
            <th class="table-header" (click)="toggleSortOrder('RequestTypeName')">Type 
              <span *ngIf="sortColumn === 'RequestTypeName' && sortOrder" [ngClass]="sortOrder === 'asc' ? 'fa-arrow-up' : 'fa-arrow-down'"></span>
            </th>
            <th class="table-header" (click)="toggleSortOrder('RequesterName')">Requester 
              <span *ngIf="sortColumn === 'RequesterName' && sortOrder" [ngClass]="sortOrder === 'asc' ? 'fa-arrow-up' : 'fa-arrow-down'"></span>
            </th>
            <th class="table-header" (click)="toggleSortOrder('Subject')">Subject 
              <span *ngIf="sortColumn === 'Subject' && sortOrder" [ngClass]="sortOrder === 'asc' ? 'fa-arrow-up' : 'fa-arrow-down'"></span>
            </th>
            <th class="table-header" (click)="toggleSortOrder('Status')">Status 
              <span *ngIf="sortColumn === 'Status' && sortOrder" [ngClass]="sortOrder === 'asc' ? 'fa-arrow-up' : 'fa-arrow-down'"></span>
            </th>
            <th class="table-header" (click)="toggleSortOrder('Priority')">Priority 
              <span *ngIf="sortColumn === 'Priority' && sortOrder" [ngClass]="sortOrder === 'asc' ? 'fa-arrow-up' : 'fa-arrow-down'"></span>
            </th>
            <th class="table-header" (click)="toggleSortOrder('Agent')">Agent 
              <span *ngIf="sortColumn === 'Agent' && sortOrder" [ngClass]="sortOrder === 'asc' ? 'fa-arrow-up' : 'fa-arrow-down'"></span>
            </th>
            <th class="table-header" (click)="toggleSortOrder('DateTimeUpdated')">Updated 
              <span *ngIf="sortColumn === 'DateTimeUpdated' && sortOrder" [ngClass]="sortOrder === 'asc' ? 'fa-arrow-up' : 'fa-arrow-down'"></span>
            </th>
            <th class="table-header" (click)="toggleSortOrder('DateTimeCreated')">Created 
              <span *ngIf="sortColumn === 'DateTimeCreated' && sortOrder" [ngClass]="sortOrder === 'asc' ? 'fa-arrow-up' : 'fa-arrow-down'"></span>
            </th>
          </tr>
          </thead>
          <tbody>
          <tr *ngFor="let request of this.searchResults"  class="divLink">
            <td [routerLink]="['/ticket/', request.Id]">{{request.LocalRequestId}}</td>
            <td [routerLink]="['/ticket/', request.Id]">{{request.RequestTypeName}}</td>
            <td [routerLink]="['/ticket/', request.Id]">{{request.RequesterName}}</td>
            <td [routerLink]="['/ticket/', request.Id]" [innerHTML]="request.Subject"></td>
            <td [routerLink]="['/ticket/', request.Id]">{{request.Status}}</td>
            <td [routerLink]="['/ticket/', request.Id]">{{request.Priority}}</td>
            <td [routerLink]="['/ticket/', request.Id]">{{request.Agent}}</td>
            <td [routerLink]="['/ticket/', request.Id]">
              {{ (request.DateTimeUpdated | amTimeAgo) == "Invalid date" ? (request.DateTimeUpdated) : (request.DateTimeUpdated | amTimeAgo) }}
            </td>
            <td [routerLink]="['/ticket/', request.Id]">
              {{ (request.DateTimeCreated | amTimeAgo) == "Invalid date" ? (request.DateTimeCreated) : (request.DateTimeCreated | amTimeAgo) }}
            </td>
          </tr>
          </tbody>
        </table>
      </div>


    </div>
  </div>
</div>
