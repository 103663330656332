<app-loading-spinner *ngIf="isLoading || isLoadingOptions"></app-loading-spinner>

<div *ngIf="!isLoading && !isLoadingOptions" class="table-responsive p-l-20 p-r-20">

  <h4 style="
    text-align: center;
    text-decoration: underline;
">Impact</h4>

  <div class="row">
    <div class="col-1">
      <h4 class="" style="
        text-decoration: underline;
        writing-mode: vertical-lr;
        text-orientation: inherit;
        transform: rotate(-180deg);
        padding-bottom: 70px;">Urgency</h4>
    </div>
    <div class="col">
      <table class="table table table-hover m-0">
        <thead>
        <tr>
          <td></td>
          <th *ngFor="let i of options.impact">{{i.name}}</th>
        </tr>
        </thead>
        <tbody>
        <tr *ngFor="let u of options.urgency">
          <th scope="row">{{u.name}}</th>
          <td *ngFor="let i of options.impact">
            <ng-select [items]="options.priority"
                       bindLabel="name"
                       bindValue="id"
                       [(ngModel)]="autoPriorityData[i.id][u.id].PriorityId"
                       [clearable]="false">
            </ng-select>
          </td>
        </tr>
        </tbody>
      </table>
    </div>

    <div class="row">
      <div class="col-1">
      </div>
      <div class="col p-10">
        <div (click)="update()" class="btn btn-primary">Save</div>
      </div>
    </div>


  </div>
</div>
