import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';


@Injectable({
  providedIn: 'root'
})
export class TestServiceService {
  private authToken = 'DefaultAuthToken';
  constructor() { }

  returnAuthToken() {
    console.log("Calling returnAuthToken");
    return this.authToken;
  }

  setAuthToken(newToken){
    console.log("Calling setAuthToken")
    this.authToken = newToken;
  }

}
