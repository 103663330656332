import {Component, EventEmitter, Input, OnInit, Output, ViewContainerRef} from '@angular/core';
import {Dialog} from "../../../../../../shared/dialog/dialog";
import {ApiAdminWebhookService} from "../../../../../../services/api/admin/admin-webhook-api/admin-webhook-api.service";
import {OptionsApiService} from "../../../../../../services/api/options/options-api.service";

@Component({
  selector: 'app-admin-webhooks-create-edit-dialog',
  templateUrl: './admin-webhooks-create-edit-dialog.component.html'
})

export class AdminWebhookComponentDialog implements Dialog {

  @Input() data; // {createNewWithRequestTypeId: 112 ,id: null}
  @Output() remove = new EventEmitter<any>();

  isLoading = true;
  isLoadingRequestTypes = true;
  isLoadingOptions = true;

  loadedId = null;

  browserHostname = window.location.hostname

  loadedData;

  requestTypes;
  options;

  constructor(
    private apiAdminWebhookService: ApiAdminWebhookService,
    private apiAdminOptionsService: OptionsApiService
  ) {
    this.getRequestTypes();
  }


  ngOnInit(): void {
    if (this.data.id) {
      this.loadedId = this.data.id;
      this.getData();
    } else {

      // Load Default Data
      this.loadedData = {
        Name: "",
        Enabled: 1,
        RequestTypeId: this.data.createNewWithRequestTypeId,
        GroupByService: null,
        RequestFormat: 2,
        Default_RequesterId: null,
        Default_ImpactId: null,
        Default_UrgencyId: null,
        Default_PriorityId: null,
        Default_GroupId: null,
        Default_StatusId: null,
        Default_CustomerId: null,
        Default_ServiceId: null,
        ValueKey_Subject: "subject",
        ValueKey_Body: "body",
        ValueKey_Requester: "requesterEmailAddress",
        ValueKey_Impact: "impactName",
        ValueKey_Urgency: "urgencyName",
        ValueKey_Priority: "priorityName",
        ValueKey_Group: "groupName",
        ValueKey_Status: "statusName",
        ValueKey_Customer: "customerName",
        ValueKey_Service: "serviceName",
        CustomFields: []
      }

      this.changeRequestType();

      this.isLoading = false;

    }
  }

  getData() {
    this.isLoading = true;
    this.apiAdminWebhookService.get(this.loadedId).then(response => {
      this.loadedData = response;
      this.isLoading = false;

      // Refresh Options after data is loaded
      this.refreshOptions();
    })
  }

  getRequestTypes() {
    this.isLoadingRequestTypes = true;
    this.apiAdminOptionsService.AllRequestTypes().then(response => {
      this.requestTypes = response;
      this.isLoadingRequestTypes = false;
    })
  }

  getCustomFieldOptions(customFieldId) {
    return this.options.CustomFields.find(cf => cf.CustomFieldId == customFieldId).Options;
  }

  changeRequestType() {

    // Reset values because type has changed
    this.loadedData.Default_RequesterId = null;
    this.loadedData.Default_ImpactId = null;
    this.loadedData.Default_UrgencyId = null;
    this.loadedData.Default_PriorityId = null;
    this.loadedData.Default_GroupId = null;
    this.loadedData.Default_StatusId = null;
    this.loadedData.Default_CustomerId = null;
    this.loadedData.Default_ServiceId = null;
    this.loadedData.CustomFields = [];

    this.refreshOptions();

  }

  // Call RefreshOptions when Customer/Group changed
  refreshOptions() {
    this.isLoadingOptions = true;
    if (this.loadedData.RequestTypeId) {
      this.apiAdminOptionsService.DynamicOptions(
        this.loadedData.RequestTypeId,
        this.loadedData.Default_GroupId,
        this.loadedData.Default_CustomerId
      ).then(response => {
        this.options = response;
        this.isLoadingOptions = false;
      })
    }
  }

  close() {
    this.remove.emit(null);
  }

  save() {
    if (this.loadedId) {
      this.apiAdminWebhookService.update(this.loadedId, this.loadedData).then(response => {
        this.remove.emit("Saved");
      })
    } else {
      this.apiAdminWebhookService.create(this.loadedData).then(response => {
        this.remove.emit("Saved");
      })
    }

  }
}
