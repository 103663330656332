import {Component, Input, OnInit} from '@angular/core';

@Component({
  selector: 'app-request-sla',
  templateUrl: './request-sla.component.html',
  styleUrls: ['./request-sla.component.css']
})
export class RequestSlaComponent implements OnInit {

  @Input() requestDetails;

  constructor() { }

  ngOnInit(): void {
  }

}
