import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {Dialog} from "../dialog/dialog";
import {AgentUserService} from "../../services/api/agent-user/agent-user.service";
@Component({
  selector: 'app-requester-search-modal',
  templateUrl: './requester-search-modal.component.html',
  styleUrls: ['./requester-search-modal.component.css']
})
export class RequesterSearchModalComponent implements Dialog {

  @Input() data;
  @Output() remove = new EventEmitter<any>();

  userList;
  selectedUserId = null;
  selectedUserName = null;
  selectedUserCustomerId = null;
  selectedUserEmail = null;
  selectedUserPreferredContactMethod=null;
  selectedUserPhoneNumber = null;

  searchTerm = '';

  isLoading = true;

  constructor(private agentUserApi : AgentUserService) {
    this.refreshUsers();
  }

  filteredUsers(){
   let filteredData  = this.userList;
   let searchTerm = this.searchTerm.toLocaleLowerCase();

   if(searchTerm){
     filteredData = filteredData.filter(user => {
       return user.Name.toString().toLocaleLowerCase().includes(searchTerm) || user.EmailAddress.toString().toLocaleLowerCase().includes(searchTerm)
     })
   }

    filteredData = filteredData.slice(0, 10);

    return filteredData;
  }

  refreshUsers() {
    this.isLoading = true;
    this.agentUserApi.GetUsers().then(response => {
      this.userList = response["Result"];
      this.isLoading = false;
    });
  }

  ngOnInit(): void {
  }

  closeModal(){
    console.log("Clicked Cancel...");
    this.remove.emit(null);
  }

  save(){
    console.log("Clicked Save...");
    this.remove.emit({userId: this.selectedUserId, customerId: this.selectedUserCustomerId, userName: this.selectedUserName,
      userEmail:this.selectedUserEmail,prefferedContactMethod:this.selectedUserPreferredContactMethod,userContact:this.selectedUserPhoneNumber});
  }

}
