import {Component, Input, OnInit} from '@angular/core';
import 'moment/locale/en-gb';
import 'moment';


@Component({
  selector: 'app-friendly-time',
  templateUrl: './friendly-time.component.html',
  styleUrls: ['./friendly-time.component.css']
})
export class FriendlyTimeComponent implements OnInit {

  @Input() utcTimeData;
  @Input() timeFormat;
  instanceParams: any;
  preferredDateFormat: string;



  constructor() { }

  ngOnInit(): void {

    this.preferredDateFormat = this.getMomentDateFormat(this.timeFormat);

  }

  getMomentDateFormat(format: string): string {
    switch (format) {
      case 'm/d/Y H:i':
        return 'MM/DD/YYYY HH:mm';
      case 'd/m/Y H:i':
        return 'DD/MM/YYYY HH:mm';
       case 'm/d/Y':
          return 'MM/DD/YYYY HH:mm';
        case 'd/m/Y':
          return 'DD/MM/YYYY HH:mm';
      default:
        return 'DD/MM/YYYY HH:mm'; // Default format if none is provided or recognized
    }
  }



}
