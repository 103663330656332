<div class="SDeskSidePanel-GreyBackground"></div>

<app-blade-right  *ngIf="!isLoading" size="blade-large">
  <div class="p-10 p-b-0">
    <i (click)="this.close()" class="fas fa-times fa-2x float-end divLink"></i>
    <h3>{{this.loadedId ? 'Edit' : 'Create'}} Sender Domain</h3>
  </div>
  <hr>
  <div class="container p-0">

    <div class="row">
      <div class="col-sm-2 formHeader alignMiddle p-10">
        Domain <span class="text-danger">*</span>
      </div>
      <div class="col-sm p-10">
        <input [(ngModel)]="loadedData.Domain" [disabled]="this.loadedId" type="text" class="form-control" id="Name" placeholder="Name" autocomplete="off">
      </div>
    </div>

    <div *ngIf="this.loadedId && !loadedData.SendGridData.errors">
        <h4>Status: {{ loadedData.SendGridData.valid ? 'Valid' : 'Pending' }}</h4>

        <h4>DNS Entries</h4>

        <table class="table table-hover mails m-0 table table-actions-bar">
          <thead>
          <tr>
            <th>Name</th>
            <th>Host</th>
            <th>Type</th>
            <th>Data</th>
            <th>Valid</th>
          </tr>
          </thead>
          <tbody>
          <tr>
            <td>
              DKIM 1
            </td>
            <td>
              {{loadedData.SendGridData.dns.dkim1.host}}
            </td>
            <td>
              {{loadedData.SendGridData.dns.dkim1.type}}
            </td>
            <td>
              {{loadedData.SendGridData.dns.dkim1.data}}
            </td>
            <td>
              {{ loadedData.SendGridData.dns.dkim1.valid ? 'Valid' : 'Pending' }}
            </td>

          </tr>

          <tr>
            <td>
              DKIM 2
            </td>
            <td>
              {{loadedData.SendGridData.dns.dkim2.host}}
            </td>
            <td>
              {{loadedData.SendGridData.dns.dkim2.type}}
            </td>
            <td>
              {{loadedData.SendGridData.dns.dkim2.data}}
            </td>
            <td>
              {{ loadedData.SendGridData.dns.dkim2.valid ? 'Valid' : 'Pending' }}
            </td>
          </tr>

          <tr>
            <td>
              Mail CNAME
            </td>
            <td>
              {{loadedData.SendGridData.dns.mail_cname.host}}
            </td>
            <td>
              {{loadedData.SendGridData.dns.mail_cname.type}}
            </td>
            <td>
              {{loadedData.SendGridData.dns.mail_cname.data}}
            </td>
            <td>
              {{ loadedData.SendGridData.dns.mail_cname.valid ? 'Valid' : 'Pending' }}
            </td>
          </tr>

          </tbody>
        </table>

    </div>
    <div *ngIf="this.loadedId && loadedData.SendGridData.errors" class="row">
      <div class="col-sm-2 formHeader alignMiddle p-10">
        <p>SendGrid Error Message: </p>
      </div>
      <div class="col-sm p-10">
        {{ loadedData.SendGridData.errors[0].message }}
      </div>
      
    </div>

    <div class="m-t-20">
      <div *ngIf="!this.loadedId" (click)="save()" class="btn btn-primary">Save</div>
      <div (click)="close()" class="btn btn-cancel">Cancel</div>
    </div>

  </div>
</app-blade-right>
