import {Component, Input, OnInit, Output, EventEmitter} from '@angular/core';
import {Dialog} from "../../shared/dialog/dialog";

@Component({
  selector: 'app-button-component',
  templateUrl: './button-component.component.html',
  styleUrls: ['./button-component.component.css']
})
export class ButtonComponent implements Dialog {

  @Input() data;
  @Output() remove = new EventEmitter<any>();

  ngOnInit() {
    console.log(this.data?.id);
  }
}
