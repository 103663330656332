<app-loading-spinner *ngIf="isLoading" ></app-loading-spinner>

<div class="col-12 sectionBox m-t-10">
  <div class="m-t-20">
    <div *ngIf="!isLoading" class="table-responsive p-l-20 p-r-20">

      <table class="table align-middle tableStyle">
        <thead>
        <tr>
          <th class="">Name</th>
          <th class="">Request Type</th>
          <th class="">      <div (click)="create()" class="btn btn-primary float-end">Create</div>
          </th>

        </tr>
        </thead>
        <tbody>
        <tr *ngFor="let row of this.data ">
          <td><span class="divLink" (click)="edit(row.Id)">{{row.Name}}</span></td>
          <td>{{row.TypeName}}</td>
            <td><i class="far fa-trash-alt float-end m-r-20 divLink" (click)="delete(row.Id)"></i>
            <i class="fas fa-cog float-end m-r-20 divLink" (click)="edit(row.Id)"></i>
          </td>
        </tr>
        </tbody>
      </table>
    </div>
  </div>
</div>
