import { Injectable } from '@angular/core';
import {ApiService} from "../../api.service";

@Injectable({
  providedIn: 'root'
})
export class DashboardSettingsApiService {

  private dashboardSettingsApi = '/api/dashboardsettings';

  constructor(private api: ApiService) { }

  getSettings(){
    return this.api.get(this.dashboardSettingsApi);
  }

  updateSettings(settingsData){
    return this.api.post(this.dashboardSettingsApi, settingsData);
  }
}
