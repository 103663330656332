

import {Component, EventEmitter, Input, OnInit, Output, ViewContainerRef} from '@angular/core';
import {Dialog} from "../../../../../../shared/dialog/dialog";
import {ApiAdminRequestTypeService} from "../../../../../../services/api/admin/admin-request-type-api/admin-request-type-api.service";

@Component({
  selector: 'app-admin-request-types-create-edit-dialog',
  templateUrl: './admin-request-types-create-edit-dialog.component.html'
})

export class AdminRequestTypesComponentDialog implements Dialog {

  @Input() data;
  @Output() remove = new EventEmitter<any>();

  isLoading = true;
  loadedId = null;

  loadedData;

  constructor(
    private apiAdminRequestTypeService: ApiAdminRequestTypeService
  ) {}


  ngOnInit(): void {
    if(this.data !== null){
      this.loadedId = this.data;
      this.getData();
    } else {

      // Load Default Data
      this.loadedData = {
        Name: ''
      }

      this.isLoading = false;

    }
  }

  getData(){
    this.isLoading = true;
    this.apiAdminRequestTypeService.get(this.loadedId).then(response => {
      this.loadedData = response;
      this.isLoading = false;
    })
  }

  close(){
    this.remove.emit(null);
  }

  save(){
    this.apiAdminRequestTypeService.create(this.loadedData).then(response => {
      this.remove.emit("Saved");
    })

  }
}
