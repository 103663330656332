

import {Component, OnInit, ViewContainerRef, ViewChild, ElementRef} from '@angular/core';
import {ApiAdminCustomerPortalsService} from "../../../../services/api/admin/admin-customer-portal/admin-customer-portal.service";
import {DialogService} from "../../../../services/dialog-service/dialog.service";
import {AdminCustomerPortalsComponentDialog} from "./admin-customer-portal-create-edit-dialog/admin-customer-portal-create-edit-dialog.component";
import {ModalConfirmationComponent} from "../../../../shared/modal-confirmation/modal-confirmation.component";
import {SelectionModel} from "@angular/cdk/collections";
import {AdminCustomerPortalCategoryCreateEditDialogComponent} from "./admin-customer-portal-category-create-edit-dialog/admin-customer-portal-category-create-edit-dialog.component";
import * as QuillNamespace from 'quill';
import { ApiAdminCustomerService } from 'src/app/services/api/admin/admin-customer-api/admin-customer-api.service';
const Quill: any = QuillNamespace;

@Component({
  selector: 'app-admin-customer-portal',
  templateUrl: './admin-customer-portal.html'
})
export class AdminCustomerPortalsComponent implements OnInit {

  @ViewChild('editor') editorElement: ElementRef;

  data;
  isLoading = true;
  isLoadingArticle = false;
  public expandedCategories = new SelectionModel(
    true,   // multiple selection or not
    [] // initial selected values
  );

  showEditArticle = false;
  selectedArticleId;
  selectedArticle;
  public bodyString:string;
  public convertedBody:any;
  filteredArticles = [];
  customers=[];
  filterField:any;
  OldCustomerId:any =[];
  customerIdarray:any = [];

  modules = {
    toolbar: [
      ['bold', 'italic', 'underline'],        // toggled buttons
      // ['blockquote', 'code-block'],

      [{ 'header': 1 }, { 'header': 2 }],               // custom button values
      [{ 'list': 'ordered'}, { 'list': 'bullet' }],
      // [{ 'script': 'sub'}, { 'script': 'super' }],      // superscript/subscript
      // [{ 'indent': '-1'}, { 'indent': '+1' }],          // outdent/indent
      // [{ 'direction': 'rtl' }],                         // text direction

      [{ 'size': ['small', false, 'large', 'huge'] }],  // custom dropdown
      [{ 'header': [1, 2, 3, 4, 5, 6, false] }],

      [{ 'color': [] },
        { 'background': [] }
        ],          // dropdown with defaults from theme
      // [{ 'font': [] }],
      // [{ 'align': [] }],

      ['clean'],                                         // remove formatting button

      // ['link', 'image', 'video']                         // link and image, video
      ['link','video']
    ]
  };

  constructor(
    private apiAdminCustomerPortalsService: ApiAdminCustomerPortalsService,private apiAdminCustomerService: ApiAdminCustomerService,
    private dialogService: DialogService,
    private viewReference: ViewContainerRef) {
    this.getData();

    const Link = Quill.import('formats/link');
    Link.sanitize = function(url) {
      // quill by default creates relative links if scheme is missing.
      if (!url.startsWith('http://') && !url.startsWith('https://')) {
        return `https://${url}`;
      }
      return url;
    };
  }

  ngOnInit(): void {
    this.getCustomers();
  }

  public getCustomers():void{
    this.isLoading = true;
    this.apiAdminCustomerService.listAll().then(response => {
      this.customers = response;
      this.isLoading = false;
    })
  }

  getData(){
    this.isLoading = true;
    this.apiAdminCustomerPortalsService.listAll().then(response => {
      this.data = response;   
      this.isLoading = false;
    })
  }

  Search() {
    if (this.filterField !== "") {
      let searchValue = this.filterField.toLocaleLowerCase();
      this.filteredArticles = this.data.Articles.filter(article => {
        return article.Title.toLocaleLowerCase().match(searchValue)
      });
    } else {
      this.ngOnInit();
    }
  }

  deleteArticle(){

    this.dialogService.createDialog(ModalConfirmationComponent, 'Are you sure?', this.viewReference)
      .then(confirmed => {
        this.apiAdminCustomerPortalsService.deleteArticle(this.selectedArticleId).then(response => {
          this.getData();
          this.cancel();
        })
      }, cancelled => null)


  }

  saveArticle(){
    if(this.selectedArticleId){
      this.selectedArticle.OldCustomerId = this.OldCustomerId;
      this.apiAdminCustomerPortalsService.updateArticle(this.selectedArticleId, this.selectedArticle).then(response => {
        this.getData();
        this.cancel();
      })
    } else {
      this.apiAdminCustomerPortalsService.createArticle(this.selectedArticle).then(response => {
        this.getData();
        this.cancel();
      })
    }
  }

  cancel(){
    this.selectedArticle = null;
    this.selectedArticleId = null;
    this.showEditArticle = false;
    this.filteredArticles = [];
    this.filterField ='';
  }

  getArticles(categoryId) {
    return this.data.Articles.filter(obj => {
      return obj.CategoryId === categoryId
    });
  }

  editArticle(id){
    this.isLoadingArticle = true;
    this.filterField='';
    this.selectedArticleId = id;

    this.apiAdminCustomerPortalsService.getArticle(id).then(response => {
      this.selectedArticle = response;
      this.OldCustomerId = this.selectedArticle.CustomerId;
      console.log("test",this.OldCustomerId)
      this.bodyString =this.selectedArticle.Body;
      this.convertedBody = this.decode(this.bodyString)
      this.selectedArticle.Body = this.convertedBody;
      this.showEditArticle = true;
      this.isLoadingArticle = false;
    })
  }
  
  decode(str: string) {
    let txt = document.createElement("textarea");
    txt.innerHTML = str;
    return txt.value;
  }

  updatedTitle(){
    // If SelectedArticleId is NULL then its creating a new article.
    if(this.selectedArticleId == null){
      let NewUrl = this.selectedArticle.Title.toLowerCase().split(' ').join('-').replace(/[^0-9a-z-]/gi, '').substring(0, 100);
      this.selectedArticle.Url = NewUrl;
    }
  }

  createArticle(){
    this.selectedArticleId = null;
    this.selectedArticle = {
      Title: '',
      Body: '',
      Url: '',
      Published: false,
      CategoryId: null,
      Tags: []
    }

    this.isLoadingArticle = false;
    this.showEditArticle = true;
  }

  createPortal(){
    this.dialogService.createDialog(AdminCustomerPortalsComponentDialog, null, this.viewReference)
      .then(dialogSaved => {
        this.getData();
      }, dialogCancelled => {

      })
  }

  editPortal(id){
    this.dialogService.createDialog(AdminCustomerPortalsComponentDialog, id, this.viewReference)
      .then(dialogSaved => {
        this.getData();
      }, dialogCancelled => {

      })
  }

  editCategory(id){
    this.dialogService.createDialog(AdminCustomerPortalCategoryCreateEditDialogComponent, id, this.viewReference)
      .then(dialogSaved => {
        this.getData();
      }, dialogCancelled => {

      })
  }

  createCategory(){
    this.dialogService.createDialog(AdminCustomerPortalCategoryCreateEditDialogComponent, null, this.viewReference)
      .then(dialogSaved => {
        this.getData();
      }, dialogCancelled => {

      })
  }

  deletePortal(id){
    this.dialogService.createDialog(ModalConfirmationComponent, 'Are you sure?', this.viewReference)
      .then(confirmed => {
        this.apiAdminCustomerPortalsService.deletePortal(id).then(response => this.getData())
      }, cancelled => null)
  }
  onModelChange(event)
  {
    console.log("modelchanged",event)
    this.customerIdarray = event;
  }


}
