<app-loading-spinner *ngIf="isLoading || isLoadingOptions"></app-loading-spinner>


<div *ngIf="!isLoading && !isLoadingOptions" class="row">
  <div class="col sectionBox p-20" style="height: 100%; margin: 10px">
    <app-loading-spinner *ngIf="isLoadingOptions"></app-loading-spinner>

    <div *ngIf="!isLoadingOptions">
      <div class="all-tasks-container" (click)="toggleGroupsDropdown()">
        <h4 class="all-tasks-title ">Groups</h4>
        <i [ngClass]="isGroupsDropdownVisible ? 'fa-chevron-up' : 'fa-chevron-down'" class="fas float-end"></i>
      </div>
      <div *ngIf="isGroupsDropdownVisible">
        <input type="text" [(ngModel)]="groupSearch" class="form-control" placeholder="Group search.." (input)="filterGroups()">
        <div class="form-check" style="margin-top: 10px;">
          <input class="form-check-input" type="checkbox" id="selectAll" [checked]="isAllGroupsOptionsSelected()"
            (change)="toggleSelectAll($event.target.checked, 'groups')">
          <label class="form-check-label" for="selectAll">Select All</label>
        </div>

        <div class="form-check">
          <input [(ngModel)]="this.filterGroupIdsIncludeUnassigned" [disabled]="isLoadingOptions || isLoading"
            [ngModelOptions]="{standalone: true}" name="groupss_unassigned" class="form-check-input" type="checkbox"
            id="groups_unassigned"  (change)="this.filterGroupIdsIncludeUnassigned.toggle(x.id); saveFilterStates() ">
          <label class="form-check-label" for="agents_unassigned">(Unassigned)</label>

        </div>

        <div *ngFor="let x of filteredGroups" class="form-check">
          <input [ngModel]="this.filterGroupIds.isSelected(x.id)" [disabled]="isLoadingOptions || isLoading"
            (change)="this.filterGroupIds.toggle(x.id); saveFilterStates()" [ngModelOptions]="{standalone: true}"
            name="{{x.id}}_{{x.name}}" class="form-check-input" type="checkbox" id="{{x.id}}_{{x.name}}">
          <label class="form-check-label" for="{{x.id}}_{{x.name}}">{{x.name}}</label>
        </div>
      </div>


      <hr class="m-t-20 m-b-20">

      <div class="all-tasks-container" (click)="toggleAgentsDropdown()">
        <h4 class="all-tasks-title ">Agents</h4>
        <i [ngClass]="isAgentsDropdownVisible ? 'fa-chevron-up' : 'fa-chevron-down'" class="fas float-end"></i>
      </div>
      <div *ngIf="isAgentsDropdownVisible">
        <input type="text" [(ngModel)]="agentSearch" class="form-control" placeholder="Agent search.." (input)="filterAgent()">
        <div class="form-check" style="margin-top: 10px;">
          <input class="form-check-input" type="checkbox" id="selectAll" [checked]="isAllAgentsOptionsSelected()"
            (change)="toggleSelectAll($event.target.checked, 'agents')">
          <label class="form-check-label" for="selectAll">Select All</label>
        </div>

        <div class="form-check">
          <input [(ngModel)]="this.filterAgentIdsIncludeUnassigned" [disabled]="isLoadingOptions || isLoading"
            [ngModelOptions]="{standalone: true}" name="agents_unassigned" class="form-check-input" type="checkbox"
            id="agents_unassigned"  (change)="this.filterAgentIdsIncludeUnassigned.toggle(x.id); saveFilterStates() ">
          <label class="form-check-label" for="agents_unassigned">(Unassigned)</label>

        </div>

        <div *ngFor="let x of filteredAgents" class="form-check">
          <input [ngModel]="this.filterAgentIds.isSelected(x.id)" [disabled]="isLoadingOptions || isLoading"
            (change)="this.filterAgentIds.toggle(x.id); saveFilterStates() " [ngModelOptions]="{standalone: true}"
            name="{{x.id}}_{{x.name}}" class="form-check-input" type="checkbox" id="{{x.id}}_{{x.name}}">
          <label class="form-check-label" for="{{x.id}}_{{x.name}}">{{x.name}}</label>
        </div>
      </div>
    </div>
  </div>
  <div class="col-md-10 sectionBox p-20" style="height: 100%; margin: 10px">
    <div class="table-responsive">
      <table class="table align-middle tableStyle">
        <thead>
        <tr>
          <th class="px-2" style="font-size: 16px">Task ID</th>
          <th class="px-2" style="font-size: 16px">Task</th>
          <th class="px-2" style="font-size: 16px">Instructions</th>
          <th class="px-2" style="font-size: 16px">Group</th>
          <th class="px-2" style="font-size: 16px">Assigned To</th>
          <th *ngIf="!requestId" class="px-2" style="font-size: 16px; min-width: 100px;">Ticket #</th>
          <!-- <th class="px-2" style="font-size: 16px">Status</th> -->
        </tr>
        </thead>
        <tbody>
          <ng-container *ngFor="let task of this.allTasksFiltered(); let i = index" >
            <tr class="taskList" style="border-bottom: 5px solid #ffffff;">
              <td class="title" style="min-width: 125px">T{{task.Id}}</td>
              <td class="title" style="min-width: 125px">{{task.TaskName}}</td>
              <td class="description" style="min-width: 200px;word-wrap:break-word;"><span [innerHTML]="task.Instructions"></span></td>
              <td style="padding: 10px;">
                <ng-select [items]="options.group" bindLabel="name" bindValue="id" [(ngModel)]="task.GroupId"
                (ngModelChange)="filterAgents(task,i)" [clearable]="false" [disabled]="false">
              </ng-select></td>
              <td style="padding: 10px; border-left: solid lightgray;min-width: 140px;">
                <ng-select [items]="options.agent" bindLabel="name" bindValue="id" [(ngModel)]="task.AgentId" (focus)="filteragentsOnload(task)"
                (ngModelChange)="setAgent(task.Id, task.AgentId)" [clearable]="false" [disabled]="!task.GroupId && isLoading">
              </ng-select></td>
              <td style="border-left: solid lightgray;"> <span *ngIf="!task.RequestId">n/a</span> <a *ngIf="task.RequestId" [routerLink]="['/agent/request/', task.RequestId]"
                routerLinkActive="active">#{{task.LocalId}}</a></td>
                <!-- <td style="min-width: 115px;">
                  <div *ngIf="task.SendCompletionEmail && !task.Completed" class="divLink">
                    <i (click)="task.ShowCompletionEmailDialog = true" class="fal fa-hourglass sdesk-check"></i>
                    <span style="margin-left: 5px" *ngIf="!task.Completed">Pending</span>
                  </div>
                  <div *ngIf="!task.Completed && !task.SendCompletionEmail" class="divLink">
                    <i (click)="markComplete(task.Id)" class="fal fa-hourglass sdesk-check"></i>
                    <span style="margin-left: 5px" *ngIf="!task.Completed">Pending</span>
                  </div>
                  <div *ngIf="task.Completed" class="divLink">
                    <i (click)="markPending(task.Id)" class="fas fa-check sdesk-check completed divLink"></i>
                    <span style="margin-left: 5px" *ngIf="!task.Completed">Complete</span>
                  </div>
                </td> -->
                <!-- <td>
                  <div style="width: 50px">
                    <i (click)="delete(task.Id)" class="fas fa-trash sdesk-check divLink float-end"></i>
                  </div>
                </td> -->
                </tr>
                <tr *ngIf="task.SendCompletionEmail && task.ShowCompletionEmailDialog">
                  <td colspan="7">
                    <p style="
                    background-color: #83a383;
                    color: white;
                    padding: 5px;
                    border-radius: 10px;
                    margin-bottom: 10px;">In order to mark this task as complete. An e-mail must be sent to the Requester:</p>
                        <quill-editor [(ngModel)]="task.CompletionEmailContents" [styles]="{height: '100px'}"
                          placeholder=""></quill-editor>

                        <div class="btn btn-primary btn-sm float-end"
                          (click)="sendEmail(task.Id, task.CompletionEmailContents, task.RequestId)" style="width: 200px">Send & Mark as
                          Complete</div>
                  </td>
                </tr>
          </ng-container>
        </tbody>
      </table>
    </div>

  </div>
</div>
