<app-modal [modalSize]="'modal-small'">
  <div class="modal-header">
    <h5 class="modal-title" id="exampleModalLabel"><i class="fas fa-eye m-r-5"></i> Followers</h5>
  </div>
  <div class="modal-body">
    <div class="table-responsive">
      <table class="table align-middle tableStyle">
        <tbody>
        <tr *ngFor="let follower of this.data">
          <td>{{follower.Name}} ({{follower.EmailAddress}})</td>
        </tr>
        </tbody>
      </table>
    </div>

  </div>
  <div class="modal-footer">
    <div (click)="closeModal()" class="btn btn-cancel" data-bs-dismiss="modal">Close</div>
  </div>
</app-modal>
