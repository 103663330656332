<app-modal [modalSize]="'modal-small'" *ngIf="!isLoading">
  <div class="modal-header">
    <h5 class="modal-title">{{this.loadedId ? 'Edit' : 'Create'}} Topic</h5>
  </div>
  <div class="modal-body">

    <div class="row">
      <div class="col-sm-2 formHeader alignMiddle p-10">
        Subject <span class="text-danger">*</span>
      </div>
      <div class="col-sm p-10">
        <input [(ngModel)]="loadedData.TopicSubject" type="text" class="form-control" id="Name" placeholder="Name" autocomplete="off">

      </div>
    </div>

  </div>
  <div class="modal-footer">
    <button (click)="save()" [disabled]="!loadedData.TopicSubject" class="btn btn-primary">Save</button>
    <button (click)="close()" class="btn btn-cancel">Cancel</button>
  </div>
</app-modal>
