<div class="col">
  <div class="col-md-9">
    <h3 class="p-20 p-b-0">All Solutions</h3>
  </div>
  <div class="col-md-7 mt-3 ml-20">
    <div class="wrap" style="margin-left: 20px; display: flex; align-items: center;">

      <!-- Dropdown for Search Criteria -->
      <div class="dropdown col-md-3">
        <button class="btn btn-secondary dropdown-toggle" type="button" (click)="toggleDropdown('criteria')" aria-haspopup="true" aria-expanded="isDropdownOpenCriteria" style="border: 1px solid #b4b4b4; border-right: none; background-color: white; color: black; width: 100%">
          <i class="fa fa-search" style="color: #FFBD4C; border-radius: 1px;" aria-hidden="true"></i>
          {{ selectedSearchCriteria }}
        </button>
        <div *ngIf="isDropdownOpenCriteria" class="dropdown-menu show" aria-labelledby="dropdownMenuButton" style="border: 1px solid #b4b4b4; width: 200px; max-height: 200px; overflow-y: auto;">
          <a class="dropdown-item" (click)="selectSearchCriteria('category')" style="cursor: pointer;">Category</a>
          <a class="dropdown-item" (click)="selectSearchCriteria('tag')" style="cursor: pointer;">Tag</a>
          <!-- <a class="dropdown-item" (click)="selectSearchCriteria('bodyContent')" style="cursor: pointer;">Body Content</a> -->
          <a class="dropdown-item" (click)="selectSearchCriteria('articleName')" style="cursor: pointer;">Article Name</a>
        </div>
      </div>

      <!-- Search bar -->
      <div class="search" style="flex-grow: 1; flex-shrink: 0; min-width: 300px; display: flex; align-items: center;">
        <input type="text" [(ngModel)]="searchTerm" (input)="search()" class="searchTerm" placeholder="Search by selected criteria..." style="width: 100%; border: 1px solid #b4b4b4; border-right: none; height: 36px; padding: 5px; border-radius: 5px 0 0 5px;">
        <button type="submit" class="searchButton" style="width: 40px; height: 36px; border: 1px solid #b4b4b4; text-align: center; color: #b4b4b4; border-radius: 0 5px 5px 0; cursor: pointer; font-size: 18px;">
          <i class="fa fa-search"></i>
        </button>
      </div>

      <!-- Dropdown for All -->
      <div class="dropdown col-md-3">
        <button class="btn btn-secondary dropdown-toggle" type="button" (click)="toggleDropdown('all')" aria-haspopup="true" aria-expanded="isDropdownOpenAll" style="border: 1px solid #b4b4b4; border-right: none; background-color: white; color: black; width: 100%">
          <i class="fa fa-folder-open-o" style="color: #FFBD4C; border-radius: 1px;" aria-hidden="true"></i>
          {{ selectedCategoryName }}
        </button>
        <div *ngIf="isDropdownOpenAll" class="dropdown-menu show" aria-labelledby="dropdownMenuButton" style="border: 1px solid #b4b4b4; width: 200px; max-height: 200px; overflow-y: auto;">
          <input type="text" [(ngModel)]="categorySearch" (input)="filterCategories()" placeholder="Search categories" style="width: 100%; padding: 5px;">
          <a class="dropdown-item" (click)="filterByCategory(null)" style="cursor: pointer;">All Categories</a>
          <a class="dropdown-item" *ngFor="let category of filteredCategories" (click)="filterByCategory(category.Id)" style="cursor: pointer;">{{ category.Name }}</a>
        </div>
      </div>

      <!-- Dropdown for Tags -->
      <div class="dropdown" style="margin-left: 40px;">
        <button class="btn btn-secondary dropdown-toggle w-full md:w-auto" type="button" (click)="toggleDropdown('tags')" aria-haspopup="true" [attr.aria-expanded]="isDropdownOpenTags" style="border: 1px solid #b4b4b4; background-color: white; color: black; cursor: pointer;">
          {{ selectedTag }}
          <span style="background-color: #FFBD4C; color: white; border-radius: 50%; padding: 5px 10px; margin-left: 10px;">{{filteredTags.length}}</span>
        </button>
        <div *ngIf="isDropdownOpenTags" class="dropdown-menu show" aria-labelledby="dropdownMenuButtonTags" style="border: 1px solid #b4b4b4; width: 200px; max-height: 200px; overflow-y: auto;">
          <input type="text" [(ngModel)]="tagSearch" (input)="filterTags()" placeholder="Search tags" style="width: 100%; padding: 5px;">
          <a class="dropdown-item" *ngFor="let tag of filteredTags" (click)="filterByTag(tag.Tag)" style="cursor: pointer;">{{ tag.Tag }}</a>
        </div>
      </div>

      <!-- Sort Dropdown -->
      <div class="Newest" style="margin-left: 60px; position: relative;">
        <button class="btn btn-secondary dropdown-toggle" type="button" (click)="toggleSortDropdown()" aria-haspopup="true" aria-expanded="isSortDropdownOpen" style="border-radius: 5px; background-color: white; color: black; cursor: pointer;">
          Sort
        </button>
        <div *ngIf="isSortDropdownOpen" class="dropdown-menu show" style="position: absolute; top: 100%; left: 0; z-index: 1000;">
          <a class="dropdown-item" (click)="sortArticles('newest')">Newest</a>
          <a class="dropdown-item" (click)="sortArticles('oldest')">Oldest</a>
          <a class="dropdown-item" (click)="sortArticles('az')">A-Z</a>
          <a class="dropdown-item" (click)="sortArticles('za')">Z-A</a>
        </div>
      </div>

      <!-- Pagination and Page Size Dropdown -->
      <div style="display: inline-flex; align-items: center; justify-content: space-between; margin: 20px;">
        <div style="display: flex; align-items: center;">
          <select id="pageSize" [(ngModel)]="articlesPerPage" (change)="onArticlesPerPageChange(articlesPerPage)" style="padding: 5px 10px; font-size: 14px;">
            <option value="50">50</option>
            <option value="100">100</option>
            <option value="150">150</option>
          </select>
        </div>
        <nav aria-label="Page navigation" style="margin-left: 15px; margin-top: 10px;">
          <ul class="pagination">
            <li class="page-item" [class.disabled]="currentPage === 1">
              <a class="page-link" href="#" (click)="goToFirstPage()">&laquo;&laquo;</a>
            </li>
            <li class="page-item" [class.disabled]="currentPage === 1">
              <a class="page-link" href="#" (click)="goToPreviousPage()">&laquo;</a>
            </li>
            <li class="page-item" *ngFor="let page of pagesToShow(currentPage, totalPages)" [class.active]="currentPage === page">
              <a class="page-link" href="#" (click)="goToPage(page)">{{ page }}</a>
            </li>
            <li class="page-item" [class.disabled]="currentPage >= totalPages">
              <a class="page-link" href="#" (click)="goToNextPage()">&raquo;</a>
            </li>
            <li class="page-item" [class.disabled]="currentPage >= totalPages">
              <a class="page-link" href="#" (click)="goToLastPage()">&raquo;&raquo;</a>
            </li>
          </ul>
        </nav>
      </div>
    </div>
  </div>
</div>

<!-- Articles Section -->
<div *ngIf="showArticles">
  <div *ngFor="let article of filteredArticles" class="articleCard" style="width: 90%; border: 1px solid #ddd; border-radius: 10px; box-shadow: 0 4px 8px rgba(0,0,0,0.1); padding: 20px; margin: 30px; background-color: #fff;">
    <div style="font-weight: bold; text-align: right;" (click)="selectCategoryFromArticle(article.CategoryId)">
      <i class="fa fa-folder-open-o" style="margin-right: 10px; cursor: pointer; color: #FFBD4C;" aria-hidden="true"></i>
      <span style="cursor: pointer;">{{ article?.CategoryName }}</span>
    </div>
    <div [routerLink]="['/solutions/', article.Url]" class="solutionsHomeLink divLink block p-2 hover:bg-gray-100" style="cursor: pointer; font-size: 18px; font-weight: bold; color: black;">
      <i class="fa fa-list" style="background-color:#EBEFF2; color: #355672;"></i>
      {{ article?.Title }}
    </div>
    <!-- <div *ngIf="article?.TruncatedBody" [innerHTML]="article.TruncatedBody"></div>
    <a *ngIf="article?.Body && article.Body.length > 100" [routerLink]="['/solutions/', article.Url]" style="cursor: pointer;">More..</a> -->
    <div *ngIf="article.Tags?.length > 0" class="tags" style="margin-top: 10px;">
      <span *ngFor="let tag of article.Tags | slice:0:5" class="tag" style="display: inline-block; background-color: #EBEFF2; border-radius: 5px; padding: 5px 10px; margin-right: 5px;">{{ tag }}</span>
      <a *ngIf="article.Tags.length > 5" [routerLink]="['/solutions/', article.Url]" style="display: inline-block; border-radius: 5px; padding: 5px 10px; margin-right: 5px; cursor: pointer; color: black;">More..</a>
    </div>
  </div>
</div>

<div *ngIf="!showArticles">
  <div *ngFor="let article of paginatedArticles" class="articleCard" style="width: 90%; border: 1px solid #ddd; border-radius: 10px; box-shadow: 0 4px 8px rgba(0,0,0,0.1); padding: 20px; margin: 30px; background-color: #fff;">
    <div style="font-weight: bold; text-align: right;" (click)="selectCategoryFromArticle(article.CategoryId)">
      <i class="fa fa-folder-open-o" style="margin-right: 10px; cursor: pointer; color: #FFBD4C;" aria-hidden="true"></i>
      <span style="cursor: pointer;">{{ article?.CategoryName }}</span>
    </div>
    <div [routerLink]="['/solutions/', article.Url]" class="solutionsHomeLink divLink block p-2 hover:bg-gray-100" style="cursor: pointer; font-size: 18px; font-weight: bold; color: black;">
      <i class="fa fa-list" style="background-color:#EBEFF2; color: #355672;"></i>
      {{ article?.Title }}
    </div>
    <!-- <p *ngIf="article?.TruncatedBody" [innerHTML]="article.TruncatedBody"></p>
    <a *ngIf="article?.Body && article?.Body.length > 100" [routerLink]="['/solutions/', article.Url]" style="cursor: pointer;">More..</a> -->
    <div *ngIf="article.Tags?.length > 0" class="tags" style="margin-top: 10px;">
      <span *ngFor="let tag of article.Tags | slice:0:5" class="tag" style="display: inline-block; background-color: #EBEFF2; border-radius: 5px; padding: 5px 10px; margin-right: 5px;">{{ tag }}</span>
      <a *ngIf="article.Tags.length > 5" [routerLink]="['/solutions/', article.Url]" style="display: inline-block; border-radius: 5px; padding: 5px 10px; margin-right: 5px; cursor: pointer; color: black;">More..</a>
    </div>
  </div>
</div>
