import { Component, OnInit } from '@angular/core';
import { SearchApiService } from "../../services/api/search/search-api.service";

@Component({
  selector: 'app-search',
  templateUrl: './search.component.html',
  styleUrls: ['./search.component.css']
})
export class SelfServiceSearchComponent implements OnInit {

  public isLoading = false;
  public searchTerm = '';
  public searchResults;
  public sortColumn: string = '';
  public sortOrder: string = 'asc';
  public searchType = [
    {
      "value":"LocalRequestId",
      "name":"Id",
    },
    { "value":"Subject",
      "name":"Subject",
    },
    {
      "value":"RequesterName",
      "name":"Requester",
    },
    {
      "value":"Body",
      "name":"Body",
    }
  ];

    public defaultSearchType = "LocalRequestId"

  constructor(private searchApiService: SearchApiService) { }

  ngOnInit(): void {
  }

  search() {
    this.isLoading = true;
    this.searchApiService.newSearch(this.searchTerm, this.defaultSearchType).then(response => {
      this.searchResults = response;
      this.isLoading = false;
      this.sortResults();
    });
  }

  sortResults() {
    this.searchResults.sort((a, b) => {
      const modifier = this.sortOrder === 'asc' ? 1 : -1;
      if (a[this.sortColumn] < b[this.sortColumn]) return -1 * modifier;
      if (a[this.sortColumn] > b[this.sortColumn]) return 1 * modifier;
      return 0;
    });
  }

  toggleSortOrder(column: string) {
    if (this.sortColumn === column) {
      this.sortOrder = this.sortOrder === 'asc' ? 'desc' : 'asc';
    } else {
      this.sortColumn = column;
      this.sortOrder = 'asc';
    }
    this.sortResults();
  }
}
