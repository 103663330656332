<div class="SDeskSidePanel-GreyBackground"></div>

<app-blade-right size="blade-xlarge" style="">
  <div class="p-10 p-b-0">
    <i (click)="this.close()" class="fas fa-times fa-2x float-end divLink"></i>
  </div>

  <app-request [data]="data"></app-request>

</app-blade-right>


