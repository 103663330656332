<app-loading-spinner *ngIf="isLoading" ></app-loading-spinner>

<div *ngIf="!isLoading" class="table-responsive p-l-20 p-r-20">
  <div (click)="create()" class="btn btn-primary float-end">Create</div>
  <table class="table align-middle tableStyle">
    <thead>
    <tr>
      <th>Azure AD User Sync</th>
      <th>Next Sync (UTC)</th>
      <th>Last Sync (UTC)</th>
      <th>Last Sync Status</th>
      <th style="width: 100px;"></th>

    </tr>
    </thead>
    <tbody>
    <tr *ngFor="let row of this.data ">
      <td><span class="divLink" (click)="edit(row.Id)">{{row.Description}}</span>
      </td>
      <td>{{row.NextSyncDateTime}}</td>
      <td>{{row.LastSyncDateTime}}</td>
      <td>
        <i *ngIf="!row.FailureReason && row.LastSyncDateTime" class="fa fa-check-circle text-success"></i>
        <i *ngIf="row.FailureReason" class="fa fa-exclamation-circle text-danger"></i>
        {{!row.FailureReason && row.LastSyncDateTime ? 'Successful' : row.FailureReason}}
      </td>
      <td>
        <i class="fas fa-cog m-r-20 divLink" (click)="edit(row.Id)"></i>
        <i class="far fa-trash-alt m-r-20 divLink" (click)="delete(row.Id)"></i>
      </td>
    </tr>
    </tbody>
  </table>
</div>
