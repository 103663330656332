<app-modal [modalSize]="'modal-medium'">
    <div *ngIf="!isLoading" class="modal-body">

      <div class="row">
        <div class="col">
          <h5>Customer</h5>
          <!--suppress HtmlFormInputWithoutLabel -->
          <input [(ngModel)]="search.customerSearchTerm" class="form-control" name="searchTerm" placeholder="Search..." autocomplete="off">

          <div class="table-responsive">
            <table class="table align-middle tableStyle">
              <tbody>
              <tr class="divLink" *ngFor="let customer of this.filterData(this.options.Customers, 'Name', search.customerSearchTerm, selectedCustomerId)" [class.sdesk-filter-selected]="selectedCustomerId == customer?.Id" (click)="setCustomer(customer.Id,customer.Name)">
                <td>{{customer?.Name}}</td>
              </tr>
              </tbody>
            </table>
          </div>

          <p class="text-muted">
            {{this.filterData(this.options.Customers, 'Name', search.customerSearchTerm, selectedCustomerId).length}} of {{this.options.Customers.length}} results
          </p>
        </div>

        <div class="col p-l-10">
          <h5>Location</h5>

          <!--suppress HtmlFormInputWithoutLabel -->
          <input [(ngModel)]="search.customerLocationSearchTerm" class="form-control" name="searchTerm" placeholder="Search..." autocomplete="off">

          <div class="table-responsive">
            <table class="table align-middle tableStyle">
              <tbody>
              <tr class="divLink" *ngFor="let location of this.filterData(this.options.CustomerLocations, 'Name', search.customerLocationSearchTerm, selectedCustomerLocationId)" [class.sdesk-filter-selected]="selectedCustomerLocationId == location?.Id" (click)="setCustomerLocation(location.Id,location.Name)">
                <td>{{location?.Name}}</td>
              </tr>
              </tbody>
            </table>
          </div>

          <p class="text-muted">
            {{this.filterData(this.options.CustomerLocations, 'Name', search.customerLocationSearchTerm, selectedCustomerLocationId).length}} of {{this.options.CustomerLocations.length}} results
          </p>
        </div>

        <div class="col p-l-10">
          <h5>Service</h5>

          <!--suppress HtmlFormInputWithoutLabel -->
          <input [(ngModel)]="search.serviceSearchTerm" class="form-control" name="searchTerm" placeholder="Search..." autocomplete="off">

          <div class="table-responsive">
            <table class="table align-middle tableStyle">
              <tbody>
              <tr class="divLink" *ngFor="let service of this.filterData(this.options.Services, 'ServiceName', search.serviceSearchTerm, selectedServiceId)" [class.sdesk-filter-selected]="selectedServiceId == service?.Id" (click)="setService(service.Id, service.CustomerLocationId, service.CustomerId,service.ServiceName)">
                <td>{{service?.ServiceName}}</td>
              </tr>
              </tbody>
            </table>
          </div>

          <p class="text-muted">
            {{this.filterData(this.options.Services, 'ServiceName', search.serviceSearchTerm, selectedServiceId).length}} of {{this.options.Services.length}} results
          </p>
        </div>
      </div>


  </div>
  <div class="modal-footer">
    <div (click)="save()" class="btn btn-primary">Select</div>
    <div (click)="close()" class="btn btn-cancel" data-bs-dismiss="modal">Cancel</div>
  </div>
</app-modal>
