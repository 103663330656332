import { Component, OnInit } from '@angular/core';
import {ApiService} from "../../../../../services/api/api.service";
import {AdminGlobalService} from "../../../../../services/api/admin/admin-global.service";
import {BrandingService} from "../../../../../services/branding/branding.service";
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-admin-global-settings',
  templateUrl: './admin-global-settings.component.html',
  styleUrls: ['./admin-global-settings.component.css']
})
export class AdminGlobalSettingsComponent implements OnInit {

  data;
  isLoading ;
  uploadingAttachmentInProgress = false;
  ticketSettings =
  {
    IsReopenLimitDays:'',
    IsResolveInHours:'',
    ReopenLimitDays:'',
    ResolveInHours:'',
  
  };
  isGlobalTab:boolean = true;
  isTicketsTab:boolean =false;
  tab;

  constructor(private adminGlobalApi: AdminGlobalService, private apiService: ApiService, private brandingService: BrandingService, private route: ActivatedRoute,) {
    this.getData();
    this.getSettingsData();
  }

  ngOnInit(): void {
    this.route.queryParams.subscribe(params => {
      this.tab = params['tab'];
      if (this.tab === 'globalsettings') {
        this.isGlobalTab = true;
        this.isTicketsTab = false;
      }
      if (this.tab === 'ticketsettings') {
        this.isTicketsTab = true;
        this.isGlobalTab = false;  
      }
    })
    this.data = {
      PreferredDateFormat: 1
    }
  }

  preferredDateFormats = [
    {id:1, name:'DD/MM/YYYY'},
    {id:2, name:'MM/DD/YYYY'},
  ];

  updateDayData(Day) {

    if(!this.data[Day].Enabled) {
      this.data[Day].TwentyFour = null;
      this.data[Day].Start = null;
      this.data[Day].Finish = null;
    }

    if(this.data[Day].Enabled && !this.data[Day].TwentyFour) {
      this.data[Day].TwentyFour = null;
      this.data[Day].Start = null;
      this.data[Day].Finish = null;
    }

    if(this.data[Day].Enabled && !this.data[Day].TwentyFour) {
      this.data[Day].Start = '09:00';
      this.data[Day].Finish = '17:00';
    }
  };

  getData(){
    this.isLoading = true;
    this.adminGlobalApi.getData().then(response => {
      this.data = response;
      this.isLoading = false;
    })
  }

  saveData(){
    this.isLoading = true;
    this.adminGlobalApi.updateData(this.data).then(response => {
      this.getData();
      this.isLoading = false;
    })
  }

  onFileSelected(event){
    const file:File = event.target.files[0];
    if (file) {

      this.uploadingAttachmentInProgress = true;

      let fileName = file.name;
      let fileSize = file.size;

      const formData = new FormData();
      formData.append('0', file);

      this.apiService.post('/api/admin/global/updatelogo', formData).then(success => {
        this.brandingService.refreshBranding();
        this.uploadingAttachmentInProgress = false;

      }, error => {
        console.error(error);
      })
    }
  }
  public getSettingsData(): void {
    this.isLoading = true;
    this.adminGlobalApi.getSettingsData().then(response => {
      this.ticketSettings = response;
      this.isLoading = false;
    })
  }
  public saveSettingsData(): void {
    this.isLoading = true;
    let payload = {
      NoOfHours: this.ticketSettings.ResolveInHours,
      IsAutomaticallyResolve: this.ticketSettings.IsResolveInHours,
      NoOfDays: this.ticketSettings.ReopenLimitDays,
      IsDisableReopenTicket: this.ticketSettings.IsReopenLimitDays,
    }
    this.adminGlobalApi.updateSettingsData(payload).then(response => {
      this.getSettingsData();
    })
  }

}
