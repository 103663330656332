import { Component, EventEmitter, Input, Output } from "@angular/core";
import { ToastrService } from "ngx-toastr";
import { ApiAdminIcxService } from "src/app/services/api/admin/admin-icx-api/admin-icx-api.service";
import { OptionsApiService } from "src/app/services/api/options/options-api.service";
import { Dialog } from "src/app/shared/dialog/dialog";

@Component({
  selector: 'app-admin-jira-settings-create-edit-dialog',
  templateUrl: './admin-jira-settings-create-edit-dialog.component.html'
})

export class AdminJiraSettingsComponentDialog implements Dialog {
  @Input() data;
  @Output() remove = new EventEmitter<any>();

  isLoading: boolean = false; // Flag to indicate loading state

  jiraConfigList;
  jiraConfigGroup = {
    JiraProjectId: "",
    JiraIssueTypeId: "",
    GroupName: "",
    is_Active:true
  }

  jiraConfigGroups = [];

  issueType = [];
  requestTypes = [];
  isGroupAdded: boolean;
  isJiraConfigSaved: boolean = false;

  constructor(
    private apiAdminIcxService: ApiAdminIcxService,
    private toastr: ToastrService,
    private apiAdminOptionsService: OptionsApiService
  ) {}

  loadedData: any;

  ngOnInit(): void {
    this.loadedData = {
      JiraUrl: '',
      JiraUsername: '',
      JiraApiKey: ''
    };

    // Load Jira configuration first and chain the other API calls
    this.getJiraConfig().then(() => {
      this.getJiraConfigGroups().then(() => {
        this.getRequestTypes(); // Runs after getJiraConfigGroups finishes
      });
    });

  }


  getRequestTypes() {
    this.isLoading = true;
    this.apiAdminOptionsService.AllRequestTypes().then(response => {
      const defaultOption = { id: -1, name: 'All Request Types' };
      this.requestTypes = [defaultOption, ...response];


       // Filter out request types that already exist in jiraConfigGroups
       this.requestTypes = this.requestTypes.filter(requestType =>
        !this.jiraConfigGroups.some(group =>
          group.GroupName.toLowerCase() === requestType.name.toLowerCase()
        )
      );

      this.isLoading = false; // Loading finished
    }).catch(() => {
      this.isLoading = false; // Handle error and stop loading
    });
  }

  getJiraConfigGroups(): Promise<void> {
    this.isLoading = true;
    return new Promise((resolve, reject) => {
      this.apiAdminIcxService.getJiraConfigGroup().then(response => {
        this.jiraConfigGroups = []; // Clear the array before populating it

        // Loop over each group in the response data
        response["data"].forEach(groupData => {
          const jiraGroup = {
            id:  groupData.id,
            GroupName: groupData.group_name,
            JiraProjectId: groupData.jira_project_id,
            JiraIssueTypeId: groupData.jira_issue_type_id,
            is_Active:groupData.is_active
          };

          // Push each group to the jiraConfigGroups array
          this.jiraConfigGroups.push(jiraGroup);

          // Load the issue types for each Jira project
          this.getIssueType(jiraGroup.JiraProjectId);
        });

        this.isLoading = false; // Loading finished
        resolve(); // Resolve the promise when done
      }).catch(() => {
        this.isLoading = false; // Handle error and stop loading
        reject(); // Reject the promise on error
      });
    });
  }


  getJiraConfig(): Promise<void> {
    this.isLoading = true;
    return this.apiAdminIcxService.getJiraConfig().then(response => {
      this.jiraConfigList = response["data"][0];
      this.loadedData.JiraUrl = response["data"][0]["config"]["jira_url"];
      this.loadedData.JiraUsername = response["data"][0]["config"]["user_name"];
      this.isJiraConfigSaved = true;
      this.isLoading = false;
    }).catch(() => {
      this.isLoading = false;
    });
  }

  getIssueType(projectId: string) {
    this.isLoading = true;

    // Check if jiraConfigList is populated before proceeding
    if (!this.jiraConfigList || !this.jiraConfigList.project) {
      console.error("JiraConfigList is undefined or does not contain 'project'");
      this.isLoading = false;
      return;
    }

    const project = this.jiraConfigList.project.find(p => p.id === projectId);

    if (project) {
      this.issueType = project.issue_types;
      if (this.jiraConfigGroup.JiraIssueTypeId) {
        const selectedIssueType = this.issueType.find(type => type.id === this.jiraConfigGroup.JiraIssueTypeId);
        if (!selectedIssueType) {
          this.jiraConfigGroup.JiraIssueTypeId = null;
        }
      }
    } else {
      this.issueType = [];
      this.jiraConfigGroup.JiraIssueTypeId = null;
    }

    this.isLoading = false;
  }


  save() {
    if (this.isFormValid()) {
      this.isLoading = true;
      this.toastr.info("Configuring Jira");
      this.apiAdminIcxService.createJiraConfig(this.loadedData).then(response => {
        this.toastr.success('Jira configuration saved successfully!');
        this.isJiraConfigSaved = true;
        this.getJiraConfig();
        this.isLoading = false; // Save finished
      }).catch(() => {
        this.toastr.error('An error occurred while saving the configuration.');
        this.isLoading = false; // Handle error and stop loading
      });
    } else {
      this.toastr.error('Please fill out all fields before saving.');
    }
  }

  updateGroup(id){
    this.isLoading = true;
    var  group = this.jiraConfigGroups.find(p => p.id === id);
    this.apiAdminIcxService.updateJiraConfigGroup(id, group).then(response=>{
      this.toastr.success('Jira configuration group updated successfully!');
      this.getJiraConfig();
      this.remove.emit("Saved");
      this.isLoading = false; // Save finished
      }).catch(() => {
        this.toastr.error('An error occurred while updating the configuration.');
        this.isLoading = false; // Handle error and stop loading
      });
  }

  saveGroup() {
    this.isLoading = true;

    // Take the last value from the jiraConfigGroups array
    if (this.jiraConfigGroups.length > 0) {
      const lastGroup = this.jiraConfigGroups[this.jiraConfigGroups.length - 1];

      // Assign lastGroup values to jiraConfigGroup
      this.jiraConfigGroup = {
        JiraProjectId: lastGroup.JiraProjectId,
        JiraIssueTypeId: lastGroup.JiraIssueTypeId,
        GroupName: lastGroup.GroupName,
        is_Active:lastGroup.is_Active
      };
    }

    // Log the current state of jiraConfigGroups for debugging
    console.log(this.jiraConfigGroups);

    this.apiAdminIcxService.createJiraConfigGroup(this.jiraConfigGroup).then(response => {
      this.toastr.success('Jira configuration group saved successfully!');

      // Optionally, you may want to refresh the jiraConfigGroups after saving
      this.getJiraConfigGroups(); // Refresh the groups list, if needed

      // Emit the success message
      this.remove.emit("Saved");
      this.isLoading = false; // Save finished
    }).catch(() => {
      this.toastr.error('An error occurred while saving the group.');
      this.isLoading = false; // Handle error and stop loading
    });
  }

  activeDisableGroup(id){
    this.isLoading = true;
    var  group = this.jiraConfigGroups.find(p => p.id === id);
    this.apiAdminIcxService.activeDisableJiraConfigGroup(id,group).then(response=>{
      this.toastr.success(`Jira configuration group ${group.is_Active ? 'disabled' : 'activated'} successfully!`);
      this.getJiraConfig();
      this.remove.emit("Saved");
      this.isLoading = false; // Save finished
      }).catch(() => {
        this.toastr.success(`An error occurred while ${group.is_Active ? 'disabled' : 'activated'} the configuration.`);
        this.toastr.error('An error occurred while deleting the configuration.');
        this.isLoading = false; // Handle error and stop loading
      });
  }


  addGroup() {

      // Push the current group to the jiraConfigGroups array
      this.jiraConfigGroups.push({...this.jiraConfigGroup});

      // Set flag to true to hide "Add Group" and show "Save Group"
      this.isGroupAdded = true;

      // Optionally reset the form if needed
      this.jiraConfigGroup = {
        JiraProjectId: "",
        JiraIssueTypeId: "",
        GroupName: "",
        is_Active:true
      };
  }
   // Validation function to ensure group form is filled
   // Validation function to ensure group form is filled
private isGroupFormValid(): boolean {
  // Ensure all required fields are present and not empty
  return (
    this.jiraConfigGroup.GroupName.trim().length > 0 &&
    this.jiraConfigGroup.JiraProjectId.trim().length > 0 &&
    this.jiraConfigGroup.JiraIssueTypeId.trim().length > 0
  );
}

  close() {
    this.remove.emit(null);
  }

  // Type guard to check if the value is a string
  private isNonEmptyString(value: any): boolean {
    return typeof value === 'string' && value.trim().length > 0;
  }

  // Check if all fields are filled
  private isFormValid(): boolean {
    return Object.values(this.loadedData).every(value => this.isNonEmptyString(value));
  }
}
