import { Injectable } from '@angular/core';
import {ApiService} from "../../api.service";

@Injectable({
  providedIn: 'root'
})
export class AdminAuditLogApiService {

  apiUri = '/api/admin/auditlog'

  constructor(private apiService: ApiService) {

  }

  listAll(page = null, perPage = null) {
    let apiUriWithParams = this.apiUri;

    if (page !== null && perPage !== null) {
      apiUriWithParams += `?page=${page}&perPage=${perPage}`;
    }

    return this.apiService.get(apiUriWithParams);
  }

  search(searchTerm, searchType, page=null, perPage = null){
    let apiUriWithParams = `${this.apiUri}/search`;
    if (page !== null && perPage !== null) {
      apiUriWithParams += `?page=${page}&perPage=${perPage}`;
    }
    return this.apiService.post(apiUriWithParams, {"SearchTerm":searchTerm, "SearchType":searchType});
  }
}
