import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {Dialog} from "../../../../../shared/dialog/dialog";
import {RequestMajorIncidentService} from "../../../../../services/api/request/request-major-incident/request-major-incident.service";
import {SelectionModel} from "@angular/cdk/collections";

@Component({
  selector: 'app-major-incident-link-services-modal',
  templateUrl: './major-incident-link-services-modal.component.html',
  styleUrls: ['./major-incident-link-services-modal.component.css']
})
export class MajorIncidentLinkServicesModalComponent implements Dialog {

  @Input() data;
  @Output() remove = new EventEmitter<any>();

  public availableServices = [];
  public loading = true;

  public search = '';

  public selected = new SelectionModel(
    true,   // multiple selection or not
    [] // initial selected values
  );

  constructor(private majorIncidentApi: RequestMajorIncidentService) {
  }

  ngOnInit(): void {
    this.majorIncidentApi.listServices().then(response => {
      this.availableServices = response
      this.loading = false;
    });
  }

  returnFilteredServices() {
    return this.availableServices.filter(service => {
      return (String(service.Name).toLowerCase()).includes(this.search.toLowerCase());
    })
  }

  checkboxIsAllSelected() {
    return this.selected.selected.length == (this.availableServices).length;
  }

  checkboxToggleAll() {
    if (this.checkboxIsAllSelected()) {
      this.returnFilteredServices().forEach(request => {
        this.selected.deselect(request.Id);
      })
    } else {
      this.returnFilteredServices().forEach(request => {
        this.selected.select(request.Id);
      })
    }
  }

  closeModal() {
    this.remove.emit(null);
  }

  save() {
    this.majorIncidentApi.linkServices(this.data.requestId, this.selected.selected).then(response => {
      this.remove.emit("Success");
    })
  }
}
