
import {Injectable} from '@angular/core';
import {ApiService} from "../../api.service";

@Injectable({
  providedIn: 'root'
})
export class ApiAdminCustomerPortalsService {

  portalApiUrl = '/api/admin/customerportal'
  categoryApiUrl = '/api/admin/customerportal/knowledgebase/category'
  articleApiUrl = '/api/admin/customerportal/knowledgebase/article'

  constructor(private apiService: ApiService) {
  }

  // Portal API's

  listAll() {
    return this.apiService.get(this.portalApiUrl);
  }

  getPortal(id) {
    return this.apiService.get(`${this.portalApiUrl}/${id}`);
  }

  getDefaultLogo(){
    return this.apiService.get(`${this.portalApiUrl}/defaultlogo`);
  }

  deletePortal(id) {
    return this.apiService.delete(`${this.portalApiUrl}/${id}`);
  }

  createPortal(data) {
    return this.apiService.post(this.portalApiUrl, data);
  }

  updatePortal(id, data) {
    return this.apiService.put(`${this.portalApiUrl}/${id}`, data);
  }

  isHostNameAvailable(hostName) {
    return this.apiService.get(`${this.portalApiUrl}/checkhostname/${hostName}`);
  }


  // Category API's

  listAllCategories() {
    return this.apiService.get(this.categoryApiUrl);
  }

  getCategory(id) {
    return this.apiService.get(`${this.categoryApiUrl}/${id}`);
  }

  deleteCategory(id) {
    return this.apiService.delete(`${this.categoryApiUrl}/${id}`);
  }

  createCategory(data) {
    return this.apiService.post(this.categoryApiUrl, data);
  }

  updateCategory(id, data) {
    return this.apiService.put(`${this.categoryApiUrl}/${id}`, data);
  }


  // Article API's

  listAllArticles() {
    return this.apiService.get(this.articleApiUrl);
  }

  getArticle(id) {
    return this.apiService.get(`${this.articleApiUrl}/${id}`);
  }

  deleteArticle(id) {
    return this.apiService.delete(`${this.articleApiUrl}/${id}`);
  }

  createArticle(data) {
    return this.apiService.post(this.articleApiUrl, data);
  }

  updateArticle(id, data) {
    return this.apiService.put(`${this.articleApiUrl}/${id}`, data);
  }


}



