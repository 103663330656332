import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {OptionsApiService} from "../../services/api/options/options-api.service";
import {Dialog} from "../dialog/dialog";

@Component({
  selector: 'app-customer-service-search-modal',
  templateUrl: './customer-service-search-modal.component.html',
  styleUrls: ['./customer-service-search-modal.component.css']
})
export class CustomerServiceSearchModalComponent implements Dialog {

  @Input() data;
  @Output() remove = new EventEmitter<any>();
  isLoading = true;

  search = {
    customerSearchTerm: "",
    customerLocationSearchTerm: "",
    serviceSearchTerm: ""
  }

  selectedCustomerId;
  selectedCustomerName;
  selectedCustomerLocationId;
  selectedCustomerLocationName;
  selectedServiceId;
  selectedServiceName;

  options;

  constructor(private optionsApi: OptionsApiService) {

  }

  ngOnInit(): void {
    console.log("customer details",this.data)
    this.selectedCustomerId = this.data.customerId ?? null;
    this.selectedCustomerName = this.data.customerName ?? null;
    this.selectedCustomerLocationId = this.data.customerLocationId ?? null;
    this.selectedCustomerLocationName = this.data.cusLocationName ?? null;
    this.selectedServiceId = this.data.serviceId ?? null;
    this.selectedServiceName = this.data.serviceName ?? null;

    this.refreshData();
  }

  refreshData(refreshSilently = false) {
    if (!refreshSilently) {
      this.isLoading = true;
    }
    this.optionsApi.ServiceSearchOptions(this.selectedCustomerId, this.selectedCustomerLocationId).then(response => {
      this.options = response;
      this.isLoading = false;
    })
  }

  setCustomer(customerId,customerName) {
    this.selectedCustomerName = customerName;

    if (customerId == this.selectedCustomerId) {
      return;
    }

    this.selectedCustomerId = customerId;
    this.selectedCustomerLocationId = null;
    this.selectedServiceId = null;

    this.refreshData(true);
  }

  setCustomerLocation(customerLocationId,customerLocationName) {
    this.selectedCustomerLocationName=customerLocationName;
    if (customerLocationId == this.selectedCustomerLocationId) {
      return;
    }

    this.selectedCustomerLocationId = customerLocationId;
    this.selectedServiceId = null;

    this.refreshData(true);
  }

  setService(serviceId, customerLocationId, customerId, serviceName) {
    this.selectedServiceName = serviceName;
    if (serviceId == this.selectedServiceId) {
      return;
    }

    this.selectedServiceId = serviceId;
    this.selectedCustomerLocationId = customerLocationId;
    this.selectedCustomerId = customerId;

    this.refreshData(true);
  }

  // getValueById(array, id, property){
  //   return array.find(item => item.Id == id)?.[property];
  // }

  filterData(array, searchProperty, searchTerm, currentSelectedId) {

    let filteredData = array;

    if (searchTerm) {
      filteredData = filteredData.filter(row => {
        return row[searchProperty].toString().toLocaleLowerCase().includes(searchTerm.toLocaleLowerCase()) && row.Id !== currentSelectedId
      })
    } else {
      // Remove Selected Row
      filteredData = filteredData.filter(row => row.Id !== currentSelectedId)
    }
    // Select top 10
    filteredData = filteredData.slice(0, currentSelectedId ? 9 : 10);

    // Add selected row back in so its always shown
    if (currentSelectedId) {
      filteredData.unshift(array.find(item => item.Id == currentSelectedId));
    }

    return filteredData;
  }

  close() {
    this.remove.emit(null);
  }

  save() {
    console.log("1",this.selectedCustomerName,"2",this.selectedCustomerLocationName,"3",this.selectedServiceName)
    const dataToReturn = {
      customerId: this.selectedCustomerId,
      customerName: this.selectedCustomerName,
      customerLocationId: this.selectedCustomerLocationId,
      cusLocationName:this.selectedCustomerLocationName,
      serviceId: this.selectedServiceId,
      serviceName:this.selectedServiceName
    }

    this.remove.emit(dataToReturn);
  }

}
