import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';

@Component({
  selector: 'app-custom-field-dropdown',
  templateUrl: './custom-field-dropdown.component.html',
  styleUrls: ['./custom-field-dropdown.component.css']
})
export class CustomFieldDropdownComponent implements OnInit {

  @Input() customFieldOptions:[any];
  @Input() customFieldId;
  @Input() selectedLevelValues;
  @Input() isDisabled = false;
  @Output() optionOutput = new EventEmitter<any>();

  public level1SelectedId;
  public level2SelectedId;
  public level3SelectedId;

  constructor() { }

  ngOnInit(): void {
    this.level1SelectedId = this.selectedLevelValues.Level1SelectedId;
    this.level2SelectedId = this.selectedLevelValues.Level2SelectedId;
    this.level3SelectedId = this.selectedLevelValues.Level3SelectedId;
  }

  getDropdownOptions(level, parentId){
    let returnOptions = this.customFieldOptions;
    return returnOptions.filter(option => option.Level == level && option.ParentCustomFieldId == parentId)
  }

  onChange(changedLevel){

    console.log("onChange:"+changedLevel)

    if(changedLevel == 1){
      this.level2SelectedId = null;
      this.level3SelectedId = null;
    }
    if(changedLevel == 2){
      this.level3SelectedId = null;
    }
    this.sendChangeToParent();
  }

  sendChangeToParent(){
    
    const isCompleted = this.level1SelectedId && (!this.getDropdownOptions(2, this.level1SelectedId).length || this.level2SelectedId) && (!this.getDropdownOptions(3, this.level2SelectedId).length || this.level3SelectedId);
    this.optionOutput.emit({
      customFieldId: this.customFieldId,
      selected: this.level3SelectedId ?? this.level2SelectedId ?? this.level1SelectedId,
      isCompleted
    });
  }
}
