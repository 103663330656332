

import {Component, EventEmitter, Input, OnInit, Output, ViewContainerRef} from '@angular/core';
import {Dialog} from "../../../../../shared/dialog/dialog";
import {ApiAdminCustomerPortalsService} from "../../../../../services/api/admin/admin-customer-portal/admin-customer-portal.service";
import {ApiService} from "../../../../../services/api/api.service";
import {ModalConfirmationComponent} from "../../../../../shared/modal-confirmation/modal-confirmation.component";
import {DialogService} from "../../../../../services/dialog-service/dialog.service";

@Component({
  selector: 'app-admin-customer-portal-create-edit-dialog',
  templateUrl: './admin-customer-portal-create-edit-dialog.component.html'
})

export class AdminCustomerPortalsComponentDialog implements Dialog {

  @Input() data;
  @Output() remove = new EventEmitter<any>();

  isLoading = true;
  loadedId = null;

  editingHostName;
  hostNameAvailable;

  defaultLogoUrl;

  loadedData;

  constructor(
    private apiAdminCustomerPortalsService: ApiAdminCustomerPortalsService,
    private apiService: ApiService,
    private dialogService: DialogService,
    private viewReference: ViewContainerRef
  ) {}

  ngOnInit(): void {
    if(this.data !== null){
      this.loadedId = this.data;
      this.getData();

      this.editingHostName = false;
      this.hostNameAvailable = true;
    } else {

      this.apiAdminCustomerPortalsService.getDefaultLogo().then(response => {

        this.defaultLogoUrl = response.LogoUrl;

        // Load Default Data
        this.loadedData = {
          PortalDescription: '',
          HostName: '',
          IsHostNameVerified: null,
          HostNameSetupInstructions: '',
          LogoUrl: response.LogoUrl,
          RequireLogin: 0,
          LoadMyTicketsByDefault: 0,
          ShowKnowledgeBase: 0
        }

        this.editingHostName = true;
        this.hostNameAvailable = false;

        this.isLoading = false;

      })

    }
  }

  editHostName(){
    this.editingHostName = true;
    this.hostNameAvailable = null;
  }

  checkHostNameAvailable(){
    this.apiAdminCustomerPortalsService.isHostNameAvailable(this.loadedData.HostName).then(data => {
      this.hostNameAvailable = data.IsAvailable
    })
  }

  deletePortal(){
    this.dialogService.createDialog(ModalConfirmationComponent, 'Are you sure?', this.viewReference)
      .then(confirmed => {
        this.apiAdminCustomerPortalsService.deletePortal(this.loadedId).then(
          response => {
            // Close the Dialog and emit a value. If a value is emited it will refresh the parent.
            this.remove.emit("Deleted")
          })
      }, cancelled => null)
  }

  uploadLogo(event){
    const file:File = event.target.files[0];
    if (file) {

      let fileName = file.name;
      let fileSize = file.size;

      const formData = new FormData();
      formData.append('0', file);

      this.apiService.post('/api/admin/customerportal/uploadLogo', formData).then(success => {
        this.loadedData.LogoUrl = success.LogoUrl;
      }, error => {
        console.error(error);
      })
    }
  }

  resetLogoToDefault(){
    this.loadedData.LogoUrl = this.defaultLogoUrl;
  }

  getData(){
    this.isLoading = true;
    this.apiAdminCustomerPortalsService.getPortal(this.loadedId).then(response => {
      this.loadedData = response;
      this.isLoading = false;
    })
  }

  close(){
    this.remove.emit(null);
  }

  save(){
    if(this.loadedId){
      this.apiAdminCustomerPortalsService.updatePortal(this.loadedId, this.loadedData).then(response => {
        this.remove.emit("Saved");
      })
    } else {
      this.apiAdminCustomerPortalsService.createPortal(this.loadedData).then(response => {
        this.remove.emit("Saved");
      })
    }

  }
}
