<div class="table-responsive sectionBox">
  <table class="table align-middle tableStyle">
    <thead>
    <tr>
      <th>Customer Name</th>
      <th>Sub Domain</th>
      <th>Active</th>
      <th>Users</th>
      <th>Agents</th>
      <th>Tickets</th>
      <th>Assets</th>

      <th>Free Trial Start</th>

    </tr>
    </thead>
    <tbody>
    <tr *ngFor="let instance of this.instanceData">
      <td>{{instance.Name}}</td>
      <td>{{instance.SubDomain}}</td>
      <td>{{instance.Active}}</td>
      <td>{{instance.UserCount}}</td>
      <td>{{instance.AgentCount}}</td>
      <td>{{instance.RequestCount}}</td>
      <td>{{instance.DeviceCount}}</td>
      <td>{{instance.Billing_FreeTrialStart}}</td>
      <td (click)="loginToInstance(instance.Id, instance.SubDomain)">LOGIN</td>
    </tr>
    </tbody>
  </table>
</div>
