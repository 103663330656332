<app-modal [modalSize]="'modal-medium'">
  <div class="modal-header">
    <h5 class="modal-title" id="exampleModalLabel">Add CAB Members</h5>
  </div>
        <div class=" modal-body">
        <div class="row">
            <div class="col">
                <div class="d-flex">
                    <input class="form-control me-2" type="search" placeholder="Search" aria-label="Search" [(ngModel)]="searchTerm">
                    <button class="btn btn-primary" type="submit" (click)="searchCab()">Search</button>
                    <button class="btn btn-default" type="cancel" (click)="cancelSearch()">Cancel</button>
                </div>
            </div>
        </div>
    </div>
  <div class="modal-body">
    <p class="text-muted">Select a CAB</p>
    <div id="selectCabBoard">
      <div class="list-group m-b-15 m-t-10">
        <a class="list-group-item divLink" *ngFor="let x of this.cabBoards" [class.active]="selectedCabBoardId == x.Id" (click)="selectedCabBoardId = x.Id; refreshCabMembers()">
          <h4 class="list-group-item-heading">{{x.Name}}</h4>
          <p class="list-group-item-text">{{x.Description}}</p>
        </a>
      </div>
    </div>

    <div *ngIf="loadingCabMembers">
      <hr>
      <app-loading-spinner></app-loading-spinner>
    </div>

    <div *ngIf="selectedCabBoardId && !loadingCabMembers">
      <hr>
      <div class="table-responsive">
        <table class="table align-middle tableStyle">
          <thead>
          <tr>
            <th><input [ngModel]="this.checkboxIsAllSelected()" (change)="this.checkboxToggleAll()"
                       class="form-check-input" type="checkbox" id="flexCheckDefault">
              <label class="form-check-label" for="flexCheckDefault"></label>
            </th>
            <th>Name</th>
            <th>Email</th>
          </tr>
          </thead>
          <tbody>
          <tr *ngFor="let member of this.cabMembers" class="divLink">
            <td><input [ngModel]="this.selectedMembers.isSelected(member.Id)"
                       (change)="this.selectedMembers.toggle(member.Id)" class="form-check-input" type="checkbox"
                       id="filterViewTable">
              <label class="form-check-label" for="flexCheckDefault"></label></td>
            <td>{{member.Name}}</td>
            <td>{{member.EmailAddress}}</td>
          </tr>
          </tbody>
        </table>
      </div>
    </div>

  </div>
  <div class="modal-footer">
    <div (click)="save()" class="btn btn-primary" data-bs-dismiss="modal">Add Members</div>
    <div (click)="close()" class="btn btn-cancel" data-bs-dismiss="modal">Cancel</div>
  </div>
</app-modal>
