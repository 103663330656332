<app-loading-spinner *ngIf="isLoading" ></app-loading-spinner>

<div *ngIf="!isLoading" class="table-responsive p-l-20 p-r-20">

  <table class="table align-middle tableStyle">
    <thead>
    <tr>
      <th class="">Major Incident Tasks
        <div (click)="create()" class="btn btn-primary float-end">Create</div>
      </th>
      <th></th>
      <th></th>
    </tr>
    </thead>
    <tbody>
    <tr *ngFor="let row of this.data; let first = first; let last = last">
      <td><span class="divLink" (click)="edit(row.Id)">{{row.TaskName}}</span>
        <i class="far fa-trash-alt float-end m-r-20 divLink" (click)="delete(row.Id)"></i>
        <i class="fas fa-cog float-end m-r-20 divLink" (click)="edit(row.Id)"></i>
      </td>
      <td style="width: 10px;">
        <a (click)="moveUp(row.Id)"><i *ngIf="!first" class="fa fa-arrow-up m-r-5"></i></a>
      </td>
      <td style="width: 10px;">
        <a (click)="moveDown(row.Id)"><i *ngIf="!last" class="fa fa-arrow-down"></i></a>
      </td>
    </tr>
    </tbody>
  </table>
</div>
