<app-loading-spinner *ngIf="isLoading" ></app-loading-spinner>

<div *ngIf="!isLoading" class="table-responsive">
  <table class="table align-middle tableStyle">
    <thead>
    <tr>
      <th class="">Type</th>
      <th class="">Requester</th>
    </tr>
    </thead>
    <tbody>
    <tr *ngFor="let row of this.data" class="divLink">
      <td>{{row.RequestTypeName}}</td>
      <td>{{row.Requester}}</td>
    </tr>
    </tbody>
  </table>
</div>
