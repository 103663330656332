<div class="SDeskSidePanel-GreyBackground"></div>

<app-blade-right size="blade-large">
  <div class="p-10 p-b-0">
    <i (click)="this.close()" class="fas fa-times fa-2x float-end divLink"></i>
    <h3><i class="fad fa-bullhorn fa-15x" style="color: #43aa8b;"></i> Send Feedback</h3>
  </div>
  <hr>
  <div class="container p-0">


    <div class="row">
      <div class="col p-10">
        <h6>Please leave your feedback below. If you are raising a bug, please include as much detail as possible.</h6>
        <p class="text-muted">Screenshots can be pasted into the text editor below</p>
      </div>
    </div>

    <div class="row">
      <div class="col p-10">
        <quill-editor [(ngModel)]="bugInfo" [styles]="{height: '450px'}" placeholder=""></quill-editor>
      </div>
    </div>

    <div class="m-t-20">
      <div (click)="save()" class="btn btn-primary">Submit</div>
    </div>

  </div>
</app-blade-right>
