

import {Component, EventEmitter, Input, OnInit, Output, ViewContainerRef} from '@angular/core';
import {Dialog} from "../../../../../../shared/dialog/dialog";
import {ApiAdminAzureAdSyncService} from "../../../../../../services/api/admin/admin-azure-ad-sync-api/admin-azure-ad-sync-api.service";

@Component({
  selector: 'app-admin-azure-ad-sync-create-edit-dialog',
  templateUrl: './admin-azure-ad-sync-create-edit-dialog.component.html'
})

export class AdminAzureAdComponentDialog implements Dialog {

  @Input() data;
  @Output() remove = new EventEmitter<any>();

  isLoading = true;
  loadedId = null;

  loadedData;

  constructor(
    private apiAdminAzureAdSyncService: ApiAdminAzureAdSyncService
  ) {}


  ngOnInit(): void {
    if(this.data !== null){
      this.loadedId = this.data;
      this.getData();
    } else {

      // Load Default Data
      this.loadedData = {
        Description: ''
      }

      this.isLoading = false;

    }
  }

  getData(){
    this.isLoading = true;
    this.apiAdminAzureAdSyncService.get(this.loadedId).then(response => {
      this.loadedData = response;
      this.isLoading = false;
    })
  }

  close(){
    this.remove.emit(null);
  }

  save(){
    if(this.loadedId){
      this.apiAdminAzureAdSyncService.update(this.loadedId, this.loadedData).then(response => {
        this.remove.emit("Saved");
      })
    } else {
      this.apiAdminAzureAdSyncService.create(this.loadedData).then(response => {
        this.remove.emit("Saved");
      })
    }

  }
}
