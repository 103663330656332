
<div *ngIf="!isLoading" class="table-responsive p-l-20 p-r-20">
  <div class="float-end"><i class="fas fa-file-download divLink p-l-20" (click)="exportExcel()" style="font-size: 35px; color: #4a6987;"></i></div>
  <div (click)="create()" class="btn btn-primary float-end">Create</div>
  <div class="col-sm-5 m-b-10">
    <input [(ngModel)]="searchTerm" type="text" class="form-control" id="Search" placeholder="Search..." autocomplete="off">
  </div>
  <div class="row mb-3 mt-3">
    <div class="col-md-4">
   <p class="page-entries">Showing {{fromRowscount}} to <span *ngIf="!isLastPage()">{{showingRowscount}}</span><span *ngIf="isLastPage()">{{recordCount}}</span> of {{recordCount}} users</p> 
 
   </div>
   <div class="col">
    <p-paginator (onPageChange)="onPageChange($event)" [first]="first" [rows]="rows" [totalRecords]="recordCount" [rowsPerPageOptions]="[50,100,500]"></p-paginator>
   
   </div>
  </div>
  <table class="table align-middle tableStyle">
    <thead class="sticky top-0">
      <tr>
        <th class="divLink" (click)="changeOrder('Name')">
          <div class="flex flex-col items-center">
        Users   <div class="user-count" text-center>({{recordCount}})</div>
          <i *ngIf="orderTableBy == 'Name'" [class.fa-arrow-up]="orderTableByAsc" [class.fa-arrow-down]="!orderTableByAsc" class="fas"></i>
          </div>
        </th>
        <th class="divLink" (click)="changeOrder('EmailAddress')">
          Email Address
          <i *ngIf="orderTableBy == 'EmailAddress'" [class.fa-arrow-up]="orderTableByAsc" [class.fa-arrow-down]="!orderTableByAsc" class="fas"></i>
        </th>
        <th class="divLink" (click)="changeOrder('CustomerName')">
          Customer Name
          <i *ngIf="orderTableBy == 'CustomerName'" [class.fa-arrow-up]="orderTableByAsc" [class.fa-arrow-down]="!orderTableByAsc" class="fas"></i>
        </th>        
        <th class="divLink" (click)="changeOrder('Admin')">
          <div class="flex flex-col items-center">
          Admin <div class="Admin-count" ml-20 text-center>({{totalAdmins}})</div>
          <i *ngIf="orderTableBy == 'Admin'" [class.fa-arrow-up]="orderTableByAsc" [class.fa-arrow-down]="!orderTableByAsc" class="fas"></i>
          </div>
        </th>
        <th class="divLink" (click)="changeOrder('Agent')">
          <div class="flex flex-col items-center">
          Agent<div class="Agent-count" text-center>({{totalAgent}})</div>
          <i *ngIf="orderTableBy == 'Agent'" [class.fa-arrow-up]="orderTableByAsc" [class.fa-arrow-down]="!orderTableByAsc" class="fas"></i>
        </div>
        </th>
        <th class="divLink" (click)="changeOrder('AllowPortalLogin')">
          <div class="flex flex-col items-center pl-45">
          Self Service Portal Enabled<div class="self-service-portal-enabled" text-center>({{totalsspe}})</div>
          <i *ngIf="orderTableBy == 'AllowPortalLogin'" [class.fa-arrow-up]="orderTableByAsc" [class.fa-arrow-down]="!orderTableByAsc" class="fas"></i>
          </div>
        </th>
        <th></th>
      </tr>
    </thead>
    <tbody>
      <tr *ngFor="let row of this.filteredData()">
        <td class="divLink" (click)="edit(row.Id)">{{row.Name}}</td>
        <td>{{row.EmailAddress}}</td>
        <td>{{row.CustomerName}}</td>
        <td><i *ngIf="row.Admin" class="fas fa-check-circle"></i></td>
        <td><i *ngIf="row.Agent" class="fas fa-check-circle"></i></td>
        <td><i *ngIf="row.AllowPortalLogin" class="fas fa-check-circle"></i></td>
        <td>
          <i class="far fa-trash-alt float-end m-r-20 divLink" (click)="delete(row.Id)"></i>
          <i class="fas fa-cog float-end m-r-20 divLink" (click)="edit(row.Id)"></i>
        </td>
      </tr>
    </tbody>
  </table>
</div>
