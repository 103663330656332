<div (click)="toggleDropdown()" class="divLink" style="
    border: 1px lightgray solid;
    padding: 5px;
    border-radius: 4px;">

  <span *ngIf="selectedData.length == 0">Please Select</span>

  <span *ngFor="let item of selectedData" class="tag" style="background: #466d92 !important;
    color: whitesmoke;
    font-size: 13px;
    padding: 1px 7px;
    margin-right: 10px;
    border-radius: 5px;
    margin-top: 5px;
    display: inline-block;
">{{item.name}}

    <i (click)="removeItem($event, item)" class="fa fa-times tag-remove" style="margin-left: 5px; cursor: pointer;"></i>
  </span>

  <i class="fa fa-chevron-down float-end" style="padding: 5px;" aria-hidden="true"></i>
</div>

<div #dropdown *ngIf="showDropdown"
  style="position: absolute; background: white; width: 335px; border: solid #d1d1d1 1px; z-index: 100; border-bottom-right-radius: 5px; border-bottom-left-radius: 5px; margin-top: -1px; max-height: 200px; overflow-y: auto;">
  <div class="search-container">
    <i class="fa fa-search search-icon float-end"></i>
    <input type="text" [(ngModel)]="searchTerm" (input)="filterOptions()" placeholder="Search" class="dropdown-search">
  </div>
  <div style="max-height: 100px; overflow-y: auto;">
    <div (click)="selectAll()" class="row userSearchRow divLink">
      <div class="col-10">
        Select All
      </div>
      <div class="col-2">
        <i *ngIf="allSelected()" class="fas fa-check-circle float-end unselectable-checkbox" style="font-size: 20px;
      color: #4a6987;" aria-hidden="true"></i>
      </div>
    </div>
  </div>


  <div (click)="toggleItem(option)" *ngFor="let option of filteredData" class="row userSearchRow divLink">
    <div class="col-10">
      {{option.name}}
    </div>
    <div class="col-2">
      <i *ngIf="!isSelected(option)" class="fal fa-circle float-end unselectable-checkbox" style="font-size: 20px;"
        aria-hidden="true"></i>
      <i *ngIf="isSelected(option)" class="fas fa-check-circle float-end unselectable-checkbox" style="font-size: 20px;
    color: #4a6987;" aria-hidden="true"></i>
    </div>
  </div>
</div>