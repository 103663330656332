<app-modal [modalSize]="'modal-medium'">
  <div class="modal-header">
    <h5 class="modal-title" id="exampleModalLabel">Service Information</h5>
  </div>
  <div class="">

    <app-loading-dots *ngIf="isLoading"></app-loading-dots>
    <div *ngIf="!isLoading" id="thread" class="row p-t-20">
      <!--  Only show tabs if thread is greater than 1 -->
      <div id="threadTabs" class="row">
        <div class="col">
          <ul class="nav m-b-5">
            <li class="nav-item divLink" (click)="selectedTab = 'info'">
              <a class="nav-link-thread" [class.active]="selectedTab == 'info'">Service Details</a>
            </li>
            <li class="nav-item divLink" (click)="selectedTab = 'open'">
              <a class="nav-link-thread" [class.active]="selectedTab == 'open'">Open Tickets  ({{serviceData.OpenTickets.length}})</a>
            </li>
            <li class="nav-item divLink" (click)="selectedTab = 'closed'">
              <a class="nav-link-thread" [class.active]="selectedTab == 'closed'">Closed Tickets ({{serviceData.AllTickets.length}})</a>
            </li>
          </ul>
        </div>
      </div>
      <div id="threadContent" class="row m-t-5">
        <div *ngIf="selectedTab == 'info'">
          <div class="col-12">
            <table class="table tableStyle m-0">
              <tbody>
              <tr>
                <th scope="row" class="col-3">Service Name</th>
                <td class="ng-binding">{{serviceData.Info.ServiceName}}</td>
              </tr>
              <tr>
                <th scope="row" class="">Customer</th>
                <td class="ng-binding">{{serviceData.Info.CustomerName}}</td>
              </tr>
              <tr>
                <th scope="row">Vendor</th>
                <td class="ng-binding">{{serviceData.Info.VendorName}}</td>
              </tr>
              <tr>
                <th scope="row">Contact Name</th>
                <td class="ng-binding">{{serviceData.Info.ContactName}}</td>
              </tr>
              <tr>
                <th scope="row">Contact Email Address</th>
                <td class="ng-binding">{{serviceData.Info.ContactEmailAddress}}</td>
              </tr>
              <tr>
                <th scope="row">Contact Phone Number</th>
                <td class="ng-binding">{{serviceData.Info.PhoneNumber}}</td>
              </tr>
              </tbody>
            </table>


          </div>
        </div>

        <div *ngIf="selectedTab == 'open'">
          <table class="table tableStyle m-0">
            <thead>
            <tr>
              <th>Id#</th>
              <th>Short Description</th>
              <th>Created</th>
              <th>Assigned To</th>
              <th>Status</th>
            </tr>
            </thead>
            <tbody>
            <tr routerLink="/agent/request/{{x.Id}}" class="divLink" *ngFor="let x of serviceData.OpenTickets">
              <th scope="row">{{x.LocalRequestId}}</th>
              <td>{{x.Subject}}</td>
              <td><app-friendly-time [utcTimeData]="x.DateTimeCreated" [timeFormat] = "instanceParams.preferredDateFormat"></app-friendly-time></td>
              <td>{{x.AssignedTo}}</td>
              <td>{{x.StatusName}}</td>
            </tr>
            </tbody>
          </table>
        </div>

        <div *ngIf="selectedTab == 'closed'">
          <table ng-show="Data.AllTickets" class="table tableStyle m-0">
            <thead>
            <tr>
              <th>Id#</th>
              <th>Short Description</th>
              <th>Created</th>
              <th>Assigned To</th>
              <th>Status</th>
            </tr>
            </thead>
            <tbody>
            <tr routerLink="/agent/request/{{x.Id}}" class="divLink" *ngFor="let x of serviceData.AllTickets">
              <th scope="row">{{x.LocalRequestId}}</th>
              <td>{{x.Subject}}</td>
              <td><app-friendly-time [utcTimeData]="x.DateTimeCreated" [timeFormat] = "instanceParams.preferredDateFormat"></app-friendly-time></td>
              <td>{{x.AssignedTo}}</td>
              <td>{{x.StatusName}}</td>
            </tr>
            </tbody>
          </table>
        </div>

      </div>
    </div>


  </div>
  <div class="modal-footer">
    <div (click)="close()" class="btn btn-cancel" data-bs-dismiss="modal">Close</div>
  </div>
</app-modal>
