import { Injectable } from '@angular/core';
import {BehaviorSubject} from "rxjs";
import {OptionsApiService} from "../api/options/options-api.service";

@Injectable({
  providedIn: 'root'
})
export class RequestTypeService {
  public requestTypesSubscription = new BehaviorSubject<any>(null);

  constructor(private apiOptions: OptionsApiService) { }

  refreshRequestTypes(){
    this.apiOptions.AllRequestTypes().then(response => {
      this.requestTypesSubscription.next(response)
    }).catch(error => {})
  }
}
