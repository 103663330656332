

import {Component, EventEmitter, Input, OnInit, Output, ViewContainerRef} from '@angular/core';
import {ApiAdminCustomerLocationsService} from "../../../../../../../services/api/admin/admin-customer-locations-api/admin-customer-locations-api.service";
import {Dialog} from "../../../../../../../shared/dialog/dialog";

@Component({
  selector: 'app-admin-customers-locations-create-edit-dialog',
  templateUrl: './admin-customers-locations-create-edit-dialog.component.html'
})

export class AdminCustomersLocationsComponentDialog implements Dialog {

  @Input() data; // {customerId: this.loadedId, locationId: id}
  @Output() remove = new EventEmitter<any>();

  isLoading = true;
  isLoadingOptions = true;

  loadedId = null;

  loadedData;
  options;

  constructor(
    private apiAdminCustomerLocationsService: ApiAdminCustomerLocationsService
  ) {}


  ngOnInit(): void {

    this.getOptions();

    if(this.data.locationId == null){
      // Load Default Data
      this.loadedData = {
        Name: '',
        Timezone: 'Europe/London',
        CustomerId: this.data.customerId
      }

      this.isLoading = false;

    } else {
      this.loadedId = this.data.locationId;
      this.getData();
    }

  }

  getOptions(){
    this.apiAdminCustomerLocationsService.options().then(response => {
      this.options = response;
      this.isLoadingOptions = false;
    })
  }

  getData(){
    this.isLoading = true;
    this.apiAdminCustomerLocationsService.get(this.loadedId).then(response => {
      this.loadedData = response;
      this.isLoading = false;

    })
  }

  close(){
    this.remove.emit(null);
  }

  save(){
    if(this.loadedId){
      this.apiAdminCustomerLocationsService.update(this.loadedId, this.loadedData).then(response => {
        this.remove.emit("Saved");
      })
    } else {
      this.apiAdminCustomerLocationsService.create(this.loadedData).then(response => {
        this.remove.emit("Saved");
      })
    }

  }
}
