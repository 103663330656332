<app-loading-spinner *ngIf="isLoading"></app-loading-spinner>

<div *ngIf="!isLoading" class="row m-t-5">
  <!-- Middle section start -->
  <div class="col-lg-9">
    <div class="sectionBox">
      <h4><strong>{{ticketData.details.RequestSubject}}</strong></h4>
      <div [innerHTML]="ticketData.details.BodyText"></div>

    </div>

    <div class="sectionBox">
      <!-- Ticket detail -->
      <app-loading-spinner *ngIf="sendingUpdateInProgress"></app-loading-spinner>
      <!-- Update details input start-->
      <div *ngIf="!sendingUpdateInProgress">
        <!-- <textarea [(ngModel)]="replyText" rows="5" id="RequestBody" spellcheck="false" autocomplete="off" class="form-control" style="width: 100%;"></textarea> -->
        <quill-editor [(ngModel)]="replyText" [styles]="{height: '150px'}" placeholder="" style="display:block;" [modules]="modules" (change)="onEditorChange($event.target.files)"></quill-editor>

        <input type="file" style="display: none" (change)="onFileSelected($event)" #fileUpload>

        <!-- Showing the attached documents start -->
        <div *ngFor="let attachment of attachmentArray; let i = index" (click)="removeTempAttachment(i)" class="btn btn-outline-secondary divLink btn-sm attachmentButton m-r-5 m-t-10">
          <i class="fas fa-file m-r-5"></i>
          <span>{{attachment.AttachmentName}}</span>
        </div>
        <!-- Showing the attached document end -->

        <!-- Input button section start-->
        <div>
          <!-- Attach the files -->
          <button (click)="fileUpload.click()" class="btn btn-primary" ><i class="fas fa-paperclip"></i></button>
          <!-- Save the updates -->
          <button (click)="submitUpdate()" [disabled]="!replyText" class="btn btn-primary">Update Ticket</button> 
        </div>
        <!-- Input button section end -->

      </div>
      <!-- Update details input end-->
    </div>
    
    <app-request-thread-item *ngFor="let threadItem of ticketData.thread" [threadItem]="threadItem" [threadItemAttachments]="ticketData.ThreadAttachments"></app-request-thread-item>

  </div>
  <!-- Middle section end -->

  <!-- Right side box start -->
  <div class="col-lg-3">
    <div class="sectionBox p-0">
      <table class="table tableStyle m-0">
        <tbody>
        <tr>
          <th scope="row">{{ticketData.details.TypeName}} Id</th>

          <td>
            {{ticketData.details.LocalRequestId}}
          </td>

        </tr>
        <tr>
          <th scope="row">Request Type</th>
          <td>{{ticketData.details.TypeName}}</td>
        </tr>
        <tr>
          <th scope="row">Opened</th>
          <td>
            <app-friendly-time [utcTimeData]="ticketData.details.date_browser"></app-friendly-time>
          </td>
        </tr>
        <tr>
          <th scope="row">Requester</th>
          <td>
            {{ticketData.details.Requester}}
          </td>
        </tr>

        <tr>
          <th scope="row">Priority </th>
          <td>
            {{ticketData.details.Priority}}
          </td>
        </tr>

        <tr>
          <th scope="row">Status </th>
          <td>
            {{ticketData.details.Status}}
          </td>
        </tr>

        </tbody>
      </table>
    </div>
  </div>
<!-- Right side box end -->
</div>
