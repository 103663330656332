import {Component, Input, Output, EventEmitter, ElementRef, HostListener, ViewChild} from '@angular/core';
import { CommonModule } from '@angular/common';
import {FormsModule} from '@angular/forms';

@Component({
  selector: 'app-sdesk-multi-select',
  standalone: true,
  imports: [CommonModule, FormsModule],
  templateUrl: './sdesk-multi-select.component.html',
  styleUrl: './sdesk-multi-select.component.css'
})

export class SdeskMultiSelectComponent {
  @Input() data: any[] = [];
  @Input() selectedData: any[] = [];
  @Output() selectedDataChange = new EventEmitter<any[]>();


  showDropdown = false;
  private toggleClicked = false;
  searchTerm = '';
  filteredData: any[] = [];

  @ViewChild('dropdown', { static: false }) dropdownElement: ElementRef;

  ngOnInit() {
    this.filteredData = [...this.data];
  }

  toggleDropdown(): void {
    this.showDropdown = !this.showDropdown;
    this.toggleClicked = true; // Set the flag when toggle is clicked
  }

  @HostListener('document:click', ['$event'])
  onDocumentClick(event: MouseEvent): void {
    // Check if the click is outside and not on the toggle
    if (!this.toggleClicked && this.dropdownElement && !this.dropdownElement.nativeElement.contains(event.target)) {
      this.showDropdown = false;
    }
    this.toggleClicked = false; // Reset the flag
  }

  // toggle option
  toggleItem(item: any): void {
    if (this.isSelected(item)) {
      this.selectedData = this.selectedData.filter(selectedItem => selectedItem.id !== item.id);
    } else {
      this.selectedData.push(item);
    }

    this.selectedDataChange.emit(this.selectedData);
  }
  isSelected(item: any): boolean {
    return this.selectedData.some(selectedItem => selectedItem.id === item.id);
  }

  // Select all options
  selectAll(): void {
    if (this.allSelected()) {
      this.selectedData = [];
    } else {
      this.selectedData = [...this.data];
    }

    this.selectedDataChange.emit(this.selectedData);
  }

  // Check if all options are selected
  allSelected(): boolean {
    return this.selectedData.length === this.data.length;
  }

  filterOptions(): void {
    if (!this.searchTerm) {
      this.filteredData = [...this.data];
    } else {
      this.filteredData = this.data.filter(option => option.name.toLowerCase().includes(this.searchTerm.toLowerCase()));
    }
  }

  removeItem(event: Event, item: any): void {
    event.stopPropagation();
    this.selectedData = this.selectedData.filter(selectedItem => selectedItem.id !== item.id);
    this.selectedDataChange.emit(this.selectedData);
  }
}
