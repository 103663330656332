<app-loading-spinner *ngIf="isLoading"></app-loading-spinner>

<div class="sdesk-refresh-bar" *ngIf="!isLoading"></div>


<div class="m-t-10 m-b-10" *ngIf="!isLoading">
    <div class="row">
        <div class="col-md-6">
    <h4 class="p-10 mb-3"><strong>{{data?.Name}}</strong>&nbsp;&nbsp;<span *ngIf="filterId">({{data?.Count}})</span><span *ngIf="!filterId">({{data?.Count}})</span>

        <span class=""><i class="fas fa-sync divLink p-l-20" (click)="loadData()" title="Reload"></i></span>
          <span class="col-md-2 p-l-20 " *ngIf="viewName === 'myrequests'">
            <select [(ngModel)]="defaultTicketOptions" data-style="btn-white" (change)="onOptionSelect($event)">
              <option *ngFor="let option of ticketOptions" [value]="option.value">{{ option.name }}</option>
            </select>
          </span>
        <span  class="" *ngIf="!this.data.ReadOnlyFilter"><i class="fas fa-cog divLink p-l-20" (click)="editFilter()" title="Edit Filter"></i></span>
        <span class="" *ngIf="!this.data.ReadOnlyFilter"><i class="fas fa-file-csv divLink p-l-20" (click)="downloadCsv()" title= "Export as CSV"></i></span>
        <span class="" *ngIf="!this.data.ReadOnlyFilter"><i class="fas fa-file-excel divLink p-l-20" (click)="exportExcel()" title="Export as XLSX"></i></span>

        <span *ngIf="!this.data.ReadOnlyFilter"><i class="fas fa-calendar divLink p-l-20" (click) = "openSheduleReports()" title="Schedule Report"></i></span>
        <span *ngIf="filterId && !this.data.ReadOnlyFilter"><i class="fas fa-search divLink p-l-20" (click)="toggleSearchDiv()" title="Search"></i></span>
</h4>
</div>
<div class="col-md-6">

<!-- start paginator -->
<ng-container>
    <span class="pagination-mytickets">
        <p-paginator (onPageChange)="onPageChange($event)" [first]="first" [rows]="rows" [totalRecords]="data?.Count" [rowsPerPageOptions]="[50,100,500]"></p-paginator>
    </span>
</ng-container>
<!-- end paginator -->
        </div>
    </div>

    <!-- start search section -->
    <div *ngIf="isSearchDivVisible" class="row" id="search-div">
      <div class="col-md-2" style="display: flex; align-items: stretch;">
        <select [(ngModel)]="defaultSearchType" class="form-control large-search-box" data-style="btn-white" style="height: 100%;">
          <option *ngFor="let option of totalColumnSelected" [label]="option.name" [value]="option.id"></option>
        </select>
      </div>
      <div class="col-md-10">
        <input [(ngModel)]="searchText" (keyup.enter)="loadData(false, false, true)" type="text" class="form-control large-search-box" autocomplete="off" placeholder="Search..." id="search">
      </div>
    </div>
    <!-- end search section -->

    <app-ticket-bulk-update *ngIf="!isLoading" [selectedTickets]="this.selectedTickets" [accessPermissions]="accessPermissions" [ticketsData]="this.data.Results" (refreshData)="this.loadData(false, true)" [isCmdbEnabled]="isCmdbEnabled()"></app-ticket-bulk-update>

    <div class="row mt-2">

        <div class="sectionBox p-0 col">

            <div class="scroll-wrapper">

            <!-- START section to render the top scroll bar -->
            <div class="top-scroll" #topScroll>
                <div class="top-scroll-content"></div>
            </div>
            <!-- END section to render the top scroll bar -->
            
            <div class="bottom-scroll" #bottomScroll>
                <table class="table align-middle tableStyle">
                    <thead>
                        <tr>
                            <th class="" scope="col">
                                <div class="form-check">
                                    <input [ngModel]="this.checkboxIsAllSelected()" (change)="this.checkboxToggleAll()" class="form-check-input" type="checkbox" id="flexCheckDefault">
                                    <label class="form-check-label" for="flexCheckDefault"></label>
                                </div>
                            </th>
                            <th *ngIf="this.data?.Columns[11]" class="divLink" (click)="changeOrderBy(11)">{{ isCmdbEnabled() ? 'Asset No.' : 'Id' }}
                                <div  *ngIf="!(this.data.OrderById == 11 && (this.data.OrderByAsc || !this.data.OrderByAsc))" class="combined-icon">
                                    <i class="fa fa-long-arrow-up fa-up" aria-hidden="true"></i>
                                    <i class="fa fa-long-arrow-down fa-down" aria-hidden="true"></i>
                                </div>
                                <i *ngIf="this.data.OrderById == 11 && OrderAsc === 1" class="fa fa-sort-amount-up-alt" aria-hidden="true"></i>
                                <i *ngIf="this.data.OrderById == 11 && OrderAsc === 0" class="fa fa-sort-amount-down"></i>
                            </th>

                            <th *ngIf="this.data.Columns[28]" class="divLink" (click)="changeOrderBy(28)">Type
                                <div  *ngIf="!(this.data.OrderById == 28 && (this.data.OrderByAsc || !this.data.OrderByAsc))" class="combined-icon">
                                    <i class="fa fa-long-arrow-up fa-up" aria-hidden="true"></i>
                                    <i class="fa fa-long-arrow-down fa-down" aria-hidden="true"></i>
                                </div>
                                <i *ngIf="this.data.OrderById == 28 && OrderAsc === 1" class="fa fa-sort-amount-up-alt"></i>
                                <i *ngIf="this.data.OrderById == 28 && OrderAsc === 0" class="fa fa-sort-amount-down"></i>
                            </th>

                            <th *ngIf="this.data.Columns[10]" class="divLink" (click)="changeOrderBy(10)">{{ isCmdbEnabled() ? 'Owner' : 'Requester' }}
                                <div  *ngIf="!(this.data.OrderById == 10 && (this.data.OrderByAsc || !this.data.OrderByAsc))" class="combined-icon">
                                    <i class="fa fa-long-arrow-up fa-up" aria-hidden="true"></i>
                                    <i class="fa fa-long-arrow-down fa-down" aria-hidden="true"></i>
                                </div>
                                <i *ngIf="this.data.OrderById == 10 && OrderAsc === 1" class="fa fa-sort-amount-up-alt"></i>
                                <i *ngIf="this.data.OrderById == 10 && OrderAsc === 0" class="fa fa-sort-amount-asc"></i>
                            </th>

                            <th *ngIf="this.data.Columns[41]" class="divLink" (click)="changeOrderBy(41)">Requester Email
                                <div  *ngIf="!(this.data.OrderById == 41 && (this.data.OrderByAsc || !this.data.OrderByAsc))" class="combined-icon">
                                    <i class="fa fa-long-arrow-up fa-up" aria-hidden="true"></i>
                                    <i class="fa fa-long-arrow-down fa-down" aria-hidden="true"></i>
                                </div>
                                <i *ngIf="this.data.OrderById == 41 && OrderAsc === 1" class="fa fa-sort-amount-up-alt"></i>
                                <i *ngIf="this.data.OrderById == 41 && OrderAsc === 0" class="fa fa-sort-amount-down"></i>
                            </th>

                            <th *ngIf="this.data.Columns[9]" [ngClass]="{'col-sm-5': !isCmdbEnabled()}" class="divLink" (click)="changeOrderBy(9)">{{ isCmdbEnabled() ? 'Asset Name' : 'Subject' }}
                                <div  *ngIf="!(this.data.OrderById == 9 && (this.data.OrderByAsc || !this.data.OrderByAsc))" class="combined-icon">
                                    <i class="fa fa-long-arrow-up fa-up" aria-hidden="true"></i>
                                    <i class="fa fa-long-arrow-down fa-down" aria-hidden="true"></i>
                                </div>
                                <i *ngIf="this.data.OrderById == 9 && OrderAsc === 1" class="fa fa-sort-amount-up-alt"></i>
                                <i *ngIf="this.data.OrderById == 9 && OrderAsc === 0" class="fa fa-sort-amount-down"></i>
                            </th>

                            <th *ngIf="this.data.Columns[2]" class="divLink" (click)="changeOrderBy(2)">Status
                                <div  *ngIf="!(this.data.OrderById == 2 && (this.data.OrderByAsc || !this.data.OrderByAsc))" class="combined-icon">
                                    <i class="fa fa-long-arrow-up fa-up" aria-hidden="true"></i>
                                    <i class="fa fa-long-arrow-down fa-down" aria-hidden="true"></i>
                                </div>
                                <i *ngIf="this.data.OrderById == 2 && OrderAsc === 1" class="fa fa-sort-amount-up-alt"></i>
                                <i *ngIf="this.data.OrderById == 2 && OrderAsc === 0" class="fa fa-sort-amount-down"></i>
                            </th>

                            <th *ngIf="this.data.Columns[3]" class="divLink" (click)="changeOrderBy(3)">Priority
                                <div  *ngIf="!(this.data.OrderById == 3 && (this.data.OrderByAsc || !this.data.OrderByAsc))" class="combined-icon">
                                    <i class="fa fa-long-arrow-up fa-up" aria-hidden="true"></i>
                                    <i class="fa fa-long-arrow-down fa-down" aria-hidden="true"></i>
                                </div>
                                <i *ngIf="this.data.OrderById == 3 && OrderAsc === 1" class="fa fa-sort-amount-up-alt"></i>
                                <i *ngIf="this.data.OrderById == 3 && OrderAsc === 0" class="fa fa-sort-amount-down"></i>
                            </th>

                            <th *ngIf="this.data.Columns[20]" class="divLink" (click)="changeOrderBy(20)">Group
                                <div  *ngIf="!(this.data.OrderById == 20 && (this.data.OrderByAsc || !this.data.OrderByAsc))" class="combined-icon">
                                    <i class="fa fa-long-arrow-up fa-up" aria-hidden="true"></i>
                                    <i class="fa fa-long-arrow-down fa-down" aria-hidden="true"></i>
                                </div>
                                <i *ngIf="this.data.OrderById == 20 && OrderAsc === 1" class="fa fa-sort-amount-up-alt"></i>
                                <i *ngIf="this.data.OrderById == 20 && OrderAsc === 0" class="fa fa-sort-amount-down"></i>
                            </th>

                            <th *ngIf="this.data.Columns[4] && !isCmdbEnabled()" class="divLink" (click)="changeOrderBy(4)">Agent
                                <div  *ngIf="!(this.data.OrderById == 4 && (this.data.OrderByAsc || !this.data.OrderByAsc))" class="combined-icon">
                                    <i class="fa fa-long-arrow-up fa-up" aria-hidden="true"></i>
                                    <i class="fa fa-long-arrow-down fa-down" aria-hidden="true"></i>
                                </div>
                                <i *ngIf="this.data.OrderById == 4 && OrderAsc === 1" class="fa fa-sort-amount-up-alt"></i>
                                <i *ngIf="this.data.OrderById == 4 && OrderAsc === 0" class="fa fa-sort-amount-down"></i>
                            </th>

                            <th *ngIf="this.data.Columns[14]" class="divLink" (click)="changeOrderBy(14)">Updated
                                <div  *ngIf="!(this.data.OrderById == 14 && (this.data.OrderByAsc || !this.data.OrderByAsc))" class="combined-icon">
                                    <i class="fa fa-long-arrow-up fa-up" aria-hidden="true"></i>
                                    <i class="fa fa-long-arrow-down fa-down" aria-hidden="true"></i>
                                </div>
                                <i *ngIf="this.data.OrderById == 14 && OrderAsc === 1" class="fa fa-sort-amount-up-alt"></i>
                                <i *ngIf="this.data.OrderById == 14 && OrderAsc === 0" class="fa fa-sort-amount-down"></i>
                            </th>

                            <th *ngIf="this.data.Columns[8]" class="divLink" (click)="changeOrderBy(8)">Created
                                <div  *ngIf="!(this.data.OrderById == 8 && (this.data.OrderByAsc || !this.data.OrderByAsc))" class="combined-icon">
                                    <i class="fa fa-long-arrow-up fa-up" aria-hidden="true"></i>
                                    <i class="fa fa-long-arrow-down fa-down" aria-hidden="true"></i>
                                </div>
                                <i *ngIf="this.data.OrderById == 8 && OrderAsc === 1" class="fa fa-sort-amount-up-alt"></i>
                                <i *ngIf="this.data.OrderById == 8 && OrderAsc === 0" class="fa fa-sort-amount-down"></i>
                            </th>

                            <th *ngIf="this.data.Columns[12]" class="divLink" (click)="changeOrderBy(12)">Responded
                                <div  *ngIf="!(this.data.OrderById == 12 && (this.data.OrderByAsc || !this.data.OrderByAsc))" class="combined-icon">
                                    <i class="fa fa-long-arrow-up fa-up" aria-hidden="true"></i>
                                    <i class="fa fa-long-arrow-down fa-down" aria-hidden="true"></i>
                                </div>
                                <i *ngIf="this.data.OrderById == 12 && OrderAsc === 1" class="fa fa-sort-amount-up-alt"></i>
                                <i *ngIf="this.data.OrderById == 12 && OrderAsc === 0" class="fa fa-sort-amount-down"></i>
                            </th>

                            <th *ngIf="this.data.Columns[13]" class="divLink" (click)="changeOrderBy(13)">Resolved
                                <div  *ngIf="!(this.data.OrderById == 13 && (this.data.OrderByAsc || !this.data.OrderByAsc))" class="combined-icon">
                                    <i class="fa fa-long-arrow-up fa-up" aria-hidden="true"></i>
                                    <i class="fa fa-long-arrow-down fa-down" aria-hidden="true"></i>
                                </div>
                                <i *ngIf="this.data.OrderById == 13 && OrderAsc === 1" class="fa fa-sort-amount-up-alt"></i>
                                <i *ngIf="this.data.OrderById == 13 && OrderAsc === 0" class="fa fa-sort-amount-down"></i>
                            </th>

                            <th *ngIf="this.data.Columns[15]" class="divLink" (click)="changeOrderBy(15)">Response Due
                                <div  *ngIf="!(this.data.OrderById == 15 && (this.data.OrderByAsc || !this.data.OrderByAsc))" class="combined-icon">
                                    <i class="fa fa-long-arrow-up fa-up" aria-hidden="true"></i>
                                    <i class="fa fa-long-arrow-down fa-down" aria-hidden="true"></i>
                                </div>
                                <i *ngIf="this.data.OrderById == 15 && OrderAsc === 1" class="fa fa-sort-amount-up-alt"></i>
                                <i *ngIf="this.data.OrderById == 15 && OrderAsc === 0" class="fa fa-sort-amount-down"></i>
                            </th>

                            <th *ngIf="this.data.Columns[16]" class="divLink" (click)="changeOrderBy(16)">Resolution Due
                                <div  *ngIf="!(this.data.OrderById == 16 && (this.data.OrderByAsc || !this.data.OrderByAsc))" class="combined-icon">
                                    <i class="fa fa-long-arrow-up fa-up" aria-hidden="true"></i>
                                    <i class="fa fa-long-arrow-down fa-down" aria-hidden="true"></i>
                                </div>
                                <i *ngIf="this.data.OrderById == 16 && OrderAsc === 1" class="fa fa-sort-amount-up-alt"></i>
                                <i *ngIf="this.data.OrderById == 16 && OrderAsc === 0" class="fa fa-sort-amount-down"></i>
                            </th>

                            <th *ngIf="this.data.Columns[17]" class="divLink" (click)="changeOrderBy(17)">Survey Result
                                <div  *ngIf="!(this.data.OrderById == 17 && (this.data.OrderByAsc || !this.data.OrderByAsc))" class="combined-icon">
                                    <i class="fa fa-long-arrow-up fa-up" aria-hidden="true"></i>
                                    <i class="fa fa-long-arrow-down fa-down" aria-hidden="true"></i>
                                </div>
                                <i *ngIf="this.data.OrderById == 17 && OrderAsc === 1" class="fa fa-sort-amount-up-alt"></i>
                                <i *ngIf="this.data.OrderById == 17 && OrderAsc === 0" class="fa fa-sort-amount-down"></i>
                            </th>

                            <th *ngIf="this.data.Columns[18]" class="divLink" (click)="changeOrderBy(18)">Survey Status
                                <div  *ngIf="!(this.data.OrderById == 18 && (this.data.OrderByAsc || !this.data.OrderByAsc))" class="combined-icon">
                                    <i class="fa fa-long-arrow-up fa-up" aria-hidden="true"></i>
                                    <i class="fa fa-long-arrow-down fa-down" aria-hidden="true"></i>
                                </div>
                                <i *ngIf="this.data.OrderById == 18 && OrderAsc === 1" class="fa fa-sort-amount-up-alt"></i>
                                <i *ngIf="this.data.OrderById == 18 && OrderAsc === 0" class="fa fa-sort-amount-asc"></i>
                            </th>

                            <th *ngIf="this.data.Columns[19]" class="divLink" (click)="changeOrderBy(19)">Service
                                <div  *ngIf="!(this.data.OrderById == 19 && (this.data.OrderByAsc || !this.data.OrderByAsc))" class="combined-icon">
                                    <i class="fa fa-long-arrow-up fa-up" aria-hidden="true"></i>
                                    <i class="fa fa-long-arrow-down fa-down" aria-hidden="true"></i>
                                </div>
                                <i *ngIf="this.data.OrderById == 19 && OrderAsc === 1" class="fa fa-sort-amount-up-alt"></i>
                                <i *ngIf="this.data.OrderById == 19 && OrderAsc === 0" class="fa fa-sort-amount-down"></i>
                            </th>

                            <th *ngIf="this.data.Columns[21]" class="divLink" (click)="changeOrderBy(21)">Customer
                                <div  *ngIf="!(this.data.OrderById == 21 && (this.data.OrderByAsc || !this.data.OrderByAsc))" class="combined-icon">
                                    <i class="fa fa-long-arrow-up fa-up" aria-hidden="true"></i>
                                    <i class="fa fa-long-arrow-down fa-down" aria-hidden="true"></i>
                                </div>
                                <i *ngIf="this.data.OrderById == 21 && OrderAsc === 1" class="fa fa-sort-amount-up-alt"></i>
                                <i *ngIf="this.data.OrderById == 21 && OrderAsc === 0" class="fa fa-sort-amount-down"></i>
                            </th>

                            <th *ngIf="this.data.Columns[22]" class="divLink" (click)="changeOrderBy(22)">Impact
                                <div  *ngIf="!(this.data.OrderById == 22 && (this.data.OrderByAsc || !this.data.OrderByAsc))" class="combined-icon">
                                    <i class="fa fa-long-arrow-up fa-up" aria-hidden="true"></i>
                                    <i class="fa fa-long-arrow-down fa-down" aria-hidden="true"></i>
                                </div>
                                <i *ngIf="this.data.OrderById == 22 && OrderAsc === 1" class="fa fa-sort-amount-up-alt"></i>
                                <i *ngIf="this.data.OrderById == 22 && OrderAsc === 0" class="fa fa-sort-amount-down"></i>
                            </th>

                            <th *ngIf="this.data.Columns[23]" class="divLink" (click)="changeOrderBy(23)">Urgency
                                <div  *ngIf="!(this.data.OrderById == 23 && (this.data.OrderByAsc || !this.data.OrderByAsc))" class="combined-icon">
                                    <i class="fa fa-long-arrow-up fa-up" aria-hidden="true"></i>
                                    <i class="fa fa-long-arrow-down fa-down" aria-hidden="true"></i>
                                </div>
                                <i *ngIf="this.data.OrderById == 23 && OrderAsc === 1" class="fa fa-sort-amount-up-alt"></i>
                                <i *ngIf="this.data.OrderById == 23 && OrderAsc === 0" class="fa fa-sort-amount-down"></i>
                            </th>

                            <th *ngIf="this.data.Columns[24]" class="divLink" (click)="changeOrderBy(24)">Resolution Code
                                <div  *ngIf="!(this.data.OrderById == 24 && (this.data.OrderByAsc || !this.data.OrderByAsc))" class="combined-icon">
                                    <i class="fa fa-long-arrow-up fa-up" aria-hidden="true"></i>
                                    <i class="fa fa-long-arrow-down fa-down" aria-hidden="true"></i>
                                </div>
                                <i *ngIf="this.data.OrderById == 24 && OrderAsc === 1" class="fa fa-sort-amount-up-alt"></i>
                                <i *ngIf="this.data.OrderById == 24 && OrderAsc === 0" class="fa fa-sort-amount-down"></i>
                            </th>

                            <th *ngIf="this.data.Columns[25]" class="divLink" (click)="changeOrderBy(25)">Requester Responded
                                <div  *ngIf="!(this.data.OrderById == 25 && (this.data.OrderByAsc || !this.data.OrderByAsc))" class="combined-icon">
                                    <i class="fa fa-long-arrow-up fa-up" aria-hidden="true"></i>
                                    <i class="fa fa-long-arrow-down fa-down" aria-hidden="true"></i>
                                </div>
                                <i *ngIf="this.data.OrderById == 25 && OrderAsc === 1" class="fa fa-sort-amount-up-alt"></i>
                                <i *ngIf="this.data.OrderById == 25 && OrderAsc === 0" class="fa fa-sort-amount-down"></i>
                            </th>

                            <th *ngIf="this.data.Columns[26]" class="divLink" (click)="changeOrderBy(26)">Last Status Change
                                <div  *ngIf="!(this.data.OrderById == 26 && (this.data.OrderByAsc || !this.data.OrderByAsc))" class="combined-icon">
                                    <i class="fa fa-long-arrow-up fa-up" aria-hidden="true"></i>
                                    <i class="fa fa-long-arrow-down fa-down" aria-hidden="true"></i>
                                </div>
                                <i *ngIf="this.data.OrderById == 26 && OrderAsc === 1" class="fa fa-sort-amount-up-alt"></i>
                                <i *ngIf="this.data.OrderById == 26 && OrderAsc === 0" class="fa fa-sort-amount-down"></i>
                            </th>

                            <th *ngIf="this.data.Columns[27]" class="divLink" (click)="changeOrderBy(27)">Change State
                                <div  *ngIf="!(this.data.OrderById == 27 && (this.data.OrderByAsc || !this.data.OrderByAsc))" class="combined-icon">
                                    <i class="fa fa-long-arrow-up fa-up" aria-hidden="true"></i>
                                    <i class="fa fa-long-arrow-down fa-down" aria-hidden="true"></i>
                                </div>
                                <i *ngIf="this.data.OrderById == 27 && OrderAsc === 1" class="fa fa-sort-amount-up-alt"></i>
                                <i *ngIf="this.data.OrderById == 27 && OrderAsc === 0" class="fa fa-sort-amount-down"></i>
                            </th>

                            <th *ngIf="this.data.Columns[29]" class="divLink" (click)="changeOrderBy(29)">Sla
                                <div  *ngIf="!(this.data.OrderById == 29 && (this.data.OrderByAsc || !this.data.OrderByAsc))" class="combined-icon">
                                    <i class="fa fa-long-arrow-up fa-up" aria-hidden="true"></i>
                                    <i class="fa fa-long-arrow-down fa-down" aria-hidden="true"></i>
                                </div>
                                <i *ngIf="this.data.OrderById == 29 && OrderAsc === 1" class="fa fa-sort-amount-up-alt"></i>
                                <i *ngIf="this.data.OrderById == 29 && OrderAsc === 0" class="fa fa-sort-amount-down"></i>
                            </th>

                            <th *ngIf="this.data.Columns[30]" class="divLink" (click)="changeOrderBy(30)">Asset Type
                                <div  *ngIf="!(this.data.OrderById == 30 && (this.data.OrderByAsc || !this.data.OrderByAsc))" class="combined-icon">
                                    <i class="fa fa-long-arrow-up fa-up" aria-hidden="true"></i>
                                    <i class="fa fa-long-arrow-down fa-down" aria-hidden="true"></i>
                                </div>
                                <i *ngIf="this.data.OrderById == 30 && OrderAsc === 1" class="fa fa-sort-amount-up-alt"></i>
                                <i *ngIf="this.data.OrderById == 30 && OrderAsc === 0" class="fa fa-sort-amount-down"></i>
                            </th>

                            <th *ngIf="this.data.Columns[31]" class="divLink" (click)="changeOrderBy(31)">Serial Number
                                <div  *ngIf="!(this.data.OrderById == 31 && (this.data.OrderByAsc || !this.data.OrderByAsc))" class="combined-icon">
                                    <i class="fa fa-long-arrow-up fa-up" aria-hidden="true"></i>
                                    <i class="fa fa-long-arrow-down fa-down" aria-hidden="true"></i>
                                </div>
                                <i *ngIf="this.data.OrderById == 31 && OrderAsc === 1" class="fa fa-sort-amount-up-alt"></i>
                                <i *ngIf="this.data.OrderById == 31 && OrderAsc === 0" class="fa fa-sort-amount-down"></i>
                            </th>

                            <th *ngIf="this.data.Columns[32]" class="divLink" (click)="changeOrderBy(32)">Imei
                                <div  *ngIf="!(this.data.OrderById == 32 && (this.data.OrderByAsc || !this.data.OrderByAsc))" class="combined-icon">
                                    <i class="fa fa-long-arrow-up fa-up" aria-hidden="true"></i>
                                    <i class="fa fa-long-arrow-down fa-down" aria-hidden="true"></i>
                                </div>
                                <i *ngIf="this.data.OrderById == 32 && OrderAsc === 1" class="fa fa-sort-amount-up-alt"></i>
                                <i *ngIf="this.data.OrderById == 32 && OrderAsc === 0" class="fa fa-sort-amount-down"></i>
                            </th>

                            <th *ngIf="this.data.Columns[33]" class="divLink" (click)="changeOrderBy(33)">Manufacturer
                                <div  *ngIf="!(this.data.OrderById == 33 && (this.data.OrderByAsc || !this.data.OrderByAsc))" class="combined-icon">
                                    <i class="fa fa-long-arrow-up fa-up" aria-hidden="true"></i>
                                    <i class="fa fa-long-arrow-down fa-down" aria-hidden="true"></i>
                                </div>
                                <i *ngIf="this.data.OrderById == 33 && OrderAsc === 1" class="fa fa-sort-amount-up-alt"></i>
                                <i *ngIf="this.data.OrderById == 33 && OrderAsc === 0" class="fa fa-sort-amount-down"></i>
                            </th>

                            <th *ngIf="this.data.Columns[34]" class="divLink" (click)="changeOrderBy(34)">Model
                                <div  *ngIf="!(this.data.OrderById == 34 && (this.data.OrderByAsc || !this.data.OrderByAsc))" class="combined-icon">
                                    <i class="fa fa-long-arrow-up fa-up" aria-hidden="true"></i>
                                    <i class="fa fa-long-arrow-down fa-down" aria-hidden="true"></i>
                                </div>
                                <i *ngIf="this.data.OrderById == 34 && OrderAsc === 1" class="fa fa-sort-amount-up-alt"></i>
                                <i *ngIf="this.data.OrderById == 34 && OrderAsc === 0" class="fa fa-sort-amount-down"></i>
                            </th>

                            <th *ngIf="this.data.Columns[35]" class="divLink" (click)="changeOrderBy(35)">Cost
                                <div  *ngIf="!(this.data.OrderById == 35 && (this.data.OrderByAsc || !this.data.OrderByAsc))" class="combined-icon">
                                    <i class="fa fa-long-arrow-up fa-up" aria-hidden="true"></i>
                                    <i class="fa fa-long-arrow-down fa-down" aria-hidden="true"></i>
                                </div>
                                <i *ngIf="this.data.OrderById == 35 && OrderAsc === 1" class="fa fa-sort-amount-up-alt"></i>
                                <i *ngIf="this.data.OrderById == 35 && OrderAsc === 0" class="fa fa-sort-amount-down"></i>
                            </th>

                            <th *ngIf="this.data.Columns[36]" class="divLink" (click)="changeOrderBy(36)">Planning Scheduled Start
                                <div  *ngIf="!(this.data.OrderById == 36 && (this.data.OrderByAsc || !this.data.OrderByAsc))" class="combined-icon">
                                    <i class="fa fa-long-arrow-up fa-up" aria-hidden="true"></i>
                                    <i class="fa fa-long-arrow-down fa-down" aria-hidden="true"></i>
                                </div>
                                <i *ngIf="this.data.OrderById == 36 && OrderAsc === 1" class="fa fa-sort-amount-up-alt"></i>
                                <i *ngIf="this.data.OrderById == 36 && OrderAsc === 0" class="fa fa-sort-amount-down"></i>
                            </th>

                            <th *ngIf="this.data.Columns[37]" class="divLink" (click)="changeOrderBy(37)">Planning Scheduled End
                                <div  *ngIf="!(this.data.OrderById == 37 && (this.data.OrderByAsc || !this.data.OrderByAsc))" class="combined-icon">
                                    <i class="fa fa-long-arrow-up fa-up" aria-hidden="true"></i>
                                    <i class="fa fa-long-arrow-down fa-down" aria-hidden="true"></i>
                                </div>
                                <i *ngIf="this.data.OrderById == 37 && OrderAsc === 1" class="fa fa-sort-amount-up-alt"></i>
                                <i *ngIf="this.data.OrderById == 37 && OrderAsc === 0" class="fa fa-sort-amount-down"></i>
                            </th>

                            <th *ngIf="this.data.Columns[38]" class="divLink" (click)="changeOrderBy(38)">Requested By Date
                                <div  *ngIf="!(this.data.OrderById == 38 && (this.data.OrderByAsc || !this.data.OrderByAsc))" class="combined-icon">
                                    <i class="fa fa-long-arrow-up fa-up" aria-hidden="true"></i>
                                    <i class="fa fa-long-arrow-down fa-down" aria-hidden="true"></i>
                                </div>
                                <i *ngIf="this.data.OrderById == 38 && OrderAsc === 1" class="fa fa-sort-amount-up-alt"></i>
                                <i *ngIf="this.data.OrderById == 38 && OrderAsc === 0" class="fa fa-sort-amount-down"></i>
                            </th>

                            <th *ngIf="this.data.Columns[39]" class="divLink" (click)="changeOrderBy(39)">Planning Downtime Start
                                <div  *ngIf="!(this.data.OrderById == 39 && (this.data.OrderByAsc || !this.data.OrderByAsc))" class="combined-icon">
                                    <i class="fa fa-long-arrow-up fa-up" aria-hidden="true"></i>
                                    <i class="fa fa-long-arrow-down fa-down" aria-hidden="true"></i>
                                </div>
                                <i *ngIf="this.data.OrderById == 39 && OrderAsc === 1" class="fa fa-sort-amount-up-alt"></i>
                                <i *ngIf="this.data.OrderById == 39 && OrderAsc === 0" class="fa fa-sort-amount-down"></i>
                            </th>

                            <th *ngIf="this.data.Columns[40]" class="divLink" (click)="changeOrderBy(40)">Planning Downtime End
                                <div  *ngIf="!(this.data.OrderById == 40 && (this.data.OrderByAsc || !this.data.OrderByAsc))" class="combined-icon">
                                    <i class="fa fa-long-arrow-up fa-up" aria-hidden="true"></i>
                                    <i class="fa fa-long-arrow-down fa-down" aria-hidden="true"></i>
                                </div>
                                <i *ngIf="this.data.OrderById == 40 && OrderAsc === 1" class="fa fa-sort-amount-up-alt"></i>
                                <i *ngIf="this.data.OrderById == 40 && OrderAsc === 0" class="fa fa-sort-amount-down"></i>
                            </th>

                            <th *ngIf="this.data.Columns[42]" class="divLink" (click)="changeOrderBy(42)">Approval Status
                                <div  *ngIf="!(this.data.OrderById == 42 && (this.data.OrderByAsc || !this.data.OrderByAsc))" class="combined-icon">
                                    <i class="fa fa-long-arrow-up fa-up" aria-hidden="true"></i>
                                    <i class="fa fa-long-arrow-down fa-down" aria-hidden="true"></i>
                                </div>
                                <i *ngIf="this.data.OrderById == 42 && OrderAsc === 1" class="fa fa-sort-amount-up-alt"></i>
                                <i *ngIf="this.data.OrderById == 42 && OrderAsc === 0" class="fa fa-sort-amount-down"></i>
                            </th>

                            <ng-container *ngFor="let col of customColumns">
                                <th class="divLink">
                                  {{col.name}}

                                </th>
                            </ng-container>

                        </tr>
                    </thead>
                    <tbody>
                        <tr *ngFor="let request of this.data.Results " [class.sdesk-filter-unread]="!request.DateTimeLastRead">
                            <td>
                                <div class="form-check">
                                    <input [ngModel]="this.selectedTickets.isSelected(request.Id)" (change)="this.selectedTickets.toggle(request.Id)" class="form-check-input" type="checkbox" id="filterViewTable">
                                    <label class="form-check-label" for="flexCheckDefault"></label>
                                </div>
                            </td>
                            <td *ngIf="this.data.Columns[11]" [routerLink]="['/agent/request/', request.Id]" class="divLink">{{ isCmdbEnabled() ? request.AssetNo : request.LocalRequestId }}</td>
                            <td *ngIf="this.data.Columns[28]">{{request.RequestTypeName}}</td>
                            <td *ngIf="this.data.Columns[10]" [routerLink]="['/agent/request/', request.Id]" class="divLink">{{request.Requester}}</td>
                            <td *ngIf="this.data.Columns[41]" [routerLink]="['/agent/request/', request.Id]" class="divLink">{{request.RequesterEmail}}</td>
                            <td *ngIf="this.data.Columns[9]" [routerLink]="['/agent/request/', request.Id]" class="divLink">{{request.Subject}}</td>
                            <td *ngIf="this.data.Columns[2]">{{request.Status}}</td>
                            <td *ngIf="this.data.Columns[3]">{{request.Priority}}</td>
                            <td *ngIf="this.data.Columns[20]">{{request.GroupName}}</td>
                            <td *ngIf="this.data.Columns[4] && !isCmdbEnabled()">{{request.Agent}}</td>
                            <td *ngIf="this.data.Columns[14]">
                                {{ !this.data?.ReadOnlyFilter ? (parseDate(request.DateTimeUpdated) | amTimeAgo) : (parseReadOnlyDate(request.DateTimeUpdated) | amTimeAgo) }}
                            </td>
                            <td *ngIf="this.data.Columns[8]">
                                {{ !this.data?.ReadOnlyFilter ? (parseDate(request.DateTimeCreated) | amTimeAgo) : (parseReadOnlyDate(request.DateTimeCreated) | amTimeAgo) }}
                            </td>
                            <td *ngIf="this.data.Columns[12]">{{request.DateTimeResponded }}</td>
                            <td *ngIf="this.data.Columns[13]">{{request.DateTimeResolved }}</td>
                            <td *ngIf="this.data.Columns[15]">{{request.SlaResponseDueDate }}</td>
                            <td *ngIf="this.data.Columns[16]">{{request.SlaResolutionDueDate }}</td>
                            <td *ngIf="this.data.Columns[17]">{{request.SurveyResult}}</td>
                            <td *ngIf="this.data.Columns[18]">{{request.SurveyStatus}}</td>
                            <td *ngIf="this.data.Columns[19]">{{request.Service}}</td>
                            <td *ngIf="this.data.Columns[21]">{{request.Customer}}</td>
                            <td *ngIf="this.data.Columns[22]">{{request.Impact}}</td>
                            <td *ngIf="this.data.Columns[23]">{{request.Urgency}}</td>
                            <td *ngIf="this.data.Columns[24]">{{request.ResolutionCode}}</td>
                            <td *ngIf="this.data.Columns[25]">{{request.DateTimeRequesterResponded }}</td>
                            <td *ngIf="this.data.Columns[26]">{{request.DateTimeLastStatusChange }}</td>
                            <td *ngIf="this.data.Columns[27]">{{request.ChangeState}}</td>
                            <td *ngIf="this.data.Columns[29]">
                                <span *ngIf="!request.NextSlaPaused && request.NextSlaOverdue" [className]="request.NextSlaOverdue? 'SDeskSlaIndicator-Red' : 'SDeskSlaIndicator-Green'" class="label m-0">{{ !this.data?.ReadOnlyFilter ? (parseDate(request.NextSlaDueDateTime) | amTimeAgo) : (parseReadOnlyDate(request.NextSlaDueDateTime) | amTimeAgo) }}</span>
                                <span *ngIf="request.NextSlaPaused" class="label SDeskSlaIndicator-Paused m-0">Paused</span>
                            </td>
                            <td *ngIf="this.data.Columns[30]">{{request.AssetType}}</td>
                            <td *ngIf="this.data.Columns[31]">{{request.AssetSerialNo}}</td>
                            <td *ngIf="this.data.Columns[32]">{{request.AssetImei}}</td>
                            <td *ngIf="this.data.Columns[33]">{{request.AssetManufacturer}}</td>
                            <td *ngIf="this.data.Columns[34]">{{request.AssetModel}}</td>
                            <td *ngIf="this.data.Columns[35]">{{request.AssetCost}}</td>
                            <td *ngIf="this.data.Columns[36]">{{request.Planning_ScheduledStart }}</td>
                            <td *ngIf="this.data.Columns[37]">{{request.Planning_ScheduledEnd }}</td>
                            <td *ngIf="this.data.Columns[38]">{{request.Requested_Date }}</td>
                            <td *ngIf="this.data.Columns[39]">{{request.Planning_DowntimeStart }}</td>
                            <td *ngIf="this.data.Columns[40]">{{request.Planning_DowntimeEnd }}</td>
                            <td *ngIf="this.data.Columns[42]" >{{request.ApprovalStatus}}</td>


                            <!-- <ng-container *ngIf="request.customFieldValues">
            <td *ngFor="let customFieldValues of filterCustomFieldValues(request.customFieldValues)">
              {{ customFieldValues.value }}
            </td>
          </ng-container> -->

                            <ng-container *ngIf="request.customFieldValues">
                                <td *ngFor="let col of customColumns">
                                    {{ filterCustomFieldValues(request.customFieldValues, col)}}
                                </td>
                            </ng-container>

                        </tr>
                    </tbody>
                </table>
            </div>

        </div>
    </div>
</div>
