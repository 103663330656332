<div class="SDeskSidePanel-GreyBackground"></div>

<app-blade-right *ngIf="!isLoading && !isLoadingOptions && !isLoadingSlaTemplates" size="blade-xlarge">
  <div class="p-10 p-b-0">
    <i (click)="this.close()" class="fas fa-times fa-2x float-end divLink"></i>
    <h3>{{this.loadedId ? 'Edit' : 'Create'}} Customer</h3>
  </div>
  <hr>

  <div class="container p-0">

    <!-- Dialog Tabs -->
    <div class="col-12 m-b-20 ">
      <ul class="nav dialogTabs">
        <li class="nav-item divLink" (click)="selectedTab = 'details'">
          <a class="nav-link-thread" [class.active]="selectedTab == 'details'">
            Customer Details
          </a>
        </li>

        <li class="nav-item divLink" (click)="selectedTab = 'businessHours'">
          <a class="nav-link-thread" [class.active]="selectedTab == 'businessHours'">
            Business Hours
          </a>
        </li>

        <li class="nav-item divLink" (click)="selectedTab = 'locations'">
          <a class="nav-link-thread" [class.active]="selectedTab == 'locations'">
            Locations
          </a>
        </li>

        <li class="nav-item divLink" (click)="selectedTab = 'contact'">
          <a class="nav-link-thread" [class.active]="selectedTab == 'contact'">
            Contact Details
          </a>
        </li>

        <li class="nav-item divLink" (click)="selectedTab = 'sla'">
          <a class="nav-link-thread" [class.active]="selectedTab == 'sla'">
            SLA
          </a>
        </li>

        <li class="nav-item divLink" (click)="selectedTab = 'email'">
          <a class="nav-link-thread" [class.active]="selectedTab == 'email'">
            Email Settings
          </a>
        </li>

        <li class="nav-item divLink" (click)="selectedTab = 'intune'">
          <a class="nav-link-thread" [class.active]="selectedTab == 'intune'">
            Intune Device Sync
          </a>
        </li>

      </ul>
    </div>

    <!-- Tab Content -->

    <div *ngIf="selectedTab == 'details'">

      <div class="row">
        <div class="col-sm-3 formHeader alignMiddle p-10">
          Name <span class="text-danger">*</span>
        </div>
        <div class="col-sm p-10">
          <input [(ngModel)]="loadedData.Name" type="text" class="form-control" id="Name" placeholder="Name"
                 autocomplete="off">
        </div>
      </div>

      <div class="row">
        <div class="col-sm-3 formHeader alignMiddle p-10">
          Customers Timezone <span class="text-danger">*</span>
        </div>
        <div class="col-sm p-10">
          <ng-select [items]="options.Timezones"
                     [(ngModel)]="loadedData.Timezone"
                     [clearable]="false">
          </ng-select>
        </div>
      </div>

      <hr>

      <div class="row">
        <div class="col-sm-3 formHeader p-10">
          Associated Domains
        </div>
        <div class="col-sm p-10">

          <div class="input-group">
            <input [(ngModel)]="domainToAdd" type="text" class="form-control" id="domainToAdd" placeholder=""
                   autocomplete="off">
            <div (click)="addDomain()" class="btn btn-primary">Add</div>
            
            <div *ngIf="showToast" class="toast show" style="position: absolute; top: 100%; left: 0; z-index: 11; width: 50%;">
              <div class="toast-header" style="justify-content: space-between;">
                <strong class="mr-auto">Notification</strong>
                <button type="button" class="close" style="margin-left: 20px; padding: 0;" (click)="showToast = false">
                  <span aria-hidden="true">&times;</span>
                </button>
              </div>
              <div class="toast-body">
                Please enter a domain to add.
              </div>
            </div>

          </div>

          <ul class="list-group">
            <li class="list-group-item" *ngFor="let domain of loadedData.Domains; let i = index">
              {{ domain }}
              <i class="far fa-trash-alt float-end m-r-20 divLink" (click)="removeDomain(i)" aria-hidden="true"></i>
            </li>
          </ul>

        </div>
      </div>

      <hr>

    </div>

    <div *ngIf="selectedTab == 'businessHours'">

      <div class="row">
        <div class="col p-10">

          <div class="row">
            <div class="col-sm-4 formHeader alignMiddle p-10">
              Override Business Hours?
            </div>
            <div class="col-sm p-10">
              <input class="form-check-input" type="checkbox" [(ngModel)]="loadedData.CustomWorkingHours">
              <label>
              </label>
            </div>
          </div>

          <hr>

          <div *ngIf="loadedData.CustomWorkingHours" class="col">

            <div class="row">
              <div class="col-sm-2"></div>
              <div class="col-sm-2">Open</div>
              <div class="col-sm-2">24h</div>
              <div class="col-sm-3">Start</div>
              <div class="col-sm-3">Finish</div>
            </div>

            <div class="row">
              <div class="col-sm-2 p-5">Monday</div>
              <div class="col-sm-2 p-5">
                <div class="checkbox checkbox-primary">
                  <input class="form-check-input" type="checkbox" [(ngModel)]="loadedData.Monday.Enabled"
                         (change)="updateDayData('Monday')">
                  <label>
                  </label>
                </div>
              </div>
              <div class="col-sm-2 p-5">
                <div class="checkbox checkbox-primary">
                  <input class="form-check-input" type="checkbox" *ngIf="loadedData.Monday.Enabled"
                         [(ngModel)]="loadedData.Monday.TwentyFour" (change)="updateDayData('Monday')">
                  <label>
                  </label>
                </div>
              </div>
              <div class="col-sm-3">
                <input size="4" class="form-control input-sm" type="time" [(ngModel)]="loadedData.Monday.Start"
                       *ngIf="!loadedData.Monday.TwentyFour && loadedData.Monday.Enabled"
                       [disabled]="loadedData.Monday.TwentyFour"></div>
              <div class="col-sm-3">
                <input size="4" class="form-control input-sm" type="time" [(ngModel)]="loadedData.Monday.Finish"
                       *ngIf="!loadedData.Monday.TwentyFour && loadedData.Monday.Enabled"
                       [disabled]="loadedData.Monday.TwentyFour"></div>
            </div>

            <div class="row">
              <div class="col-sm-2 p-5">Tuesday</div>
              <div class="col-sm-2 p-5">
                <div class="checkbox checkbox-primary">
                  <input class="form-check-input" type="checkbox" [(ngModel)]="loadedData.Tuesday.Enabled"
                         (change)="updateDayData('Tuesday')">
                  <label>
                  </label>
                </div>
              </div>
              <div class="col-sm-2 p-5">
                <div class="checkbox checkbox-primary">
                  <input class="form-check-input" type="checkbox" *ngIf="loadedData.Tuesday.Enabled"
                         [(ngModel)]="loadedData.Tuesday.TwentyFour" (change)="updateDayData('Tuesday')">
                  <label>
                  </label>
                </div>
              </div>
              <div class="col-sm-3">
                <input size="4" class="form-control input-sm" type="time" [(ngModel)]="loadedData.Tuesday.Start"
                       *ngIf="!loadedData.Tuesday.TwentyFour && loadedData.Tuesday.Enabled"
                       [disabled]="loadedData.Tuesday.TwentyFour"></div>
              <div class="col-sm-3">
                <input size="4" class="form-control input-sm" type="time" [(ngModel)]="loadedData.Tuesday.Finish"
                       *ngIf="!loadedData.Tuesday.TwentyFour && loadedData.Tuesday.Enabled"
                       [disabled]="loadedData.Tuesday.TwentyFour"></div>
            </div>

            <div class="row">
              <div class="col-sm-2 p-5">Wednesday</div>
              <div class="col-sm-2 p-5">
                <div class="checkbox checkbox-primary">
                  <input class="form-check-input" type="checkbox" [(ngModel)]="loadedData.Wednesday.Enabled"
                         (change)="updateDayData('Wednesday')">
                  <label>
                  </label>
                </div>
              </div>
              <div class="col-sm-2 p-5">
                <div class="checkbox checkbox-primary">
                  <input class="form-check-input" type="checkbox" *ngIf="loadedData.Wednesday.Enabled"
                         [(ngModel)]="loadedData.Wednesday.TwentyFour" (change)="updateDayData('Wednesday')">
                  <label>
                  </label>
                </div>
              </div>
              <div class="col-sm-3">
                <input size="4" class="form-control input-sm" type="time" [(ngModel)]="loadedData.Wednesday.Start"
                       *ngIf="!loadedData.Wednesday.TwentyFour && loadedData.Wednesday.Enabled"
                       [disabled]="loadedData.Wednesday.TwentyFour"></div>
              <div class="col-sm-3">
                <input size="4" class="form-control input-sm" type="time" [(ngModel)]="loadedData.Wednesday.Finish"
                       *ngIf="!loadedData.Wednesday.TwentyFour && loadedData.Wednesday.Enabled"
                       [disabled]="loadedData.Wednesday.TwentyFour"></div>
            </div>

            <div class="row">
              <div class="col-sm-2 p-5">Thursday</div>
              <div class="col-sm-2 p-5">
                <div class="checkbox checkbox-primary">
                  <input class="form-check-input" type="checkbox" [(ngModel)]="loadedData.Thursday.Enabled"
                         (change)="updateDayData('Thursday')">
                  <label>
                  </label>
                </div>
              </div>
              <div class="col-sm-2 p-5">
                <div class="checkbox checkbox-primary">
                  <input class="form-check-input" type="checkbox" *ngIf="loadedData.Thursday.Enabled"
                         [(ngModel)]="loadedData.Thursday.TwentyFour" (change)="updateDayData('Thursday')">
                  <label>
                  </label>
                </div>
              </div>
              <div class="col-sm-3">
                <input size="4" class="form-control input-sm" type="time" [(ngModel)]="loadedData.Thursday.Start"
                       *ngIf="!loadedData.Thursday.TwentyFour && loadedData.Thursday.Enabled"
                       [disabled]="loadedData.Thursday.TwentyFour"></div>
              <div class="col-sm-3">
                <input size="4" class="form-control input-sm" type="time" [(ngModel)]="loadedData.Thursday.Finish"
                       *ngIf="!loadedData.Thursday.TwentyFour && loadedData.Thursday.Enabled"
                       [disabled]="loadedData.Thursday.TwentyFour"></div>
            </div>

            <div class="row">
              <div class="col-sm-2 p-5">Friday</div>
              <div class="col-sm-2 p-5">
                <div class="checkbox checkbox-primary">
                  <input class="form-check-input" type="checkbox" [(ngModel)]="loadedData.Friday.Enabled"
                         (change)="updateDayData('Friday')">
                  <label>
                  </label>
                </div>
              </div>
              <div class="col-sm-2 p-5">
                <div class="checkbox checkbox-primary">
                  <input class="form-check-input" type="checkbox" *ngIf="loadedData.Friday.Enabled"
                         [(ngModel)]="loadedData.Friday.TwentyFour" (change)="updateDayData('Friday')">
                  <label>
                  </label>
                </div>
              </div>
              <div class="col-sm-3">
                <input size="4" class="form-control input-sm" type="time" [(ngModel)]="loadedData.Friday.Start"
                       *ngIf="!loadedData.Friday.TwentyFour && loadedData.Friday.Enabled"
                       [disabled]="loadedData.Friday.TwentyFour"></div>
              <div class="col-sm-3">
                <input size="4" class="form-control input-sm" type="time" [(ngModel)]="loadedData.Friday.Finish"
                       *ngIf="!loadedData.Friday.TwentyFour && loadedData.Friday.Enabled"
                       [disabled]="loadedData.Friday.TwentyFour"></div>
            </div>

            <div class="row">
              <div class="col-sm-2 p-5">Saturday</div>
              <div class="col-sm-2 p-5">
                <div class="checkbox checkbox-primary">
                  <input class="form-check-input" type="checkbox" [(ngModel)]="loadedData.Saturday.Enabled"
                         (change)="updateDayData('Saturday')">
                  <label>
                  </label>
                </div>
              </div>
              <div class="col-sm-2 p-5">
                <div class="checkbox checkbox-primary">
                  <input class="form-check-input" type="checkbox" *ngIf="loadedData.Saturday.Enabled"
                         [(ngModel)]="loadedData.Saturday.TwentyFour" (change)="updateDayData('Saturday')">
                  <label>
                  </label>
                </div>
              </div>
              <div class="col-sm-3">
                <input size="4" class="form-control input-sm" type="time" [(ngModel)]="loadedData.Saturday.Start"
                       *ngIf="!loadedData.Saturday.TwentyFour && loadedData.Saturday.Enabled"
                       [disabled]="loadedData.Saturday.TwentyFour"></div>
              <div class="col-sm-3">
                <input size="4" class="form-control input-sm" type="time" [(ngModel)]="loadedData.Saturday.Finish"
                       *ngIf="!loadedData.Saturday.TwentyFour && loadedData.Saturday.Enabled"
                       [disabled]="loadedData.Saturday.TwentyFour"></div>
            </div>

            <div class="row">
              <div class="col-sm-2 p-5">Sunday</div>
              <div class="col-sm-2 p-5">
                <div class="checkbox checkbox-primary">
                  <input class="form-check-input" type="checkbox" [(ngModel)]="loadedData.Sunday.Enabled"
                         (change)="updateDayData('Sunday')">
                  <label>
                  </label>
                </div>
              </div>
              <div class="col-sm-2 p-5">
                <div class="checkbox checkbox-primary">
                  <input class="form-check-input" type="checkbox" *ngIf="loadedData.Sunday.Enabled"
                         [(ngModel)]="loadedData.Sunday.TwentyFour" (change)="updateDayData('Sunday')">
                  <label>
                  </label>
                </div>
              </div>
              <div class="col-sm-3">
                <input size="4" class="form-control input-sm" type="time" [(ngModel)]="loadedData.Sunday.Start"
                       *ngIf="!loadedData.Sunday.TwentyFour && loadedData.Sunday.Enabled"
                       [disabled]="loadedData.Sunday.TwentyFour"></div>
              <div class="col-sm-3">
                <input size="4" class="form-control input-sm" type="time" [(ngModel)]="loadedData.Sunday.Finish"
                       *ngIf="!loadedData.Sunday.TwentyFour && loadedData.Sunday.Enabled"
                       [disabled]="loadedData.Sunday.TwentyFour"></div>
            </div>

          </div>
        </div>
      </div>


    </div>

    <div *ngIf="selectedTab == 'locations'">
      <p *ngIf="!this.loadedId">Please save before locations can be added.</p>
      <table *ngIf="this.loadedId" class="table align-middle tableStyle">
        <thead>
        <tr>
          <th class="">Name</th>
          <th class="">Timezone</th>
          <th class="">
            <div (click)="createLocation()" class="btn btn-primary float-end">Create</div>
          </th>
        </tr>
        </thead>
        <tbody>
        <tr *ngFor="let row of loadedData.Locations">
          <td><span class="divLink" (click)="editLocation(row.Id)">{{row.Name}}</span></td>
          <td>{{row.Timezone}}</td>
          <td>
            <i class="far fa-trash-alt float-end m-r-20 divLink" (click)="deleteLocation(row.Id)"></i>
            <i class="fas fa-cog float-end m-r-20 divLink" (click)="editLocation(row.Id)"></i>

          </td>
        </tr>
        </tbody>
      </table>
    </div>

    <div *ngIf="selectedTab == 'contact'">

      <p><strong>Major Incident Contact</strong></p>
      <p>When there is a Major Incident affecting this Customer, the below contact will be notified:</p>

      <div class="row">
        <div class="col-sm-3 formHeader alignMiddle p-10">
          Contact Name
        </div>
        <div class="col-sm p-10">
          <input [(ngModel)]="loadedData.ContactName" type="text" class="form-control" id="ContactName" placeholder=""
                 autocomplete="off">
        </div>
      </div>

      <div class="row">
        <div class="col-sm-3 formHeader alignMiddle p-10">
          Contact Email Address
        </div>
        <div class="col-sm p-10">
          <input [(ngModel)]="loadedData.ContactEmailAddress" type="email" class="form-control" id="ContactEmailAddress"
                 placeholder="" autocomplete="off">
        </div>
      </div>

      <div class="row">
        <div class="col-sm-3 formHeader alignMiddle p-10">
          Contact Mobile Number (Used for SMS Notifications)
        </div>
        <div class="col-sm p-10">
          <input [(ngModel)]="loadedData.PhoneNumber" type="email" class="form-control" id="PhoneNumber" placeholder=""
                 autocomplete="off">
        </div>
      </div>


    </div>

    <div *ngIf="selectedTab == 'email'">

      <p><strong>This is an advanced setting</strong></p>
      <p>When e-mailing this customer using SDesk, override the send from e-mail address with these: </p>

      <div class="row">
        <div class="col-sm-3 formHeader alignMiddle p-10">
          From Name <span class="text-danger">*</span>
        </div>
        <div class="col-sm p-10">
          <input [(ngModel)]="loadedData.OverrideFromAddressName" type="text" class="form-control" id="OverrideFromAddressName"
                 placeholder="Name" autocomplete="off">
        </div>
      </div>

      <div class="row">
        <div class="col-sm-3 formHeader alignMiddle p-10">
          From Email Address <span class="text-danger">*</span>
        </div>
        <div class="col-sm p-10">
          <input [(ngModel)]="loadedData.OverrideFromAddress" type="text" class="form-control" id="OverrideFromAddress"
                 placeholder="Name" autocomplete="off">
        </div>
      </div>


    </div>

    <div *ngIf="selectedTab == 'sla'">
      <div class="p-10 p-b-0 m-t-20">
        <h4>Override SLA's</h4>
      </div>
      <hr>


      <div *ngFor="let slaLink of this.loadedData.SlaLinks" class="row">
        <div class="col-sm-3 formHeader alignMiddle p-10">
          {{slaLink.TypeName}}
        </div>
        <div class="col-sm p-10">

          <ng-select [items]="slaTemplates"
                     bindLabel="Name"
                     bindValue="Id"
                     [(ngModel)]="slaLink.SlaId"
                     [clearable]="true">
          </ng-select>

        </div>
      </div>
    </div>

    <div *ngIf="selectedTab == 'intune'">

      <div *ngIf="loadedData.AssetManagement_IntuneTenantId">
        <p>Intune Linked</p>
        <div (click)="setupIntuneSync()" class="button-primary divLink" style="
    padding: 12px;
    background-color: #4a6987;
    border-radius: 10px;
    width: 250px;
    color: white;
    margin-top: 10px;
">Link again</div>
      </div>

      <div *ngIf="!loadedData.AssetManagement_IntuneTenantId">
        <p>This will redirect you to Azure AD to confirm access.</p>
        <div (click)="setupIntuneSync()" class="button-primary divLink" style="
    padding: 12px;
    background-color: #4a6987;
    border-radius: 10px;
    width: 250px;
    color: white;
    margin-top: 10px;
">Authorise Access to Intune</div>
      </div>

    </div>

    <div class="m-t-20">
      <div (click)="save()" class="btn btn-primary">Save</div>
      <div (click)="close()" class="btn btn-cancel">Cancel</div>
    </div>

  </div>
</app-blade-right>
