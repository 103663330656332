<app-blade-right [size]="'blade-large'">
  <div class="p-10">
    <i (click)="this.close()" class="fas fa-times fa-2x float-end p-10 divLink"></i>
    <h3>Major Incident - {{this.data.taskObject.TaskName}}</h3>
    <p>This update will be sent to all linked incidents, all service contacts and all customer contacts. Your template will include the greeting, list of services affected and a signature. Please do not include these in the update.

    </p>
    <hr>

    <app-loading-spinner *ngIf="loading"></app-loading-spinner>
    <div *ngIf="!this.loading">

      <div class="form-group m-b-10">
        <div class="col-sm-12">
                    <textarea rows="8" id="UpdateText" [(ngModel)]="updateText" class="form-control"
                              style="width: 100%;"></textarea>
        </div>
      </div>

      <div class="">

        <div class="checkbox checkbox-primary m-b-10">
          <input id="SendSmsUpdate" type="checkbox" [(ngModel)]="sendSmsUpdate" class="form-check-input">
          <label for="SendSmsUpdate" class="m-l-5 m-t-3">
            Send SMS Update?
          </label>
        </div>

        <div class="form-group m-b-10" *ngIf="sendSmsUpdate">
          <label for="name" class="m-l-5 m-t-3">SMS Update</label>
          <textarea rows="2" id="SmsUpdateText" [(ngModel)]="smsUpdateText" class="form-control"
                    style="width: 100%;"></textarea>
        </div>

        <div class="checkbox checkbox-primary m-b-10">
          <input id="IsComplete" type="checkbox" [(ngModel)]="markTaskComplete" class="form-check-input">
          <label for="IsComplete" class="m-l-5 m-t-3">
            Mark task as complete
          </label>
        </div>

        <div class="checkbox checkbox-primary m-b-10">
          <input id="SendToSlack" type="checkbox" [(ngModel)]="sendUpdateToSlack" class="form-check-input">
          <label for="SendToSlack" class="m-l-5 m-t-3">
            Send to Slack?
          </label>
        </div>

        <div class="row m-b-10">
          <label class="col-sm-3">
            <div class="checkbox checkbox-primary m-t-0">

              <input id="ShowNextUpdateOptions" type="checkbox" [(ngModel)]="showNextUpdateOptions" class="form-check-input">
              <label for="ShowNextUpdateOptions" class="m-l-5 m-t-3">
                Schedule Next Update?
              </label>

            </div>
          </label>
          <div class="col-md-1 col-sm-4" *ngIf="showNextUpdateOptions">
            <input type="number" id="NextUpdateValue" [(ngModel)]="nextUpdateValue" class="form-control input input-sm select2" placeholder="" min="1">
          </div>
          <div class="col-md-2 col-sm-8" *ngIf="showNextUpdateOptions">

            <ng-select [items]="nextUpdateOptions"
                       bindLabel="name"
                       bindValue="id"
                       [(ngModel)]="this.nextUpdateType">
            </ng-select>
          </div>
        </div>

      </div>

      <p class="text m-b-15 font-13">Please preview your update before sending.</p>



      <div class="m-t-15">
        <button (click)="save()" type="submit" class="btn btn-primary">
          Send
        </button>
        <button (click)="close()" type="button" class="btn btn-cancel">
          Cancel
        </button>
      </div>

    </div>

  </div>
</app-blade-right>
