import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {TestServiceService} from "../test-service.service";

@Component({
  selector: 'app-child',
  templateUrl: './child.component.html',
  styleUrls: ['./child.component.css']
})
export class ChildComponent implements OnInit {

  @Input() Message: string;
  @Output() messageEvent = new EventEmitter<string>();

  sendMessage() {
    this.messageEvent.emit("Fuck Off!")
  }

  setToken() {
    console.log("Calling child set token")
    this.testService.setAuthToken("Setting Auth Token ro something else?")
  }

  Token = '';

  constructor(private testService: TestServiceService) {
    this.Token = testService.returnAuthToken();
  }

  ngOnInit(): void {
  }

}
