import {Component, ViewContainerRef} from '@angular/core';
import {Location} from '@angular/common'
import {UserPermissionsService} from "../services/user-permissions/user-permissions.service";
import {AuthenticationService} from "../services/authentication/authentication.service";
import {BrandingService} from "../services/branding/branding.service";
import {RequestTypeService} from "../services/request-type/request-type.service";
import {SelectionModel} from "@angular/cdk/collections";
import {DialogService} from "../services/dialog-service/dialog.service";
import {NewTicketBladeComponent} from "../shared/new-ticket-blade/new-ticket-blade.component";
import {FilterService} from "../services/api/filter/filter.service";
import {FilterCreateEditBladeComponent} from "../shared/filter-create-edit-blade/filter-create-edit-blade.component";
import {ActivatedRoute, Router} from "@angular/router";
import {RaiseABugDialogComponent} from "../shared/raise-a-bug-dialog/raise-a-bug-dialog.component";
import {InstanceParamsService} from "../services/instance-params/instance-params.service";
import {ApiService} from '../services/api/api.service';
import {RecordTimeComponent} from '../shared/record-time/record-time.component';
import {OptionsApiService} from '../services/api/options/options-api.service';
import {UserSearchService} from '../services/user-search.service';
import {interval, Subscription} from 'rxjs';
import {ChatApiService} from '../services/api/agent/chat/chat-api';
import {
  AdminCustomersComponentDialog
} from './pages/admin/customers/admin-customers/admin-customers-create-edit-dialog/admin-customers-create-edit-dialog.component';
import {ApiKeyDialogComponent} from './pages/api-key-dialog/api-key-dialog.component';
import { AssetRegisterComponentDialog } from './pages/assets/asset-register/asset-register-create-edit-dialog/asset-register-create-edit-dialog.component';
import { ApiAdminUsersService } from '../services/api/admin/admin-users-api/admin-users-api.service';
import { SharedService } from '../shared/service/shared-service';

@Component({
  selector: 'app-agent',
  templateUrl: './agent.component.html',
  styleUrls: ['./agent.component.css']
})
export class AgentComponent {

  private updatePoller: Subscription;

  sideBarNavOpen = true;
  brandingSubscription;
  userPermissionsSubscription;
  requestTypes = [];
  adminrequestypes;
  filters;
  instanceParams;
  timerEnabled = false;
  chatNotifications;
  userName: string ;
  allRequestTypes;
  public accessPermissions;

  private subscription: Subscription;
  public navigationExpanded = new SelectionModel(
    true,   // multiple selection or not
    [] // initial selected values
  );

  constructor(private userPermissions: UserPermissionsService,
              private auth: AuthenticationService,
              private brandingService: BrandingService,
              private requestType: RequestTypeService,
              private instanceParamsService: InstanceParamsService,
              private dialogService: DialogService,
              private viewReference: ViewContainerRef,
              private filterApi: FilterService,
              private router: Router,
              public location: Location,
              private api: ApiService,
              private apiOptions: OptionsApiService,
              private userSearchService: UserSearchService,
              private chatApi: ChatApiService,
              private route: ActivatedRoute,
              private apiAdminUsersService: ApiAdminUsersService,
              private sharedService: SharedService
  ) {

    // Subscribe to request type updates
    this.sharedService.requestTypeUpdated$.subscribe(() => {
      this.getrequesttypes(this.accessPermissions);
  });

    // User must always be logged in to see this page
    auth.checkLoggedIn();

    this.checkTimerEnabled();

    // Populate user cache
    userSearchService.refreshUsers();

    // Subscribe to branding service
    brandingService.brandingSubscription.subscribe(data => this.brandingSubscription = data);

    // Refresh user permissions before proceeding
    userPermissions.refreshUserPermissions();
    userPermissions.userPermissionsSubscription.subscribe(data => {
      this.userPermissionsSubscription = data
      if(this.userPermissionsSubscription)
        {
          this.getAccessDetails(this.userPermissionsSubscription.AgentId);
        }
     
    });
    // Refresh request types before

    // All request types 
    this.apiOptions.AllRequestTypes().then(response => {
      this.allRequestTypes = response
      console.log(" all requesttypes",this.allRequestTypes)
    });

    // Refresh Instance Parameters
    instanceParamsService.refreshInstanceParams();
    instanceParamsService.instanceParamsSubscription.subscribe(data => {
      this.instanceParams = data
      if(this.instanceParams?.ChatBotEnabled){
        this.refreshChatNotifications();
        this.startPollingForChatNotifications();
      }
    });

    // Get list of filters
    this.getFilters();
  }

  ngOnInit():void{
    this.subscription = this.sharedService.invokeMethod$.subscribe(() => {
      this.getFilters();
    });

    const usernameFromQueryParam = this.route.snapshot.queryParamMap.get('username');
    this.userName = usernameFromQueryParam || this.auth.getUserName();
    localStorage.setItem('userName', this.userName);
  }

  getAccessDetails(agentId){
    this.apiAdminUsersService.get(agentId).then(response => {
      this.accessPermissions = response;
      this.getrequesttypes(this.accessPermissions);
    })
  }

  getrequesttypes(accessPermissions)
  {
    this.apiOptions.AllRequestTypesNav().then(response => {
      let requesttypes = response;
       console.log("requesttypes",requesttypes)
       this.adminrequestypes = requesttypes;
       if(accessPermissions.AssetManagementViewOnly == 0 && accessPermissions.AssetManagementAdmin == 0)
         {
          console.log("admin false and view false")
           this.requestTypes = requesttypes.filter(item => item.CmdbEnabled == 0);
         }
         else
         {
           this.requestTypes = requesttypes;
         }
     });
  }

  isCmdbEnabled(){
    const hasCmdbEnabled = this.requestTypes.some(requestType => requestType.CmdbEnabled === 1);
    // for(const item of this.requestTypes){
    //   if(item.CmdbEnabled === 1){
    //     return true
    //   }
    // }
    // return false;
      return hasCmdbEnabled;
  }

  openApiKeyDialog(){
    // Replace null with Component
    this.dialogService.createDialog(ApiKeyDialogComponent, null, this.viewReference);
  }

  createFilter(requestTypeId, cmdbEnabled) {
    console.log("calling createFilter");
    this.dialogService.createDialog(FilterCreateEditBladeComponent, {
      create: true,
      requestTypeId: requestTypeId,
      filterId: null,
      cmdbEnabled: cmdbEnabled
    }, this.viewReference)
      .then((dialogSaved: any) => {

        this.getFilters();

        // Returns Created Id
        this.router.navigate(['/agent/filter/', dialogSaved.successId]);

        console.log("dialogSaved:" + dialogSaved);
      }, dialogCancelled => {

        this.getFilters();

        console.log("dialogCancelled:" + dialogCancelled);
      })
  }

  startPollingForChatNotifications() {
    if(!this.updatePoller){
      this.updatePoller = interval(15000).subscribe(() => {
        this.refreshChatNotifications();
      });
    }
  }

  refreshChatNotifications(){
    this.chatApi.pollNotifications().then(response => {

      let currentChatNotifications = {...this.chatNotifications};

      this.chatNotifications = response;

      if(currentChatNotifications && this.chatNotifications.UnassignedConversations > currentChatNotifications.UnassignedConversations){
        this.playAudio();
      }

      if(currentChatNotifications && this.chatNotifications.UnreadMessageCount > currentChatNotifications.UnreadMessageCount){
        this.playAudio();
      }
    })
  }

  logoff() {
    this.auth.logout();
  }

  checkTimerEnabled(){
    this.api.get("/api/timer/settings").then(response => {
      console.log(response);
      if(response.TimeTrackingEnabled){
        this.timerEnabled = true;
      }
    })
  }

  createTicket(requestTypeId, cmdbEnabled) {
    if (cmdbEnabled ==1){
      var newAsset = {
        "assetRequestType": true,
        "assetRequestTypeId": requestTypeId
      }
      this.dialogService.createDialog(AssetRegisterComponentDialog, newAsset, this.viewReference)
      .then(dialogSaved => {

      }, dialogCancelled => {

      })
    }else {
      this.dialogService.createDialog(NewTicketBladeComponent, requestTypeId, this.viewReference)
      .then(dialogSaved => {

      }, dialogCancelled => {

      })

    }
    
  }

  raiseBug() {
    this.dialogService.createDialog(RaiseABugDialogComponent, null, this.viewReference)
      .then(dialogSaved => {

      }, dialogCancelled => {

      })
  }

  recordTime() {
    this.dialogService.createDialog(RecordTimeComponent, null, this.viewReference)
      .then(dialogSaved => {

      }, dialogCancelled => {

      })
  }

  getFilters() {
    this.filterApi.listFilters().then(data => this.filters = data);
  }

  playAudio() {
    let audio = new Audio();
    audio.src = "/assets/notification.wav";
    audio.load();
    audio.play();
  }

  returnFiltersForRequestType(requestTypeId) {
    return this.filters.filter(filter => filter.RequestTypeId == requestTypeId);
  }

  onNgDestroy() {
    if (this.updatePoller) {
      this.updatePoller.unsubscribe();
    }
    if (this.subscription) {
      this.subscription.unsubscribe();
    }
  }

}

