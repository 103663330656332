<highcharts-chart *ngIf="showGraph"
                  [Highcharts]="Highcharts"
                  [constructorType]="chartConstructor"
                  [options]="chartOptions"
                  [callbackFunction]="chartCallback"
                  [(update)]="updateFlag"
                  [oneToOne]="oneToOneFlag"
                  [runOutsideAngular]="runOutsideAngular"
                  style="width: 95%; height: 300px; display: block; position: relative">
</highcharts-chart>
