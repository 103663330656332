<div class="SDeskSidePanel-GreyBackground"></div>

<app-blade-right  *ngIf="!isLoading" size="blade-large">
  <div class="p-10 p-b-0">
    <i (click)="this.close()" class="fas fa-times fa-2x float-end divLink"></i>
    <h3>{{this.loadedId ? 'Edit' : 'Create'}} Major Incident Task</h3>
  </div>
  <hr>
  <div class="container p-0">

    <div class="row">
      <div class="col-sm-3 formHeader alignMiddle p-10">
        Task Name <span class="text-danger">*</span>
      </div>
      <div class="col-sm p-10">
        <input [(ngModel)]="loadedData.TaskName" type="text" class="form-control" id="Name" placeholder="Name" autocomplete="off">

      </div>
    </div>

    <div class="row">
      <div class="col-sm-3 formHeader alignMiddle p-10">
        Communication Task?
      </div>
      <div class="col-sm p-10">
        <input [(ngModel)]="loadedData.IsCommunicationTask" class="form-check-input" id="IsCommunicationTask" type="checkbox"><label for="IsCommunicationTask" class="p-l-10"></label>
      </div>
    </div>

    <div *ngIf="loadedData.IsCommunicationTask" class="row">
      <div class="col-sm-3 formHeader alignMiddle p-10">
        E-mail Template
      </div>
      <div class="col-sm p-10">
        <textarea rows="5" id="EmailTemplateText" [(ngModel)]="loadedData.EmailTemplateText" class="form-control" style="width: 100%;"></textarea>
      </div>
    </div>

    <div *ngIf="loadedData.IsCommunicationTask" class="row">
      <div class="col-sm-3 formHeader alignMiddle p-10">
        SMS Template
      </div>
      <div class="col-sm p-10">
        <textarea rows="5" id="SmsTemplateText" [(ngModel)]="loadedData.SmsTemplateText" class="form-control" style="width: 100%;"></textarea>
      </div>
    </div>

    <app-template-syntax *ngIf="loadedData.IsCommunicationTask"></app-template-syntax>



    <div class="m-t-20">
      <div (click)="save()" class="btn btn-primary">Save</div>
      <div (click)="close()" class="btn btn-cancel">Cancel</div>
    </div>

  </div>
</app-blade-right>
