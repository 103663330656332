

import {Component, EventEmitter, Input, OnInit, Output, ViewContainerRef} from '@angular/core';
import {Dialog} from "../../../../../../shared/dialog/dialog";
import {ApiAdminLinkConfigurationService} from "../../../../../../services/api/admin/admin-link-configuration-api/admin-link-configuration-api.service";
import {OptionsApiService} from "../../../../../../services/api/options/options-api.service";

@Component({
  selector: 'app-admin-link-configuration-create-edit-dialog',
  templateUrl: './admin-link-configuration-create-edit-dialog.component.html'
})

export class AdminLinkConfigurationComponentDialog implements Dialog {

  @Input() data;
  @Output() remove = new EventEmitter<any>();

  isLoading = true;
  isLoadingRequestTypes;

  loadedData;
  requestTypes;

  constructor(
    private apiAdminLinkConfigurationService: ApiAdminLinkConfigurationService,
    private apiOptionsService: OptionsApiService
  ) {
    this.apiOptionsService.AllRequestTypes().then(response => {
      this.requestTypes = response;
      this.isLoadingRequestTypes = false;
    })

  }

  ngOnInit(): void {

    // Load Default Data
    this.loadedData = {
      Name: ''
    }

    this.isLoading = false;
  }

  close(){
    this.remove.emit(null);
  }

  save(){
    this.apiAdminLinkConfigurationService.create(this.loadedData).then(response => {
      this.remove.emit("Saved");
    })
  }
}
