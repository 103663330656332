import {Component, OnInit} from '@angular/core';
import {BrandingService} from "../services/branding/branding.service";
import {AuthApiService} from "../services/api/authentication/auth-api-service";
import {AuthenticationService} from "../services/authentication/authentication.service";
import {ActivatedRoute, Router} from "@angular/router";
import { SdeskHelper } from '../shared/helper/SdeskHelper';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css']
})
export class LoginComponent implements OnInit {

  public branding;
  public loginMethod;

  // User/Password form
  public emailAddress: string;
  public password: string;
  public username: string;

  public screen: string = 'loginScreen'; // loginScreen / passwordResetScreen / passwordResetSuccessful
  public showPasswordResetSuccessful: boolean = false;
  public showPasswordResetFailed: boolean = false;

  public returnPath: string;
  public showLoginFailedMessage = false;

  constructor(
    private brandingService: BrandingService,
    private authApi: AuthApiService,
    private authService: AuthenticationService,
    private route: ActivatedRoute,
    private router: Router,
  ) {
    brandingService.brandingSubscription.subscribe(data => this.branding = data);
    this.emailAddress = localStorage.getItem('login_email');
    this.loginMethod = localStorage.getItem('login_method');
  }

  ngOnInit(): void {
    this.route.queryParams.subscribe(params => {
      this.returnPath = params['returnPath'];
      this.username = params['username'];
    });
  }

  getLoginMethod(){
    this.authApi.getLoginMethod(this.emailAddress).then(
      response => {
        this.setLoginMethod(response?.LoginMethod);
      }
    ).catch(error => {
      console.log("Failed to get login method.")
    })
  }

  resetPassword(){
      // clear previous status
      this.showLoginFailedMessage = false;
      this.showPasswordResetSuccessful = false;
      this.showPasswordResetFailed = false;
    this.authApi.resetPassword(this.emailAddress).then(response => {
        if (SdeskHelper.isNotNullOrEmpty(response)) {
          this.showPasswordResetSuccessful = true
        } else {
          this.showPasswordResetFailed = true;
        }
      }
    )
  }

  setLoginMethod(method){
    localStorage.setItem('login_method', method);
    this.loginMethod = localStorage.getItem('login_method');

    localStorage.setItem('login_email', this.emailAddress);
  }

  clearLoginMethod(){
    localStorage.removeItem('login_method');
    localStorage.removeItem('login_email');

    this.loginMethod = null;
    this.emailAddress = null;
  }

  login(){
    // Clear login failed message
    this.showLoginFailedMessage = false;

    if(this.loginMethod == 'SSO'){
      // Redirect to SSO Authenticate backend handler
      const ssoUrl = `/ssoauthenticate?emailaddress=${this.emailAddress}&relayState=${window.location.href}`;
      window.location.href = this.username ? `${ssoUrl}&username=${this.username}`:ssoUrl;

    } else {
      // Call Login API
      this.authApi.login(this.emailAddress, this.password).then(response => {
        const authToken = response?.AuthToken;
        const userName = response?.UserName

        if(authToken) {
          this.authService.setAuthToken(authToken);
          // this.authService.setUserName(userName);
          localStorage.setItem('userName', userName);
          this.authService.setUserName(userName);

          if(this.returnPath){
            this.router.navigate([this.returnPath]).catch(error => {
              console.log("route does not exist:" + error);
              this.router.navigate(['/']);
            });
          } else {
            this.router.navigate(['/']);
          }

        } else {
          this.showLoginFailedMessage = true;
        }

      }).catch(error => {
        this.showLoginFailedMessage = true;
      })
    }
  }
}
