import {Component, ViewContainerRef} from '@angular/core';
import { CommonModule } from '@angular/common';
import {ApiAdminCannedResponsesService} from '../../../../services/api/admin/admin-canned-responses-api/admin-canned-responses-api.service';
import {DialogService} from '../../../../services/dialog-service/dialog.service';
import {
  AdminCannedResponsesComponentDialog
} from '../admin-canned-responses/admin-canned-responses-create-edit-dialog/admin-canned-responses-create-edit-dialog.component';
import {ModalConfirmationComponent} from '../../../../shared/modal-confirmation/modal-confirmation.component';
import {AppModule} from '../../../../app.module';
import {ApiAdminChatConfig} from '../../../../services/api/admin/chat-api/admin-chat-api';
import {AdminChatConfigDialogComponent} from './admin-chat-config-dialog/admin-chat-config-dialog.component';

@Component({
  selector: 'app-admin-chat-config',
  templateUrl: './admin-chat-config.component.html'
})
export class AdminChatConfigComponent {

  data;
  isLoading = true;

  constructor(
    private apiAdminChatConfig: ApiAdminChatConfig,
    private dialogService: DialogService,
    private viewReference: ViewContainerRef) {
    this.getData();
  }

  ngOnInit(): void {
  }

  getData(){
    this.isLoading = true;
    this.apiAdminChatConfig.listAll().then(response => {
      this.data = response;
      this.isLoading = false;
    })
  }

  create(){
    // Replace null with Component
    this.dialogService.createDialog(AdminChatConfigDialogComponent, null, this.viewReference)
      .then(dialogSaved => {
        this.getData();
      }, dialogCancelled => {

      })
  }

  edit(id){
    // Replace null with Component
    this.dialogService.createDialog(AdminChatConfigDialogComponent, id, this.viewReference)
      .then(dialogSaved => {
        this.getData();
      }, dialogCancelled => {

      })
  }

  delete(id){
    this.dialogService.createDialog(ModalConfirmationComponent, 'Are you sure?', this.viewReference)
      .then(confirmed => {
        this.apiAdminChatConfig.delete(id).then(response => this.getData())
      }, cancelled => null)
  }

}
