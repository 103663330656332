import { Component, EventEmitter, Input, Output } from "@angular/core";
import { ApiAdminAssetTypesService } from "src/app/services/api/admin/admin-asset-types-api/admin-asset-types-api.service";
import { ApiAdminRequestTypeService } from "src/app/services/api/admin/admin-request-type-api/admin-request-type-api.service";
import { Dialog } from "src/app/shared/dialog/dialog";

@Component({
    selector: 'app-admin-asset-types-create-edit-dialog',
    templateUrl: './admin-asset-types-create-edit-dialog.component.html'
  })

  export class AdminAssetTypesComponentDialog implements Dialog {
    @Input() data; // {id: 122, requestTypeId: 12}
  @Output() remove = new EventEmitter<any>();

  isLoading = true;

  loadedId = null;
  requestTypeId = null;

  loadedData;
  requestDetails;

  constructor(
    private apiAdminAssetTypesService: ApiAdminAssetTypesService,
    private apiAdminRequestTypeService: ApiAdminRequestTypeService,
  ) {}

    ngOnInit(): void {
        this.requestTypeId = this.data.requestTypeId;
        this.getRequestType()
        if(this.data.id !== null){
            this.loadedId = this.data.id;
            this.getData();
        } else {

            // Load Default Data
            this.loadedData = {
                Name: ''
            }

            this.isLoading = false;

        }
    }

    getRequestType(){
        this.isLoading = true;
        this.apiAdminRequestTypeService.get(this.requestTypeId).then(response => {
          this.requestDetails = response;
          this.isLoading = false;
        })
      }
    
      getData(){
        this.isLoading = true;
        this.apiAdminAssetTypesService.get(this.loadedId).then(response => {
        this.loadedData = response;
        this.isLoading = false;
    })
      }
    
      close(){
        this.remove.emit(null);
      }
    
      save(){
        if(this.loadedId){
            this.apiAdminAssetTypesService.update(this.loadedId,this.loadedData).then(response => {
                this.remove.emit("Saved");
          });
        }
        else{
            this.apiAdminAssetTypesService.create(this.loadedData).then(response => {
                this.remove.emit("Saved");
          });

        }
        
    }
  }