<!--<h1>{{testService.returnAuthToken()}}</h1>-->

<h2 (click)="test()">SetAuthToken</h2>


<!--<h1>Refresh: {{Refresh}} <span (click)="Refresh = 'Reset'"> reset</span></h1>-->
<!--<input type="text" class="form-control" id="name"-->
<!--       required-->
<!--       [(ngModel)]="Hey" name="name">-->

<!--<app-child [Message]="Hey" (messageEvent)="receiveMessage($event)"></app-child>-->
