import {Component, Input, OnInit, ViewContainerRef} from '@angular/core';
import {ApiAdminTypeSecurityDirectFeedsService} from "../../../../../services/api/admin/admin-soc-direct-feeds-api/admin-soc-direct-feeds-api.service";
import {AdminSocDirectFeedsComponentDialog} from "./admin-soc-direct-feeds-create-edit-dialog/admin-soc-direct-feeds-create-edit-dialog.component";
import {ModalConfirmationComponent} from "../../../../../shared/modal-confirmation/modal-confirmation.component";
import {DialogService} from "../../../../../services/dialog-service/dialog.service";

@Component({
  selector: 'app-admin-soc-direct-feeds',
  templateUrl: './admin-soc-direct-feeds.html'
})
export class AdminSocDirectFeedsComponent implements OnInit {

  @Input() requestTypeId;

  data;
  isLoading = true;

  constructor(private apiAdminTypeSecurityDirectFeedsService: ApiAdminTypeSecurityDirectFeedsService, private dialogService: DialogService, private viewReference: ViewContainerRef) {
  }

  ngOnInit(): void {
    this.getData();
  }

  getData() {
    this.isLoading = true;
    this.apiAdminTypeSecurityDirectFeedsService.listAll(this.requestTypeId).then(response => {
      this.data = response;
      this.isLoading = false;
    })
  }

  create() {
    // Replace null with Component
    this.dialogService.createDialog(AdminSocDirectFeedsComponentDialog, {
      id: null,
      requestTypeId: this.requestTypeId
    }, this.viewReference)
      .then(dialogSaved => {
        this.getData();
      }, dialogCancelled => {

      })
  }

  edit(id) {
    // Replace null with Component
    this.dialogService.createDialog(AdminSocDirectFeedsComponentDialog, {
      id: id,
      requestTypeId: this.requestTypeId
    }, this.viewReference)
      .then(dialogSaved => {
        this.getData();
      }, dialogCancelled => {

      })
  }

  delete(id) {
    this.dialogService.createDialog(ModalConfirmationComponent, 'Are you sure?', this.viewReference)
      .then(confirmed => {
        this.apiAdminTypeSecurityDirectFeedsService.delete(this.requestTypeId, id).then(response => this.getData())
      }, cancelled => null)
  }


}
