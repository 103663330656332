
import {Injectable} from '@angular/core';
import {ApiService} from "../../api.service";

@Injectable({
  providedIn: 'root'
})
export class ApiAdminCustomFieldService {

  constructor(private apiService: ApiService) {
  }

  listAll(typeId) {
    return this.apiService.get(`/api/admin/type/${typeId}/customfield`);
  }

  get(typeId, id) {
    return this.apiService.get(`/api/admin/type/${typeId}/customfield/${id}`);
  }

  delete(typeId, id) {
    return this.apiService.delete(`/api/admin/type/${typeId}/customfield/${id}`);
  }

  create(typeId, data) {
    return this.apiService.post(`/api/admin/type/${typeId}/customfield`, data);
  }

  update(typeId, id, data) {
    return this.apiService.put(`/api/admin/type/${typeId}/customfield/${id}`, data);
  }

  moveUp(typeId, id) {
    return this.apiService.patch(`/api/admin/type/${typeId}/customfield/${id}/changeorder/up`);
  }

  moveDown(typeId, id) {
    return this.apiService.patch(`/api/admin/type/${typeId}/customfield/${id}/changeorder/down`);
  }

  createCustomFieldOption(typeId, customFieldId, value, level, parentOptionId) {

    let data = {
      OptionValue: value,
      Level: level,
      ParentCustomFieldId: parentOptionId
    }

    return this.apiService.post(`/api/admin/type/${typeId}/customfield/${customFieldId}/option`, data);
  }

  deleteCustomFieldOption(typeId, customFieldId, optionId) {
    console.log(`Deleting option with TypeID: ${typeId}, CustomFieldID: ${customFieldId}, OptionID: ${optionId}`);
    return this.apiService.delete(`/api/admin/type/${typeId}/customfield/${customFieldId}/option/${optionId}`);
  }
  

  moveUpCustomFieldOption(typeId, customFieldId, optionId) {
    return this.apiService.patch(`/api/admin/type/${typeId}/customfield/${customFieldId}/option/${optionId}/changeorder/up`);
  }

  moveDownCustomFieldOption(typeId, customFieldId, optionId) {
    return this.apiService.patch(`/api/admin/type/${typeId}/customfield/${customFieldId}/option/${optionId}/changeorder/down`);
  }


}



