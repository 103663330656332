import { Injectable } from '@angular/core';
import {Router} from "@angular/router";

@Injectable({
  providedIn: 'root'
})
export class AuthenticationService {

  private authToken;
  private userName: string = '';

  constructor(private router: Router) {
    console.log("Constructing AuthenticationService");
    const tokenInLocalStorage = localStorage.getItem('id_token');
    if(tokenInLocalStorage){
      this.authToken = tokenInLocalStorage;
    }
  }

  amILoggedIn(){
    if(!this.authToken){
      return false;
    } else {
      return true;
    }
  }

  checkLoggedIn() {
    if(!this.authToken){
      console.log("checkLoggedIn.... redirect to /login");
      this.redirectToLoginPage();
    } else {
      console.log("I am logged in");
    }
  }

  redirectToLoginPage(){
    if(!this.router.url.includes('/login')){
      console.log("Url does not contain /login");
      this.router.navigate(['/login'], { queryParams: { returnPath: this.router.url }});
    }
  }

  removeAuthTokenAndRedirectToLogin() {
    console.log("removeAuthTokenAndRedirectToLogin.... redirect to /login");

    localStorage.removeItem('id_token');
    this.redirectToLoginPage();
  }

  returnAuthToken() {
    console.log("Calling returnAuthToken");
    return this.authToken;
  }

  logout(){
    localStorage.removeItem('id_token');
    localStorage.removeItem('userName');
    this.authToken = null;
    location.reload();

  }

  setAuthToken(newToken){
    console.log("Calling setAuthToken")
    this.authToken = newToken;
    localStorage.setItem('id_token', newToken);
  }
  
  setUserName(userName: string): void {
    localStorage.setItem('userName', userName);
  }

  getUserName(): string | null {
    return localStorage.getItem('userName');
  }
}
