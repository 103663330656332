<div class="SDeskSidePanel-GreyBackground"></div>

<app-blade-right  *ngIf="!isLoading && !isLoadingRequestTypes" size="blade-large">
  <div class="p-10 p-b-0">
    <i (click)="this.close()" class="fas fa-times fa-2x float-end divLink"></i>
    <h3>{{this.loadedId ? 'Edit' : 'Create'}} Email Parse</h3>
  </div>
  <hr>
  <div class="container p-0">

    <div class="row">
      <div class="col-sm-3 formHeader alignMiddle p-10">
        Name <span class="text-danger">*</span>
      </div>
      <div class="col-sm p-10">
        <input [(ngModel)]="loadedData.Name" type="text" class="form-control" id="Name" placeholder="Name" autocomplete="off">

      </div>
    </div>

    <div class="row">
      <div class="col-sm-3 formHeader alignMiddle p-10">
        Forward your e-mails to:
      </div>
      <div class="col-sm p-10">

        <span *ngIf="loadedData.SDeskEmailAlias">{{loadedData.SDeskEmailAlias}}&#64;parse.{{instanceParams.EnvironmentUrl}}</span>
        <span *ngIf="!loadedData.SDeskEmailAlias">Will be generated on save.</span>

      </div>
    </div>

    <div class="row">
      <div class="col-sm-3 formHeader alignMiddle p-10">
        Default Request Type <span class="text-danger">*</span>
      </div>
      <div class="col-sm p-10">

        <!-- Cannot change requestType on edit -->
        <ng-select [items]="requestTypes"
                   bindLabel="name"
                   bindValue="id"
                   [(ngModel)]="loadedData.DefaultTypeId"
                   (ngModelChange)="changeRequestType()"
                   [clearable]="false"
                   [disabled]="this.loadedId">
        </ng-select>
      </div>
    </div>

    <div *ngIf="!isLoadingOptions">
      <div class="row">
        <div class="col-sm-3 formHeader alignMiddle p-10">
          Default Group
        </div>
        <div class="col-sm p-10">

          <!-- Cannot change requestType on edit -->
          <ng-select [items]="options.group"
                     bindLabel="name"
                     bindValue="id"
                     [(ngModel)]="loadedData.DefaultSupportGroupId"
                     (ngModelChange)="refreshOptions(); changeSupportGroup();"
                     [clearable]="true">
          </ng-select>
        </div>
      </div>

      <div class="row">
        <div class="col-sm-3 formHeader alignMiddle p-10">
          Default Agent
        </div>
        <div class="col-sm p-10">

          <!-- Cannot change requestType on edit -->
          <ng-select [items]="options.agent"
                     bindLabel="name"
                     bindValue="id"
                     [(ngModel)]="loadedData.DefaultAgentId"
                     [clearable]="true"
                     [disabled]="!loadedData.DefaultSupportGroupId">
          </ng-select>
        </div>
      </div>

      <div class="row">
        <div class="col-sm-3 formHeader alignMiddle p-10">
          Default Status
        </div>
        <div class="col-sm p-10">

          <!-- Cannot change requestType on edit -->
          <ng-select [items]="options.status"
                     bindLabel="name"
                     bindValue="id"
                     [(ngModel)]="loadedData.DefaultStatusId"
                     [clearable]="true">
          </ng-select>
        </div>
      </div>

      <div class="row">
        <div class="col-sm-3 formHeader alignMiddle p-10">
          Default Customer
        </div>
        <div class="col-sm p-10">

          <!-- Cannot change requestType on edit -->
          <ng-select [items]="options.customer"
                     bindLabel="name"
                     bindValue="id"
                     [(ngModel)]="loadedData.DefaultCustomerId"
                     (ngModelChange)="refreshOptions()"
                     [clearable]="true">
          </ng-select>
        </div>
      </div>

      <div class="row">
        <div class="col-sm-3 formHeader alignMiddle p-10">
          Default Service
        </div>
        <div class="col-sm p-10">

          <!-- Cannot change requestType on edit -->
          <ng-select [items]="options.service"
                     bindLabel="name"
                     bindValue="id"
                     [(ngModel)]="loadedData.DefaultServiceId"
                     [clearable]="true">
          </ng-select>
        </div>
      </div>

      <div class="row">
        <div class="col-sm-3 formHeader alignMiddle p-10">
          Default Impact
        </div>
        <div class="col-sm p-10">

          <!-- Cannot change requestType on edit -->
          <ng-select [items]="options.impact"
                     bindLabel="name"
                     bindValue="id"
                     [(ngModel)]="loadedData.DefaultImpactId"
                     [clearable]="true">
          </ng-select>
        </div>
      </div>

      <div class="row">
        <div class="col-sm-3 formHeader alignMiddle p-10">
          Default Urgency
        </div>
        <div class="col-sm p-10">

          <!-- Cannot change requestType on edit -->
          <ng-select [items]="options.urgency"
                     bindLabel="name"
                     bindValue="id"
                     [(ngModel)]="loadedData.DefaultUrgencyId"
                     [clearable]="true">
          </ng-select>
        </div>
      </div>

      <div class="row">
        <div class="col-sm-3 formHeader alignMiddle p-10">
          Default Priority
        </div>
        <div class="col-sm p-10">

          <!-- Cannot change requestType on edit -->
          <ng-select [items]="options.priority"
                     bindLabel="name"
                     bindValue="id"
                     [(ngModel)]="loadedData.DefaultPriorityId"
                     [clearable]="true">
          </ng-select>
        </div>
      </div>

    </div>
    <div class="row">
      <div class="col-sm-3 formHeader alignMiddle p-10">
        Email Priority
      </div>
      <div class="col-sm p-10">

        <!-- Cannot change requestType on edit -->
        <ng-select [items]="priorityOptions"
                   bindLabel="name"
                   bindValue="id"
                   [(ngModel)]="loadedData.EmailPriority"
                   [clearable]="true">
        </ng-select>
      </div>
    </div>

    <div class="row">
      <div class="col-sm-3 formHeader alignMiddle p-10">
        Ignore Cc Ticket Creation

        <i [matTooltip]="'Exclude ticket creation when inbound parse email ID is in Cc field'"
        [matTooltipPosition]="'above'" [matTooltipClass]="'sdesk-tooltip'"
        class="far fa-question-circle m-l-5"></i>

      </div>
      <div class="col-sm p-10">
        <input class="form-check-input" type="checkbox" [(ngModel)]="loadedData.IsIgnoreCcBcc">
      </div>
    </div>

    <div class="m-t-20">
      <div (click)="checkPriorityExists()" class="btn btn-primary">Save</div>
      <div (click)="close()" class="btn btn-cancel">Cancel</div>
    </div>

  </div>
</app-blade-right>
