

import {Component, EventEmitter, Input, OnInit, Output, ViewContainerRef} from '@angular/core';
import {Dialog} from "../../../../../../shared/dialog/dialog";
import {ApiAdminServicesService} from "../../../../../../services/api/admin/admin-services-api/admin-services-api.service";
import {ApiAdminCustomerService} from "../../../../../../services/api/admin/admin-customer-api/admin-customer-api.service";
import {ApiAdminVendorsService} from "../../../../../../services/api/admin/admin-vendors-api/admin-vendors-api.service";
import {OptionsApiService} from "../../../../../../services/api/options/options-api.service";
import {ApiAdminCustomerLocationsService} from "../../../../../../services/api/admin/admin-customer-locations-api/admin-customer-locations-api.service";
import {ApiService} from "../../../../../../services/api/api.service";

@Component({
  selector: 'app-admin-services-create-edit-dialog',
  templateUrl: './admin-services-create-edit-dialog.component.html'
})

export class AdminServiceComponentDialog implements Dialog {

  @Input() data;
  @Output() remove = new EventEmitter<any>();

  isLoading = true;
  isLoadingCustomers = true;
  isLoadingVendors = true;
  isLoadingRequestTypes = true;
  isLoadingSlaTemplates = true;

  loadedId = null;

  selectedTab = 'details';
  tagInputField = '';

  loadedData;

  requestTypes;
  customers;
  customerLocations;
  vendors;
  slaTemplates;

  constructor(
    private apiAdminServicesService: ApiAdminServicesService,
    private apiAdminCustomerService: ApiAdminCustomerService,
    private apiAdminCustomerLocationService: ApiAdminCustomerLocationsService,
    private apiAdminVendorsService: ApiAdminVendorsService,
    private apiOptionService: OptionsApiService,
    private apiService: ApiService
  ) {
    this.loadOptions();
  }

  addTag() {
    if (this.tagInputField.trim() !== '') {
      if(!this.loadedData.Tags.includes(this.tagInputField)){
        this.loadedData.Tags.push(this.tagInputField);
      }
      this.tagInputField = '';
    }
  }

  removeTag(index){
    this.loadedData.Tags.splice(index, 1);
  }

  ngOnInit(): void {
    if(this.data !== null){
      this.loadedId = this.data;
      this.getData();
    } else {

      // Load Default Data
      this.loadedData = {
        Name: '',
        CustomerId: 0,
        CustomerLocationId: 0,
        VendorId: 0,
        ContactName: '',
        ContactEmailAddress: '',
        PhoneNumber: '',
        SlaLinks: [],
        Tags: []
      }

      // Get Options
      this.apiAdminServicesService.options().then(response => {
        this.loadedData.SlaLinks = response.BlankSlaLinks;
      })

      this.isLoading = false;

    }
  }

  changeCustomer(){
    this.loadedData.CustomerLocationId = null;
    this.refreshCustomerLocations();
  }

  refreshCustomerLocations(){
    if(this.loadedData.CustomerId){
      this.apiAdminCustomerLocationService.search(this.loadedData.CustomerId).then(response => this.customerLocations = response)
    }
  }

  getData(){
    this.isLoading = true;
    this.apiAdminServicesService.get(this.loadedId).then(response => {
      this.loadedData = response;
      this.refreshCustomerLocations();
      this.isLoading = false;
    })
  }

  loadOptions(){
    // Load Customers
    this.apiAdminCustomerService.listAll().then(response => {
      this.customers = response;
      this.isLoadingCustomers = false;
    })

    // Load Vendors
    this.apiAdminVendorsService.listAll().then(response => {
      this.vendors = response;
      this.isLoadingVendors = false;
    })

    // Load Request Types
    this.apiOptionService.AllRequestTypes().then(response => {
      this.requestTypes = response;
      this.isLoadingRequestTypes = false;
    })

    // Load SLA Templates
    // ToDo: Use the SLA Template service when it has been generated
    this.apiService.get('/api/admin/slatemplate').then(response => {
      this.slaTemplates = response;
      this.isLoadingSlaTemplates = false;
    })
  }

  close(){
    this.remove.emit(null);
  }

  save(){
    if(this.loadedId){
      this.apiAdminServicesService.update(this.loadedId, this.loadedData).then(response => {
        this.remove.emit("Saved");
      })
    } else {
      this.apiAdminServicesService.create(this.loadedData).then(response => {
        this.remove.emit("Saved");
      })
    }

  }
}
